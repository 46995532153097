import React, { Fragment,useState } from 'react';
import { Link } from 'react-router-dom';
  

import Navbar from '../../components/layout/Navbar';
import NavbarEnglish from '../../components/layout/NavbarEnglish';
import Footer from '../../components/layout/Footer';
import { useTranslation } from 'react-i18next';
 
 
    
 
const Affiliate = ({match}) => {
  const Lang = match.params.lang;

 
  const [t, i18next] = useTranslation()

  const [accordion1, setAccordion1] = useState('noneDisplayI');
  const [accordion2, setAccordion2] = useState('noneDisplayI');
  const [accordion3, setAccordion3] = useState('noneDisplayI');
  const [accordion4, setAccordion4] = useState('noneDisplayI');
  const [accordion5, setAccordion5] = useState('noneDisplayI');
  const [accordion6, setAccordion6] = useState('noneDisplayI');
  const [accordion7, setAccordion7] = useState('noneDisplayI');
  const [accordion8, setAccordion8] = useState('noneDisplayI');

  const TogoleAccordion1 =() =>{
   setAccordion1('displayI')
   setAccordion2('noneDisplayI')
   setAccordion3('noneDisplayI')
   setAccordion4('noneDisplayI')
   setAccordion5('noneDisplayI')
   setAccordion6('noneDisplayI')
   setAccordion7('noneDisplayI')
   setAccordion8('noneDisplayI')
  }



  const TogoleAccordion2 =() =>{
    setAccordion1('noneDisplayI')
    setAccordion2('displayI')
    setAccordion3('noneDisplayI')
    setAccordion4('noneDisplayI')
    setAccordion5('noneDisplayI')
    setAccordion6('noneDisplayI')
    setAccordion7('noneDisplayI')
    setAccordion8('noneDisplayI')
   }


   const TogoleAccordion3 =() =>{
    setAccordion1('noneDisplayI')
    setAccordion2('noneDisplayI')
    setAccordion3('displayI')
    setAccordion4('noneDisplayI')
    setAccordion5('noneDisplayI')
    setAccordion6('noneDisplayI')
    setAccordion7('noneDisplayI')
    setAccordion8('noneDisplayI')
   }




   const TogoleAccordion4 =() =>{
    setAccordion1('noneDisplayI')
    setAccordion2('noneDisplayI')
    setAccordion3('noneDisplayI')
    setAccordion4('displayI')
    setAccordion5('noneDisplayI')
    setAccordion6('noneDisplayI')
    setAccordion7('noneDisplayI')
    setAccordion8('noneDisplayI')
   }




   const TogoleAccordion5 =() =>{
    setAccordion1('noneDisplayI')
    setAccordion2('noneDisplayI')
    setAccordion3('noneDisplayI')
    setAccordion4('noneDisplayI')
    setAccordion5('displayI')
    setAccordion6('noneDisplayI')
    setAccordion7('noneDisplayI')
    setAccordion8('noneDisplayI')
   }





   const TogoleAccordion6 =() =>{
    setAccordion1('noneDisplayI')
    setAccordion2('noneDisplayI')
    setAccordion3('noneDisplayI')
    setAccordion4('noneDisplayI')
    setAccordion5('noneDisplayI')
    setAccordion6('displayI')
    setAccordion7('noneDisplayI')
    setAccordion8('noneDisplayI')
   }





   const TogoleAccordion7 =() =>{
    setAccordion1('noneDisplayI')
    setAccordion2('noneDisplayI')
    setAccordion3('noneDisplayI')
    setAccordion4('noneDisplayI')
    setAccordion5('noneDisplayI')
    setAccordion6('noneDisplayI')
    setAccordion7('displayI')
    setAccordion8('noneDisplayI')
   }




   const TogoleAccordion8 =() =>{
    setAccordion1('noneDisplayI')
    setAccordion2('noneDisplayI')
    setAccordion3('noneDisplayI')
    setAccordion4('noneDisplayI')
    setAccordion5('noneDisplayI')
    setAccordion6('noneDisplayI')
    setAccordion7('noneDisplayI')
    setAccordion8('displayI')
   }







  return (
    <Fragment>
 
<div className="aqle3-main">
<div className="mainword2">   

  {i18next.language === 'ar'&&(<Navbar /> )}
  {i18next.language === 'en'&&(<NavbarEnglish /> )}



  <center> 
      <div className="affiliate-nav">
      <div className="affiliateTitle" >WELCOME AFFILIATES  </div>


      <p className="affiliateText">
      <ul className='DashBoxList-paragraph'>
        <li> 
        Whether you are advertising  on our platform or you have a website or accounts in social networks or forums
       </li>
     



       <li> 
        You will be able to benefit from joining the Faizads membership and easily achieve a continuous passive income.
        </li>



        <li> 
         this system will pay you $3 Dollars for each new member who registers using your private link and become a member in one of the paid membership packages.
         </li>


         <li> 
         you will get a commission for every renewal in your network
          </li>


          <li> 
            every time your referral member renews his subscription, your profit will continue through. and continuously for all your referrals as long as they pay the subscription fees.
          </li>

           </ul>
          </p>

   



   


       <div className="affiliateTitle" > Why sign up with Faizads for membership and work for our commission marketing program?  </div>

       <p className="affiliateText">
       <ul className='DashBoxList'>
          <li>
          Our platform is the best for advertising  of services or products by subscribing to one of the premium membership packages.
            </li>

           <li>
           If you are advertising your services or products, the commission marketing program will help you achieve higher income just by inviting your friends and colleagues at work to also advertise with you the sale or purchase of various services and products on our site.
            </li>


           <li>
           You will earn $ 3 in commission for each new user who subscribes to one of the paid premium packages (that is, the user must register using your special link and then must subscribe to one of the premium advertising packages in order to earn the commission).
             </li>


            <li>
            Our site serves ads for many services and products offered for sale or for subscription. You can advertise any service or product from any country
             </li>

            <li>
            You will be able to withdraw the profits that you have achieved in several ways that are suitable for you, as soon as they reach $10.
             </li>

            <li>
            There are no complications in joining our express marketing program, and there are no complications in the process of managing and publishing your ads. ‏
             </li>

 

       </ul>

        </p>
  

        <div className="affiliateTitle" >  How can I get started working with this platform?  </div>

    <p className="affiliateText">
    <ul className='DashBoxList'>

    <li>
     After registration on our platform, publish your link on your website or social media.
    </li>
  
    <li>
    You can use banners and place them on your website or blog.
    </li>

      <li>
      Write a review about our platform, how to subscribe and benefit from it, and clarify your experience with us
       </li>



      <li>
      You can run advertising campaigns to support and enhance the visibility of your private link
      </li>

  

        <li>
        There are different methods that enhance the marketing process and increase your income with us, and you are free to choose any of them according to the terms and conditions.
         </li>
</ul>

</p>







<center> 
<div className='affiliate-how-to-earn-box'> 
   <div className='affiliate-how-to-earn-box-title'> 
   How do you earn money with Faizads?
   </div>


   <div className='affiliate-how-to-earn-box-details'> 

   <div className='affiliate-how-to-earn-box-item'>
    <div><i class="fa fa-user-plus fa-3x" aria-hidden="true"></i></div>
    <div>Step one</div>
    <div>  <a style={{color:'#fff',textUnderlineOffset:'none'}} href='/user/createAccount'><span> Register </span> </a>  at Faizads.</div>
   </div>


   <div className='affiliate-how-to-earn-box-item'>
    <div><i class="fa fa-id-card-o fa-3x" aria-hidden="true"></i></div>
   <div>Step two</div>
   <div></div>
   <div> pick a suitable membership plan </div>
   </div>

   <div className='affiliate-how-to-earn-box-item'>
   <div> <i class="fa fa-users fa-3x" aria-hidden="true"></i> </div>
   <div>Step three</div>
   <div> Promote the membership to your audience by your private link.</div>
   </div>

   <div className='affiliate-how-to-earn-box-item'>
   <div> <i class="fa fa-money fa-3x" aria-hidden="true"></i> </div>
   <div>Step four</div>
   <div>Earn profits.</div>
   </div>

   

  

   </div>


   <div className='affiliate-how-to-earn-box-footer'>  </div>


</div>
</center>







<div className="affiliateTitle" >  Perfect for </div>
<div>
<div className="affiliatePerfectFor" > Affiliates   </div> 
<div className="affiliatePerfectFor" > Publishers    </div> 
<div className="affiliatePerfectFor" > Bloggers    </div> 
<div className="affiliatePerfectFor" > Influencers    </div> 
</div>
<div className="affiliatePerfectFor2" > Email list growers & networkers   </div> 
 
 <a href='/user/createAccount'>
<button className="affiliateStartNow"> Start Now   </button> 
</a>


  



<div className="affiliateTitle" > FAQs </div>



<button class="accordion" onClick={TogoleAccordion1}> I'm publisher / blogger / influencer. Do I have to pay for this services to working as a n affiliate?</button>
<div className={accordion1}> 
<div className="panel-sow">
  <p>
 No!, no costs You can earn without paying, just register and get your link immediately To promote paid membership.

  </p>
</div>
</div>

<button class="accordion" onClick={TogoleAccordion2}>How do you make money? </button>

<div className={accordion2}> 
<div class="panel-sow">
  <p>
    We make money from paid membership subscriptions
  </p>
</div>
</div>

<button class="accordion" onClick={TogoleAccordion3}> How do I get started?</button>

<div className={accordion3}>
<div class="panel-sow">
  <p> 
<ul className='DashBoxList'> 
<li >
 Register at faizads.com
</li>
<li >
  a promotional link will be generated automatically.
</li>
<li >
 Promote the membership to your audience using the promotional link.
</li>
<li >
 Start earning commission right away.
</li>
</ul>
  </p>
</div>
</div>



<button class="accordion" onClick={TogoleAccordion4}> How often do I receieve my commission? </button>

<div className={accordion4}>  
<div class="panel-sow">
  <p> 
    You can select any time for your payments if you mane more than 10$.
  </p>
</div>
</div>




<button class="accordion" onClick={TogoleAccordion5}> Can I be sure to get my money?  </button>

<div className={accordion5}> 
<div class="panel-sow">
  <p> 
    Yes! we are responsible for your payments. We guarantee that you will receive your money reliably and timely.
  </p>
</div>
</div>




<button class="accordion" onClick={TogoleAccordion6}> How Does your tracking work?  </button>
<div className={accordion6}> 
<div class="panel-sow">
  <p> 
    Our tracking is cookie-based per default (Last Cookie Wins) - rest assured, that your efforts will be rewarded. But we also offer server postback and multi-device tracking, so that you can track the success of your campaigns.

  </p>
</div>
</div>




<button class="accordion" onClick={TogoleAccordion7}> What is your cookie duration?  </button>
<div className={accordion7}> 
<div class="panel-sow">
  <p> 
        Our cookies work 365 days. That means, that you will receive your commission even then, when the client buys within 180 days after visiting the website for the last time.
  </p>
</div>
</div>




<button class="accordion" onClick={TogoleAccordion8}> Do you support me in earning more money? </button>
<div className={accordion8}> 
<div class="panel-sow">
  <p> 
    <ul className='DashBoxList'> 
    <li> 
Yes!
</li>
   <li> 
 We continuously optimize the conversion rate of our service.
</li>
   <li> 
 We allow you to analyze your campaigns.
</li>
 </ul>
  </p>
 
</div>
</div>




{/* <button class="accordion"> Do I have to declare my activity as an affiliate? </button>
<div class="panel-sow">
  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
</div> */}


{/* 
<button class="accordion"> How do I find a good offer?</button>
<div class="panel-sow">
  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
</div> */}


       </div>
      </center>



     
     
     

      <Footer />
      
      </div>
      </div>

         
   
    </Fragment>
  );
};






export default Affiliate;
