import React from 'react'

const cancel = () => {
    return (
        <div>
            <h1>cancel</h1>
        </div>
    )
}
 
export default cancel
