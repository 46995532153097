import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

 
import Navbar from '../../components/layout/Navbar';
import NavbarEnglish from '../../components/layout/NavbarEnglish';

import Footer from '../../components/layout/Footer';
import { useTranslation } from 'react-i18next';

    
 
const About = ({match}) => {
  const Lang = match.params.lang;
  const [t, i18next] = useTranslation()
 
  return (
    <Fragment>

    <div className="aqle3-main">
 <div className="mainword2">   
 
  {i18next.language === 'ar'&&(<Navbar /> )}
  {i18next.language === 'en'&&(<NavbarEnglish /> )}

      <center> 
      <form className="login-form" >   
      <div className='FormCover'>  

 
 
{/* 
      <p className='about-paragraph'>
      يتطلب كونك رائدًا في الصناعة فريقًا يحركه الطموح ويبعث الأمل في من ‏حوله ويسهم في نجاحهم وتحقيق تطلعاتهم.‏
      </p>

 
      <p className='about-paragraph'>
      يتميز فريقنا بعدم الوقوف في نقطة واحدة مطلقا مهما واجهتهم التحديات، ‏ويذهبون بطموحهم الى مسافات بعيدة، تعرف على المزيد حول الثقافة والقيم ‏التي تدفعهم للأمام.‏
       </p>


    


       <p className='about-paragraph'>
       تأسس موقعنا والذي تديره وتملكه شركة ‏اعلانات فائز للاعلان والتسويق الالكتروني عام ‏‏2022م، ‏وبخبرتنا الطويلة في مجال تكنلوجيا المعلومات والتسويق الإلكتروني، كنا ‏الفريق المسؤول عن هذه ‏المنصة، ودائما ما نبحث عن مشاريع جديدة ‏لتنفيذها على شبكة الانترنت حيث النمو السريع والفرص ‏الغير محدودة.‏ ‏
         </p>

<h1 className='about-title'> رؤيتنا</h1>
        <p className='about-paragraph'>
        أن نكون في طليعة القيمة الاقتصادية المضافة والمرتبطة بنشاط تجاري ‏واستثماري طويل الأجل ‏ومستدام .‏
</p>


<h1 className='about-title'> قيمنا </h1>
   <ul className='about-paragraph'>
<li>أن نكون الخيار المفضل لشركائنا وعملائنا .‏ </li>
<li>أن نكون بيئة عمل مفضلة ومفتوحة لتنمية الأفراد ونموهم.‏ </li>
<li>أن يكون لنا حضور هادف في المجتمع والنظام البيئي الذي نعمل فيه.‏ </li>
<li>أن نكون مصدرًا فعالًا لخلق قيمة مستدامة ومستمرة.‏ </li>
  </ul>
 */}
 {i18next.language === 'ar'&&(
  <Fragment>


<div className="about-title"> تعريف </div> 
<p className='about-paragraph'>
 فائز ادز هي منصة الكترونية تمكن المستخدم  من فتح متجر إلكتروني متكامل، فالتجارة مع فائز ادز من بيتك إلى العالم.
</p>

<p className='about-paragraph'>
 كما تتميز المنصة بسهولة اعدادت المدفوعات الآمنة، لتجارة مستدامة، وثقة متينة،
</p>

<p className='about-paragraph'>
حيث انه فور تسجيلك يصبح بامكانك من خلال النظام ان تتبع خطوات بسيطة وسهلة لبناء متجرك:
</p>



<p className='about-paragraph'>
حيث انه فور تسجيلك يصبح بامكانك من خلال لوحة التحكم ان تتبع خطوات بسيطة وسهلة لبناء متجرك 
</p>



<p className='about-paragraph'>
نحن نفخر بكوننا أحد المنصات التي تساعد مستخدميها في تجارتهم وتحقيق أهدافهم.
</p>




<p className='about-paragraph'>
 نحن دوما نتطلع لتقديم خدمات ‏تساهم في تطوير الأعمال الحرة وتوسيع نطاق الأهداف.
</p>



  

    </Fragment>
 )}


{i18next.language === 'en'&&(
  <Fragment>
<div className="about-title-english"> About </div> 
 

<p className='about-paragraph-english'>
  faizads is an online platform that enables the user to open an integrated online store. Trade with faizads from your home to the world.
 </p>

<p className='about-paragraph-english'>
  The platform is also characterized by the ease of setting up secure payments, for sustainable trade, and solid trust.
</p>

<p className='about-paragraph-english'>
  As soon as you register, you can, through the control panel, follow simple and easy steps to build your store.
</p>

   



<p className='about-paragraph-english'>
  We are proud of being one of the platforms that helps its users in their business and achieve their goals.
 </p>




<p className='about-paragraph-english'>
  We are always looking forward to providing services that contribute to the development of entrepreneurship and expanding the scope of goals.
</p>


 
    </Fragment>
 )}


      </div>  
      </form>

      </center>

     
     
      <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
      <Footer />
      
      
     
      </div>  
      </div>  
         
   
    </Fragment>
  );
};






export default About;
