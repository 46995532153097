import React, {Fragment,useEffect,useState } from "react"; 
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { addSize } from '../../actions/post';
import { useTranslation } from 'react-i18next';

const AddSizes11 = ({isOpen, toggle,postID, addSize}) => {

  const [t, i18next] = useTranslation()
 

  useEffect(() => {
    document.body.classList.toggle("modal-open", isOpen);
  }, [isOpen]);


  const [formData, setFormData] = useState({
    size_AR_name:'',
    size_EN_name:'',
    size_code: '',
    image: '',
    });

    const {size_AR_name,size_EN_name,size_code,image } = formData;

    const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
      e.preventDefault();
     await  addSize(postID,{size_AR_name,size_EN_name,size_code,image});
     window.location=`/posts/${postID}`
    };


  

  const handleDelete = () => {
    toggle();

  
    }
 




  return (
    
    <>
    <div
      className={isOpen ? "modal fade show" : "modal fade"}
      id="DeleteModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="DeleteModalTitle"
      aria-hidden={isOpen}
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">

         
          {i18next.language === 'ar' &&(<div className="abokhaled-title-button-new-blue">اضافة حجم</div>)}
          {i18next.language === 'en' &&(<div className="abokhaled-title-button-new-blue">Add size</div>)}
 
          <button
                type="button"
                className="abokhaled-close-button-new-blue"
                data-dismiss="modal"
                onClick={toggle} 
              > 
                <i className="fa fa-close fa-2x"></i>
              </button>

 
          </div>
          <div className="modal-body text-center delete-modal">
        
      
          
          


   <div className='mainForm'>
    
    <div className=''>
      <form
        className='' 
        onSubmit={e => onSubmit(e)}
      > 
   
      {i18next.language === 'ar' &&(
        <Fragment> 
          <span> اسم الحجم </span>
        <input
         className="login-input"
          placeholder='اسم الحجم مثال : XXX Larg'
          name='size_AR_name'
          value={size_AR_name}
          onChange={e => onChange(e)}
          required
        />


<span> كود الحجم </span>
        <input
         className="login-input"
          placeholder='كود الحجم'
          name='size_code'
          value={size_code}
          onChange={e => onChange(e)}
           
        />


<span> رابط صورة توضح حجم المنتج </span>
        <input
         className="login-input"
          placeholder='رابط صورة توضح حجم المنتج'
          name='image'
          value={image}
          onChange={e => onChange(e)}
           
        />
         
        <button className="Formbutton" type='submit'   onClick={toggle} >  
        أضف{' '}
          <i class="fa fa-plus" aria-hidden="true"></i> 
          </button>
          </Fragment>
      )}


 

{i18next.language === 'en' &&(
        <Fragment> 

{/* <button className="CommentbuttonENglish" type='submit'  >  
        {t('postItems_comment_form_sendComment')} {' '}
          <i class="fa fa-plus" aria-hidden="true"></i> 
          </button> */}
 
          <span> the name of size </span>

          <input
         className="login-input"
          placeholder='the name of size ex: XX LARG'
          name='size_EN_name'
          value={size_EN_name}
          onChange={e => onChange(e)}
          required
        />


<span> size code </span>
        <input
         className="login-input"
          placeholder='size code'
          name='size_code'
          value={size_code}
          onChange={e => onChange(e)}
           
        />


<span> image to display the size </span>
        <input
         className="login-input"
          placeholder='image to display the size'
          name='image'
          value={image}
          onChange={e => onChange(e)}
           
        />

         
<button className="Formbutton" type='submit'  onClick={toggle}  >  
      
          <i class="fa fa-plus" aria-hidden="true"></i> {' '}
          Add
          </button>
    

          </Fragment>
      )}
        
      </form>
    </div>
    </div>




 
           
            <div className="modal-footer border-0 justify-content-center">
              
              {/* <button
                type="button"
                className="abokhaled-close-button-new-blue"
                data-dismiss="modal"
                onClick={toggle} 
              > 
                <i className="fa fa-close fa-2x"></i>
              </button> */}


              {/* <button
                type="button" 
                className="abokhaled-delete-button-new-red"
                onClick={handleDelete}
              >
                <i class="fa fa-trash fa-2x" aria-hidden="true"></i>
              </button> */}


            </div>

 
          </div>
        </div>
      </div>
    </div>
    {isOpen && <div className="modal-backdrop fade show"></div>}
  </>

  )
}


AddSizes11.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  addSize: PropTypes.func.isRequired
};
  

export default connect(
  null,
  { addSize }
)(AddSizes11);


 