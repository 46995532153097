import React, { useState,useEffect ,Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from 'axios';
import Spinner from '../layout/Spinner';
import Navbar from '../layout/Navbar';
import NavbarEnglish from '../layout/NavbarEnglish';
import { useTranslation } from 'react-i18next';
   
 function EditPasswordComfirmation() {

    const [t, i18next] = useTranslation()
   return (
    <div className="aqle3-main">
    <div className="mainword2">
    {i18next.language === 'ar'&&(<Navbar />)}
    {i18next.language === 'en'&&(<NavbarEnglish />)}

    {i18next.language === 'ar' &&(<div className='dash-title'> تم تغيير كلمة المرور بنجاح </div>)}
    {i18next.language === 'en' &&(<div className='dash-title'> Password changed successfully </div>)}

    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
  
     </div>
     </div>
   )
 }
  
 export default EditPasswordComfirmation