import React, { Fragment, useState } from 'react'; 
import axios from 'axios';
import AddPremiumPost from './AddPremiumPost';
import AddpostFree from './AddpostFree';
import { useTranslation } from 'react-i18next';
import Navbar from '../layout/Navbar';
import NavbarEnglish from '../layout/NavbarEnglish';
import Spinner from '../layout/Spinner'; 


function Addpost() {
  const [t, i18next] = useTranslation()
  const [subscription,setSubscription]= useState([])


  axios.get('/api/subscriptions/lastsubscription')
  .then(res => {
    console.log(res);
    setSubscription(res.data)
  })
  .catch((err) => {
    console.log(err);
  })
 
 
  return (
    <Fragment> 
      {i18next.language === 'ar'&&(<Navbar />)}
      {i18next.language === 'en'&&(<NavbarEnglish />)}

    {/* {subscription.membership_class === 'golden'&&(<AddPremiumPost />)} */}

    {subscription.membership_class === 'free'?(<AddpostFree />):(<AddPremiumPost />)}
    </Fragment>
   
  )
}
 
export default Addpost