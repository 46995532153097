import React, { Fragment, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
 
import Navbar from '../../components/layout/Navbar';
import NavbarEnglish from '../../components/layout/NavbarEnglish';
import Footer from '../../components/layout/Footer';


function RegistrationMethod({match}) {
  const [t, i18next] = useTranslation()
  const Lang = match.params.lang;
  return (
      <Fragment>

<div className="aqle3-main">
<div className="mainword2">   

        {i18next.language === 'ar'&&(<Navbar />)}
        {i18next.language === 'en'&&(<NavbarEnglish />)}
        
 

<center> 
      <div className="login-form" >   
      <div className='FormCover'>  

   
  
      {i18next.language === 'ar'&&(
        <Fragment>
      <div className="login-title"> طريقة التسجيل </div>  
      <div className="about-title"> الخطوة الأولى </div>  
      <p className='about-paragraph'>
          الضغط على انشئ حساب 
        <a  href='/user/createAccount'>  انشئ حساب </a>
       </p>

 

     

      <div className="about-title"> الخطوة الثانية </div>  
      <p className='about-paragraph'>
        اكمال عملية التسجيل بتعبئة النموذج بشكل صحيح  
       </p>



       <div className="about-title"> الخطوة الثالثة </div>  
       <p className='about-paragraph'>
       الدخول للوحة تحكم حسابك حسب بريدك الالكتروني وكلمة المرور الخاصة بك
        <a  href='/user/login'> الدخول  </a>
       </p>




       <div className="about-title"> الخطوة الرابعة </div>  
       <p className='about-paragraph'>
       اتباع خطوات بناء متجرك 
       </p>



       </Fragment>
      )}



 


{i18next.language === 'en'&&(
        <Fragment>
      <div style={{direction:'ltr'}}> 
      <div className="login-title"> How to register </div>  
      <div className="about-title-english"> The first step </div>  
      <p className='about-paragraph-english'>
      Click on create an account 
        <a  href='/user/createAccount'>  create an account </a>
       </p>
 
 
 
      <div className="about-title-english"> The second step </div>  
      <p className='about-paragraph-english'>
      Complete the registration process by filling in the form correctly  
       </p>

 

       <div className="about-title-english"> Third step </div>  
       <p className='about-paragraph-english'>
       Login to your account with your email and password  
       <a  href='/user/login'>  Login </a>
       </p>


       <div className="about-title-english"> Fourth step </div>  
       <p className='about-paragraph-english'>
       Follow the steps to build your store 
       </p>



       </div>
       </Fragment>
      )}


      

</div>
</div> 
 </center> 



 <br/><br/><br/><br/><br/><br/><br/>
<Footer />
 </div> 
 </div> 
      </Fragment>
   
  )
}

export default RegistrationMethod