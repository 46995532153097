import React, { Fragment, useEffect ,useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import ff from './system-icon.jpg';
import { useTranslation } from 'react-i18next';
  
import axios from 'axios';
 
const mySidenav0 = 'sidenavnone';
const sidenav = 'sidenav';

const Navbar = ({ auth: { isAuthenticated, loading }, logout,shopID,match,userCategories  }) => {

  const [t, i18next] = useTranslation()

  const [mySidenav, setmySidenav] = useState(mySidenav0)

  const [categories11,setCategory11]= useState([])
 
  const openMenu = async e => {
 
    setmySidenav(sidenav);
  };

  

  const closeMenu = async e => {
 
    setmySidenav(mySidenav0);
  };




  
  useEffect(() => {
   
    axios.get('/api/categories/public')
    .then(res => {
      console.log(res);
    setCategory11(res.data)
    })
    .catch((err) => {
      console.log(err);
    })




  }, [categories11]);

  
     
 
  const authLinks = (
   
 <ul className="topnavSYS" >

   

<div id="mySidenav"   className={mySidenav}>
 <center> 
 <button onClick={closeMenu} className="closebtn">&times;</button>
 
  

  {userCategories.map(catego=>(
<Fragment> 
  <a href={`/shops/${shopID}/special-shops/${catego._id}`} > 
 {catego.c_AR_name} </a> 
  </Fragment>
 ))}  


 

 </center>
</div>
 
      {/* <li className="left">
      <a href='/'>
        <img src={ss} width="60px" height="40px"/>
        </a>
        </li> */} 


   {userCategories.map(catego=>(
        <li className="">
        <a href={`/shops/${shopID}/special-shops/${catego._id}`}>
          {catego.c_AR_name}  
        {' '} <i class="fa fa-chevron-left fa-1x" aria-hidden="true"></i>
        </a>
        
      </li> 
 ))}
  

 
      
  
      <li>
      <button className="buttonNav" onClick={openMenu}>  &#9776;   </button>
      </li>

  
 
      
   

    </ul>
  );
 
  const guestLinks = (
  <ul className="topnavSYS" >
    
  
<button className="buttonNav" onClick={openMenu} >  &#9776;   </button>
 

<div id="mySidenav"   className={mySidenav}>
 <center> 
 <button onClick={closeMenu} className="closebtn" >&times;</button>
 </center>
{userCategories.map(catego=>(

<Fragment> 
<a href={`/shops/${shopID}/special-shops/${catego._id}`} > 
{catego.c_AR_name} </a>

</Fragment>

 ))}

  
 
</div>

     
{userCategories.map(catego=>(
        <li className="">
        <a href={`/shops/${shopID}/special-shops/${catego._id}`}>
          {catego.c_AR_name}  
        {' '} <i class="fa fa-chevron-left fa-1x" aria-hidden="true"></i>
        </a>
        
      </li> 
 ))}
 
 
   
    </ul>





  );
 
  return (
    <Fragment>
    <center> 
      {!loading && (
        <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
      )}
      </center>
    </Fragment>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});
 
export default connect(
  mapStateToProps,
  { logout }
)(Navbar);
 