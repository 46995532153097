import React, { Fragment, useEffect ,useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import moment from 'moment';
import Moment from 'react-moment';

 
       
import { addLike, removeLike, deletePost, addFiveStars , addFourStars, addThreeStars, addTwoStars, addOneStars } from '../../actions/post';

import ConfirmButton from "./ConfirmButton";
import axios from 'axios';

import greenFace from './greenFace.png';
import noimg from '../profiles/noimg.png';


import redFace from './redFace.png';
import yallowFace from './yallowFace.png';
import Navbar from '../layout/Navbar';
import NavbarEnglish from '../layout/NavbarEnglish';
import { useTranslation } from 'react-i18next';




const noneDisplayI = 'noneDisplayI';
const displayI = 'displayI';
      
  
const MainUserHomeForFollowing = ({loading,match, addLike,auth,five_stars,
  removeLike, 
  deletePost,
  addFiveStars, addFourStars, addThreeStars, addTwoStars,addOneStars,
}) => {
 
   
  
  // const [getfivestarsuser,setgetfivestarsuser]= useState([])




    const [t, i18next] = useTranslation()
    const [userFollowing,setUserFollowing]= useState([])
    const [getPosts,setPosts]= useState([])
    const [get5sum,setget5sum]= useState([])
    const [get4sum,setget4sum]= useState([])
    const [get3sum,setget3sum]= useState([])


    const [activationComplete,setgetactivationComplete]= useState([])
    const [activationNo,setgetactivationNo]= useState([])
    const [premiumComplete,setgetpremiumComplete]= useState([])


     
    
    
    // const [getAuth,setAuth]= useState([])
    // const [getVote,setVoting]= useState([])

    const [visible,setVisible]= useState(20)
    const [showLike,setShowLike]= useState(true)
    // const [search,setSearch]= useState('')

    const currentResults = getPosts.slice(0,visible);

    
 

    //  const searchChanged = async e =>
    //  setSearch({ search: e.target.value });

    
    const [showModal ,setShowModal]= useState(true)
    const [hideModal  ,setHideModal]= useState(true)

 
    const [menulist ,setMenulist]= useState(true)
    const [menulist2 ,setMenulist2]= useState(false)
    
    const [followupsList,setFollowups]= useState([])

    const loadMore = async e => {
        setVisible(visible+10)

    }

   
       
      // window.addEventListener('scroll', () =>{
      //   const scrollable = document.documentElement.scrollHeight - window.innerHeight;
      //   const scrolled = window.scrollY;
      //   if(Math.ceil(scrolled) === scrollable ){
    
      //     setVisible(visible+10)
        
      //     return () => {
      //       window.removeEventListener('scroll');
      //     };
      //   }
      // })
  

 

 

    useEffect(() => {
       
        axios.get('/api/posts/user/home/for/posting')
        .then(res => {
          //console.log(res);
          setPosts(res.data)
        })
        .catch((err) => {
          console.log(err);
        })


        axios.get('/api/posts/user/home/for/following')
        .then(res => {
          //console.log(res);
          setUserFollowing(res.data)
        })
        .catch((err) => {
          console.log(err);
        })



        axios.get('/api/posts/get5sumUser')
        .then(res => {
          //console.log(res);
          setget5sum(res.data)
        })
        .catch((err) => {
          console.log(err);
        })



        axios.get('/api/posts/get4sumUser')
        .then(res => {
          //console.log(res);
          setget4sum(res.data)
        })
        .catch((err) => {
          console.log(err);
        })



        axios.get('/api/posts/get3sumUser')
        .then(res => {
          //console.log(res);
          setget3sum(res.data)
        })
        .catch((err) => {
          console.log(err);
        })




        axios.get('/api/posts/activationComplete')
        .then(res => {
          //console.log(res);
          setgetactivationComplete(res.data)
        })
        .catch((err) => {
          console.log(err);
        })



        axios.get('/api/posts/activationNo')
        .then(res => {
          //console.log(res);
          setgetactivationNo(res.data)
        })
        .catch((err) => {
          console.log(err);
        })


 

        axios.get('/api/posts/premiumComplete')
        .then(res => {
          //console.log(res);
          setgetpremiumComplete(res.data)
        })
        .catch((err) => {
          console.log(err);
        })

 
  
     

 

        // axios.get('/api/posts/getfivestarsuser')
        // .then(res => {
        //   //console.log(res);
        //   setgetfivestarsuser(res.data)
        // })
        // .catch((err) => {
        //   console.log(err);
        // })

 

        // axios.get('/api/votings')
        // .then(res => {
        //   //console.log(res);
        //   setVoting(res.data)
        // })
        // .catch((err) => {
        //   console.log(err);
        // })




        // axios.get('/api/auth')
        // .then(res => {
        //   //console.log(res);
        //   setAuth(res.data)
        // })
        // .catch((err) => {
        //   console.log(err);
        // })
  
 
        axios.get('/api/followups/userfollowing/home/list')
        .then(res => {
          //console.log(res);
          setFollowups(res.data)
        })
        .catch((err) => {
          console.log(err);
        })

      },[getPosts,followupsList]);

     
     const showModal1 = async e => {
      setShowModal(true)
      };

     
        const hideModal1 = async e => {
        setHideModal(false)
      };


      const showmenulist = async e => {
        setMenulist(false)
        setMenulist2(true)

      };

      const showmenulist2 = async e => {
        setMenulist(true)
        setMenulist2(false)
      };


      {i18next.language === 'ar' && moment.locale('ar'); }
      {i18next.language === 'en' && moment.locale('en'); }
      {i18next.language === 'fr' && moment.locale('fr'); }
     
      return loading ? (
        <Spinner/>
      ) : (
        <div>
        <div className="aqle3-main">
        <div className="mainword2">
        {i18next.language === 'ar'&&( <Fragment>  <Navbar /> </Fragment>)}
        {i18next.language === 'en'&&( <Fragment> <NavbarEnglish />  </Fragment> )}


 {/* ////////////////////////////////////////القائمة الجانبية بالعربي  ////////////////////////////////////// */}
        {i18next.language === 'ar'&&(
        <Fragment>  

        <div className="mainword-section-list-right">
        <div id="mySidenav"  className='sideNavHome' >

        {menulist === true &&(
          <Fragment> 
        <button className="categoryListButtonTop"  onClick={showmenulist}>  
         {' '}  
        <i class="fa fa-arrow-circle-o-up fa-2x" aria-hidden="true"></i>
        </button>
        </Fragment>
         )}



        {menulist2 === true &&(
          <Fragment> 
        <button className="categoryListButtonTop"  onClick={showmenulist2}>  
         {' '} 
         <i class="fa fa-arrow-circle-o-down fa-2x" aria-hidden="true"></i>
        </button>
        </Fragment>
         )}
 



        {menulist===true&&(
         <Fragment> 
         <button className="categoryListButtonTop"  onClick={showmenulist}>  
         المتاجر المفضلة لك {' '}  ({followupsList.length})
         <i class="fa fa-shopping-cart fa-2x" aria-hidden="true"></i>
         </button>

{ 
         followupsList.map(post => (
          <Fragment>
            {/* <Link to={`/shops/${post.following_user.username}`} className="">
            <img className="categoryListButton" src={post.following_shop.shop_logo_img}  />
              <> {post.following_shop.shop_name}</> 
            </Link> */} 
          <Link to={`/shops/${post.following_user.username}`}>
          <div className='shopList11'>
          {!post.following_user.shop_logo_img ?( 
            <img className="shopList22" src={noimg}  />
          ):(
            <img className="shopList22" src={post.following_user.shop_logo_img}  />

          )}

          <div className='shopList33'>
            <h1> {post.following_user.shop_name ? post.following_user.shop_name:<>{post.following_user.username}@</>}</h1>
            </div>
          </div>
          </Link>  
          </Fragment>
      ))}

      </Fragment>
        )}
        
        </div>
     
   
        </div>

        </Fragment>
        )}
        
 {/* ////////////////////////////////////////القائمة الجانبية بالعربي  نهاية////////////////////////////////////// */}


 {/* ////////////////////////////////////////القائمة الجانبية باالنجليزي  ///////////////////////////////////////// */}
 {i18next.language === 'en'&&(
        <Fragment>  

        <div className="mainword-section-list-left">
        <div id="mySidenav"  className='sideNavHome' >

        {menulist === true &&(
          <Fragment> 
        <button className="categoryListButtonTop"  onClick={showmenulist}>  
         {' '}  
        <i class="fa fa-arrow-circle-o-up fa-2x" aria-hidden="true"></i>
        </button>
        </Fragment>
         )}



        {menulist2 === true &&(
          <Fragment> 
        <button className="categoryListButtonTop"  onClick={showmenulist2}>  
         {' '} 
         <i class="fa fa-arrow-circle-o-down fa-2x" aria-hidden="true"></i>
        </button>
        </Fragment>
         )}
 



        {menulist===true&&(
         <Fragment> 
         <button className="categoryListButtonTop"  onClick={showmenulist}>  
         <i class="fa fa-shopping-cart fa-2x" aria-hidden="true"></i>
          {' '}  Favorite stores ({followupsList.length})
        
         </button>

{ 
         followupsList.map(post => (
          <Fragment>
            {/* <Link to={`/shops/${post.following_user.username}`} className="">
            <img className="categoryListButton" src={post.following_shop.shop_logo_img}  />
              <> {post.following_shop.shop_name}</> 
            </Link> */} 
          <Link to={`/shops/${post.following_user.username}`}>
          <div className='shopList11EN'>


  

            
          {!post.following_user.shop_logo_img ?( 
            <img className="shopList22EN" src={noimg}  />
          ):(
            <img className="shopList22EN" src={post.following_user.shop_logo_img}  />

          )}


          <div className='shopList33EN'>
            <h1> {post.following_user.shop_name ? post.following_user.shop_name:<>{post.following_user.username}@</>}</h1>
            </div>


          </div>
          </Link>  
          </Fragment>
      ))}

      </Fragment>
        )}
        
        </div>
    
   
        </div>

        </Fragment>
        )}

        {/* ///////////////////////////////////////////////////////نهاية القائمة الإنجليزية////////////////////////////////////////// */}


  


 

 
 
 


{ getPosts.length < 1 &&
<Fragment> 

<center> 
<div className='mainword-section-left'> 
<Spinner/>
 
</div>
</center>
</Fragment>
}


 
{/* //start All   ----------------------------------*/}     
     
<center>
 
      {/* {post2.following_shop._id} */}

<div className='mainword-section-left'> 
{/* <div className="dash-title">
  {i18next.language=== 'ar'&&( <> أحدث الإعلانات في بلدك </>)}
  {i18next.language=== 'en'&&( <> Latest ads in your country </>)}


</div> */}

 {currentResults
   
  

 .map(post => (

  
     <Fragment>
         
        
       
<center>

 

    <div className="main-list" key={post._id}>

    
   
      {post.premium === 'no' &&(
        <div className='title-in-list'>
        <a className='title-in-list'  target="_blank" href={`/posts/${post._id}#/${post.purpose_code}/${post.title}`}  >    
         {post.title}  
         </a>
        </div>
      )}


        {post.premium === 'yes' &&(
        <div className='title-in-list-premium'>
        <a className='title-in-list-premium'  target="_blank" href={`/posts/${post._id}#/${post.purpose_code}/${post.title}`}  >    
         {post.title}  
         </a>
        </div>
      )}

{i18next.language=== 'ar'&&(
  <Fragment>


  <div className='section-list'>
    <div>
    {post.image ? (
      <Link   to={`/posts/${post._id}#/${post.purpose_code}/${post.title}`} style={{color:'#fff',textDecoration:'none'}} > <img className='image-in-list' src={post.image}  /></Link>
      ):(
  
        <Link   to={`/posts/${post._id}#/${post.purpose_code}/${post.title}`} style={{color:'#fff',textDecoration:'none'}} > <img className='image-in-list' src={noimg}  /></Link>
      )}
</div> 

    
     
  <div>

        
  <div className="list-details"> {post.Main_paragraph} </div>
 


<div className="list-button" style={{display:'flex',alignContent:'center', width:'100%'}}>
<button className="Action-button-status">    <Moment format='YYYY/MM/DD'>{post.date}</Moment>  <i className="fa fa-calendar fa-2x"></i></button> 
<button className="Action-button-status" >  {post.clicks.length} <i className="fa fa-eye fa-2x"></i></button>  
 <Link className="Action-button-status"   to={`/shops/${post.user.username}`} style={{textDecoration:'none'}} >  المتجر<i className="fa fa-home fa-2x"></i> </Link>
 </div>

   
{/* <div className='list-button' style={{display:'flex',alignContent:'center', width:'100%'}}>

<button className='Action-button-update' >
<img  src={greenFace} />
<span> {post.five_stars.length}</span>
</button>

<button className='Action-button-update' >
<img  src={yallowFace} />
<span> {post.four_stars.length}</span>
</button>

<button className='Action-button-update'>
<img  src={redFace} />
<span>{post.three_stars.length}</span>
</button>
</div>  */}

      </div> 
      </div>
 </Fragment>
)}
{/* /////////////////////////////////////////////////////end section list Arabic////////////////////////////////////////////////// */}


{/* /////////////////////////////////////////////////////start section list English////////////////////////////////////////////////// */}

{i18next.language=== 'en'&&(
  <Fragment>


  <div className='section-list-english'>
    <div>
    {post.image ? (
      <Link   to={`/posts/${post._id}#/${post.purpose_code}/${post.title}`} style={{color:'#fff',textDecoration:'none'}} > <img className='image-in-list' src={post.image}  /></Link>
      ):(
  
        <Link   to={`/posts/${post._id}#/${post.purpose_code}/${post.title}`} style={{color:'#fff',textDecoration:'none'}} > <img className='image-in-list' src={noimg}  /></Link>
      )}
</div> 

    
     
  <div>

        
  <div className="list-details"> {post.Main_paragraph} </div>
 

<div className="list-button-english" style={{display:'flex', alignContent:'center', width:'100%'}}>
<button className="Action-button-status">    <Moment format='YYYY/MM/DD'>{post.date}</Moment>  <i className="fa fa-calendar fa-2x"></i></button> 
<button className="Action-button-status" >  {post.clicks.length} <i className="fa fa-eye fa-2x"></i></button>  
 <Link className="Action-button-status"   to={`/shops/${post.user.username}`} style={{textDecoration:'none'}} >  visit shop <i className="fa fa-home fa-2x"></i> </Link>
 </div>

   
{/* <div className='' style={{display:'flex',alignContent:'center', width:'100%'}}>

<button className='Action-button-update' >
<img  src={greenFace} />
<span> {post.five_stars.length}</span>
</button>






<button className='Action-button-update' >
<img  src={yallowFace} />
<span> {post.four_stars.length}</span>
</button>

<button className='Action-button-update'>
<img  src={redFace} />
<span>{post.three_stars.length}</span>
</button>
</div>  */}

      </div> 
      </div>
 </Fragment>
)}
{/* /////////////////////////////////////////////////////end section list English////////////////////////////////////////////////// */}


  </div>
  </center>

     </Fragment>
      ))}









  {/* onClick={() => loadMore()} */}


 </div>
 


        </center>



  {/* //End All   ----------------------------------*/}     



  </div>
 
   {visible < getPosts.length &&(
    <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  
      {i18next.language==='ar'&&   <> <i class="fa fa-arrow-down fa-1x"></i> ...المزيد </>}
      {i18next.language==='en'&&  <>  More... <i class="fa fa-arrow-down fa-1x"></i></>}

        </button> 
       </center>
  )} 

 
        </div>
        </div>


      
       
    )
}
 


MainUserHomeForFollowing.propTypes = {
 
    deletePost: PropTypes.func.isRequired,
    addLike: PropTypes.func.isRequired,
    addFiveStars: PropTypes.func.isRequired,
    addFourStars: PropTypes.func.isRequired,
    addThreeStars: PropTypes.func.isRequired,
    addTwoStars: PropTypes.func.isRequired,
    addOneStars: PropTypes.func.isRequired,

    removeLike: PropTypes.func.isRequired,
    showActions: PropTypes.bool,
    post: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
  
  };
  
  const mapStateToProps = state => ({
    market: state.market,
    auth: state.auth
  });
  
  export default connect(
    mapStateToProps,
    { addLike, removeLike, deletePost, addFiveStars, addFourStars, addThreeStars, addTwoStars,addOneStars }
  )(MainUserHomeForFollowing);

 

 