import React, { Fragment, useEffect ,useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';   
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import PostItem from '../posts/PostItem';
import CommentForm from '../post/CommentForm';
import CommentItem from '../post/CommentItem';
import { getPost } from '../../actions/post';

 
   
import { logout } from '../../actions/auth';
   
const Post = ({  auth, auth: { isAuthenticated, user }, getPost, post: { post, loading }, match }) => {
  
  // const [sizes,setSizes]= useState([])
  // const [colors,setColors]= useState([])
  const [post1,setPost]= useState([])

  useEffect(() => {
    getPost(match.params.id);
   }, [getPost]);


   useEffect(() => {
   
    // axios.get('/api/sizes/'+match.params.id)
    // .then(res => {
    //   //console.log(res);
    //   setSizes(res.data)
    // })
    // .catch((err) => {
    //   console.log(err);
    // })

 

    // axios.get('/api/colors/'+match.params.id)
    // .then(res => {
    //   //console.log(res);
    //   setColors(res.data)
    // })
    // .catch((err) => {
    //   console.log(err);
    // })


    axios.get('/api/posts/'+match.params.id)
    .then(res => {
      //console.log(res);
      setPost(res.data)
    })
    .catch((err) => {
      console.log(err);
    })


   }, [post1]);


     

  return loading || post === null ? (
    <Spinner />
  ) : (
    <Fragment>
 

 
      <PostItem post={post} product={post} match={match} showActions={false} colors={post.colors} sizes={post.sizes} post1={post1} />
 
            
      {isAuthenticated ?(
        <Fragment>

<center> 
      {/* <CommentForm postId={post._id} /> */}

      <br/><br/>

       
      <div className=''>
        {post.comments.map(comment => (
          <CommentItem key={comment._id} comment={comment} postId={post._id}  userID={post.user._id} userName={post.user.username}/>
        ))}
      </div> 
      </center> 
 

      </Fragment>
      ):(
        <Fragment>
        {/* <center> <h1>please login to your account to see the comments </h1></center>  */}
        </Fragment>
      )}
      

     
  

 
      
    </Fragment>
  );
};

Post.propTypes = {
  getPost: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,



 
};

const mapStateToProps = state => ({
  post: state.post,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getPost,logout }
)(Post);

