import React, { useState,useEffect ,Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
 
import axios from 'axios';
import Spinner from '../layout/Spinner';
import Navbar from '../layout/Navbar';
import NavbarEnglish from '../layout/NavbarEnglish';
import { useTranslation } from 'react-i18next';
   



 function EditUserNameComfirmation() {

    const [user,setUser]= useState([])
    const [t, i18next] = useTranslation()



    useEffect(()=>{
        axios.get('/api/auth')
        .then(res => {
          console.log(res);
          setUser(res.data)
        })
        .catch((err) => {
          console.log(err);
        }) 
        },[user])

   return (
    <div className="aqle3-main">
    <div className="mainword2">
    {i18next.language === 'ar'&&(<Navbar />)}
    {i18next.language === 'en'&&(<NavbarEnglish />)}

    {i18next.language === 'ar' &&(<div className='dash-title'> مبروك لقد تم تغيير اسم المستخدم بنجـاح  </div>)}
    {i18next.language === 'en' &&(<div className='dash-title'> Congratulations, your username has been successfully changed </div>)}
   

                {i18next.language === 'ar'&&(
                <h1 className='smallText'> @{user.username} :اسم المستخدم الحالي هو  </h1>
                )}

                {i18next.language === 'en'&&(
                <h1 className='smallText'> The current username is : @{user.username}</h1>
                )}


<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
  
     </div>
     </div>
   )
 }
  
 export default EditUserNameComfirmation