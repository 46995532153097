import React, { Fragment, useEffect ,useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteCategory } from '../../actions/category';
import ConfirmButton from "./ConfirmButton";
import axios from 'axios';
import Spinner from '../layout/Spinner';
import Navbar from '../layout/Navbar';
import NavbarEnglish from '../layout/NavbarEnglish';
import { useTranslation } from 'react-i18next';
  
  
const Categories = ({loading , deleteCategory}) => {
  const [t, i18next] = useTranslation()
  const [user,setUser]= useState([])

  const [showProperties,setShowProperties]= useState('noneDisplayI')  
  const [showCars,setShowCars]= useState('noneDisplayI') 
  const [showJobs,setShowJobs]= useState('noneDisplayI') 
  const [showServices,setShowServices]= useState('noneDisplayI') 
  const [showClassifieds,setShowClassifieds]= useState('noneDisplayI') 
  const [showElectronics,setShowElectronics]= useState('noneDisplayI') 
  const [showAnimals,setShowAnimals]= useState('noneDisplayI') 
  const [showFurniture,setShowFurniture]= useState('noneDisplayI') 
  const [showPersonalitems,setShowPersonalitems]= useState('noneDisplayI') 
  const [showFooddrinks,setShowFooddrinks]= useState('noneDisplayI') 

  const [showHealthFitness,setShowHealthFitness]= useState('noneDisplayI') 


  const [showSmartHome,setShowSmartHome]= useState('noneDisplayI') 
  const [showGames,setShowGames]= useState('noneDisplayI') 
  const [showSports,setShowSports]= useState('noneDisplayI') 
  const [showTravel,setShowTravel]= useState('noneDisplayI') 
  const [showBaby,setShowBaby]= useState('noneDisplayI') 
  const [showWomen,setShowWomen]= useState('noneDisplayI') 
  const [showMen,setShowMen]= useState('noneDisplayI') 
  const [showGirls,setShowGirls]= useState('noneDisplayI') 
  const [showBoys,setShowBoys]= useState('noneDisplayI') 
  const [showComputers,setShowComputers]= useState('noneDisplayI') 




  const clickProperties = async e => {
    setShowProperties('displayI');

 
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowFooddrinks('noneDisplayI');
    setShowHealthFitness('noneDisplayI');

    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
 
    setShowComputers('noneDisplayI');

     
  }

  const clickCars = async e => {
    setShowCars('displayI'); 

    setShowProperties('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowFooddrinks('noneDisplayI');
    setShowHealthFitness('noneDisplayI');

    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
 
    setShowComputers('noneDisplayI');
  }


  const clickJobs = async e => {
    setShowJobs('displayI'); 

    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowFooddrinks('noneDisplayI');
    setShowHealthFitness('noneDisplayI');

    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
 
    setShowComputers('noneDisplayI');
  }

  const clickServices = async e => {
    setShowServices('displayI');
    
    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowFooddrinks('noneDisplayI');
    setShowHealthFitness('noneDisplayI');

    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
 
    setShowComputers('noneDisplayI');
  }


  const clickClassifieds = async e => {
    setShowClassifieds('displayI'); 

    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowFooddrinks('noneDisplayI');
    setShowHealthFitness('noneDisplayI');

    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
 
    setShowComputers('noneDisplayI');
  }



  const clickElectronics = async e => {
    setShowElectronics('displayI'); 

    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowFooddrinks('noneDisplayI');
    setShowHealthFitness('noneDisplayI');

    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
  
    setShowComputers('noneDisplayI');

  }


  const clickAnimals = async e => {
    setShowAnimals('displayI'); 

    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowFooddrinks('noneDisplayI');
    setShowHealthFitness('noneDisplayI');

    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
  
    setShowComputers('noneDisplayI');
  }


  const clickFurniture = async e => {
    setShowFurniture('displayI'); 

    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowFooddrinks('noneDisplayI');
    setShowHealthFitness('noneDisplayI');

    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
   
    setShowComputers('noneDisplayI');
  }



  const clickPersonalitems = async e => {
    setShowPersonalitems('displayI'); 

    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowFooddrinks('noneDisplayI');
    setShowHealthFitness('noneDisplayI');

    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
  
    setShowComputers('noneDisplayI');
  }


  const clickFooddrinks = async e => {
    setShowFooddrinks('displayI'); 

    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowHealthFitness('noneDisplayI');


    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
   
    setShowComputers('noneDisplayI');
  }



  const clickHealthFitness = async e => {

    setShowHealthFitness('displayI')

    setShowFooddrinks('noneDisplayI'); 
    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');

    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
 
    setShowComputers('noneDisplayI');
  }



  const clickSmartHome = async e => {

    setShowFooddrinks('noneDisplayI'); 
    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowHealthFitness('noneDisplayI');

    setShowSmartHome('displayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
 
    setShowComputers('noneDisplayI');

  }

  const clickGames = async e => {

    
    setShowFooddrinks('noneDisplayI'); 
    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowHealthFitness('noneDisplayI');


    setShowSmartHome('noneDisplayI');
    setShowGames('displayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
 
    setShowComputers('noneDisplayI');

  }


  const clickSports = async e => {


    
    setShowFooddrinks('noneDisplayI'); 
    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowHealthFitness('noneDisplayI');



    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('displayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
  
    setShowComputers('noneDisplayI');



  }



  const clickTravel = async e => {


    
    setShowFooddrinks('noneDisplayI'); 
    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowHealthFitness('noneDisplayI');


    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('displayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
 
    setShowComputers('noneDisplayI');

  }

 

  const clickBaby = async e => {


    
    setShowFooddrinks('noneDisplayI'); 
    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowHealthFitness('noneDisplayI');



    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('displayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
 
    setShowComputers('noneDisplayI');

  }



  const clickWomen = async e => {

    
    setShowFooddrinks('noneDisplayI'); 
    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowHealthFitness('noneDisplayI');


    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('displayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
 
    setShowComputers('noneDisplayI');

  }


  const clickMen = async e => {


    
    setShowFooddrinks('noneDisplayI'); 
    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowHealthFitness('noneDisplayI');


    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('displayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
 
    setShowComputers('noneDisplayI');

  }


  const clickGirls = async e => {


    
    setShowFooddrinks('noneDisplayI'); 
    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowHealthFitness('noneDisplayI');


    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('displayI');
    setShowBoys('noneDisplayI');

    setShowComputers('noneDisplayI');

  }


  const clickBoys = async e => {


    
    setShowFooddrinks('noneDisplayI'); 
    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowHealthFitness('noneDisplayI');



    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('displayI');
  
    setShowComputers('noneDisplayI');

  }


  const clickComputers = async e => {


    
    setShowFooddrinks('noneDisplayI'); 
    setShowProperties('noneDisplayI');
    setShowCars('noneDisplayI');
    setShowJobs('noneDisplayI');
    setShowServices('noneDisplayI');
    setShowClassifieds('noneDisplayI');
    setShowElectronics('noneDisplayI');
    setShowAnimals('noneDisplayI');
    setShowFurniture('noneDisplayI');
    setShowPersonalitems('noneDisplayI');
    setShowHealthFitness('noneDisplayI');



    setShowSmartHome('noneDisplayI');
    setShowGames('noneDisplayI');
    setShowSports('noneDisplayI');
    setShowTravel('noneDisplayI');
    setShowBaby('noneDisplayI');
    setShowWomen('noneDisplayI');
    setShowMen('noneDisplayI');
    setShowGirls('noneDisplayI');
    setShowBoys('noneDisplayI');
   
    setShowComputers('displayI');


  }
  
    const [getProperties,setProperties]= useState([])
    const [getCars,setCars]= useState([])
    const [getJobs,setJobs]= useState([])
    const [getServices,setServices]= useState([])
    const [getClassifieds,setClassifieds]= useState([])


    const [getElectronics,setElectronics]= useState([])
    const [getAnimals,setAnimals]= useState([])
    const [getFurniture,setFurniture]= useState([])
    const [getPersonalitems,setPersonalitems]= useState([])
    const [getFooddrinks,setFooddrinks]= useState([])


    const [getHealthFitness,setHealthFitness]= useState([])


    const [getSmartHome,setSmartHome]= useState([])
    const [getGames,setGames]= useState([])
    const [getSports,setSports]= useState([])
    const [getTravel,setTravel]= useState([])
    const [getBaby,setBaby]= useState([])
    const [getWomen,setWomen]= useState([])
    const [getMen,setMen]= useState([])
    const [getGirls,setGirls]= useState([])
    const [getBoys,setBoys]= useState([])
    const [getComputers,setComputers]= useState([])







    


 


    



    
    
    const [visible,setVisible]= useState(20)

    const currentResultsProperties = getProperties.slice(0,visible);
    const currentResultsCars = getCars.slice(0,visible);
    const currentResultsJobs = getJobs.slice(0,visible);
    const currentResultsServices = getServices.slice(0,visible);
    const currentResultsClassifieds = getClassifieds.slice(0,visible);



    const currentResultsElectronics = getElectronics.slice(0,visible);
    const currentResultsAnimals = getAnimals.slice(0,visible);
    const currentResultsFurniture = getFurniture.slice(0,visible);
    const currentResultsPersonalitems = getPersonalitems.slice(0,visible);
    const currentResultsFooddrinks = getFooddrinks.slice(0,visible);


    const currentResultsHealthFitness = getHealthFitness.slice(0,visible);


    const currentResultsSmartHome = getSmartHome.slice(0,visible);
    const currentResultsGames = getGames.slice(0,visible);
    const currentResultsSports = getSports.slice(0,visible);
    const currentResultsTravel = getTravel.slice(0,visible);
    const currentResultsBaby = getBaby.slice(0,visible);
    const currentResultsWomen = getWomen.slice(0,visible);
    const currentResultsMen = getMen.slice(0,visible);
    const currentResultsGirls = getGirls.slice(0,visible);
    const currentResultsBoys = getBoys.slice(0,visible);
    const currentResultsComputers = getComputers.slice(0,visible);
    


    


    const loadMore = async e => {
        setVisible(visible+10)
    }


    useEffect(() => {

      axios.get('/api/auth')
      .then(res => {
        setUser(res.data)
      }) 
      .catch((err) => {
        console.log(err);
      })


        axios.get('/api/categories/properties')
        .then(res => {
          //console.log(res);
          setProperties(res.data)
        })
        .catch((err) => {
          console.log(err);
        })



        axios.get('/api/categories/cars')
        .then(res => {
          //console.log(res);
          setCars(res.data)
        })
        .catch((err) => {
          console.log(err);
        })




        axios.get('/api/categories/jobs')
        .then(res => {
          //console.log(res);
          setJobs(res.data)
        })
        .catch((err) => {
          console.log(err);
        })




        axios.get('/api/categories/services')
        .then(res => {
          //console.log(res);
          setServices(res.data)
        })
        .catch((err) => {
          console.log(err);
        })




        axios.get('/api/categories/classifieds')
        .then(res => {
          //console.log(res);
          setClassifieds(res.data)
        })
        .catch((err) => {
          console.log(err);
        })



        axios.get('/api/categories/electronics')
        .then(res => {
          //console.log(res);
          setElectronics(res.data)
        })
        .catch((err) => {
          console.log(err);
        })




        axios.get('/api/categories/animals')
        .then(res => {
          //console.log(res);
          setAnimals(res.data)
        })
        .catch((err) => {
          console.log(err);
        })

 


        axios.get('/api/categories/furniture')
        .then(res => {
          //console.log(res);
          setFurniture(res.data)
        })
        .catch((err) => {
          console.log(err);
        })




        axios.get('/api/categories/personal-items')
        .then(res => {
          //console.log(res);
          setPersonalitems(res.data)
        })
        .catch((err) => {
          console.log(err);
        })



        axios.get('/api/categories/food-drinks')
        .then(res => {
          //console.log(res);
          setFooddrinks(res.data)
        })
        .catch((err) => {
          console.log(err);
        })



        axios.get('/api/categories/health-fitness')
        .then(res => {
          //console.log(res);
          setHealthFitness(res.data)
        })
        .catch((err) => {
          console.log(err);
        })



        axios.get('/api/categories/smarthome')
        .then(res => {
          //console.log(res);
          setSmartHome(res.data)
        })
        .catch((err) => {
          console.log(err);
        })




        axios.get('/api/categories/games')
        .then(res => {
          //console.log(res);
          setGames(res.data)
        })
        .catch((err) => {
          console.log(err);
        })




        axios.get('/api/categories/sports')
        .then(res => {
          //console.log(res);
          setSports(res.data)
        })
        .catch((err) => {
          console.log(err);
        })




        axios.get('/api/categories/travel')
        .then(res => {
          //console.log(res);
          setTravel(res.data)
        })
        .catch((err) => {
          console.log(err);
        })



        axios.get('/api/categories/baby')
        .then(res => {
          //console.log(res);
          setBaby(res.data)
        })
        .catch((err) => {
          console.log(err);
        })


          

        axios.get('/api/categories/women')
        .then(res => {
          //console.log(res);
          setWomen(res.data)
        })
        .catch((err) => {
          console.log(err);
        })




        axios.get('/api/categories/men')
        .then(res => {
          //console.log(res);
          setMen(res.data)
        })
        .catch((err) => {
          console.log(err);
        })




        axios.get('/api/categories/girls')
        .then(res => {
          //console.log(res);
          setGirls(res.data)
        })
        .catch((err) => {
          console.log(err);
        })





        axios.get('/api/categories/boys')
        .then(res => {
          //console.log(res);
          setBoys(res.data)
        })
        .catch((err) => {
          console.log(err);
        })






        axios.get('/api/categories/computers')
        .then(res => {
          //console.log(res);
          setComputers(res.data)
        })
        .catch((err) => {
          console.log(err);
        })


      
         
 
      }, [user]);




      const CategoriesValid = ( 
        <div>
          
 

        <div className="mainForm">
        <center>
        <div className="dash-title"> {t('categories_management_title')} </div>
 
     
        <Link to="/dashboard/main" className="Action-button-plus-admin">  <i className="fa fa-arrow-left fa-1x"></i> {t('backButton')} </Link>
        <Link to="/dashboard/categories/Addcategory" className="Action-button-plus-admin">  <i className="fa fa-plus fa-1x"></i> {t('addButton')}   </Link>
        </center>
 
 
  <div onClick={clickProperties} className="Action-button-plus-admin">   {t('categories_properties')}   </div>
  <div onClick={clickCars} className="Action-button-plus-admin">   {t('categories_cars')}   </div>
  <div onClick={clickJobs} className="Action-button-plus-admin">   {t('categories_Jobs')}    </div>
  <div onClick={clickServices} className="Action-button-plus-admin">   {t('categories_Services')}   </div>
  <div onClick={clickClassifieds} className="Action-button-plus-admin">    {t('categories_Classifieds')}   </div>
  <div onClick={clickElectronics} className="Action-button-plus-admin">    {t('Electronics')}   </div>
  <div onClick={clickAnimals} className="Action-button-plus-admin">    {t('categories_Animals')}   </div>
  <div onClick={clickFurniture} className="Action-button-plus-admin">   {t('categories_Furniture')}    </div>
  <div onClick={clickPersonalitems} className="Action-button-plus-admin">   {t('categories_Personalitems')}    </div>
  <div onClick={clickFooddrinks} className="Action-button-plus-admin">   {t('categories_Fooddrinks')}   </div>
  <div onClick={clickHealthFitness} className="Action-button-plus-admin">   {t('categories_Health_Fitness')}   </div>

  


  <div onClick={clickSmartHome} className="Action-button-plus-admin">   {t('SmartHome')}   </div>
  <div onClick={clickGames} className="Action-button-plus-admin">   {t('Games')}   </div>
  <div onClick={clickSports} className="Action-button-plus-admin">   {t('Sports')}   </div>
  <div onClick={clickTravel} className="Action-button-plus-admin">   {t('Travel')}   </div>
  <div onClick={clickBaby} className="Action-button-plus-admin">   {t('Baby')}   </div>
  <div onClick={clickWomen} className="Action-button-plus-admin">   {t('Women')}   </div>
  <div onClick={clickMen} className="Action-button-plus-admin">   {t('Men')}   </div>
  <div onClick={clickGirls} className="Action-button-plus-admin">   {t('Girls')}   </div>
  <div onClick={clickBoys} className="Action-button-plus-admin">   {t('Boys')}   </div>
  <div onClick={clickComputers} className="Action-button-plus-admin">   {t('Computers')}   </div>



  


 <div className='' style={{width:'100%',display:'flex'}}>


 {/* /////////////////////////////////////////////// العقارات//////////////////////////////////////////////// */}
 <div className={showProperties} style={{width:'90%'}}>
  
 <div className="dash-title"> {t('categories_properties')} - {getProperties.length} </div>
 
 
  {currentResultsProperties
   .map(category => (
  
  <center>
  <div class="main-list" key={category._id}>


  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name}    
  </a>
  </div>

 

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

   
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

          { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}
           

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getProperties.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>
{/* ///////////////////////////////////////////////نهاية العقارات//////////////////////////////////////////////// */}










 {/* /////////////////////////////////////////////// السيارات//////////////////////////////////////////////// */}
 <div className={showCars} style={{width:'88%'}}>
 <div className="dash-title"> {t('categories_cars')} - {getCars.length}</div>

  {currentResultsCars
   .map(category => (
 
  <center>
  <div class="main-list" key={category._id}>


  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>



  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

   
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 



         { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getCars.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>

{/* ///////////////////////////////////////////////نهاية السيارات//////////////////////////////////////////////// */}





 {/* /////////////////////////////////////////////// الوظائف//////////////////////////////////////////////// */}
 <div className={showJobs} style={{width:'88%'}}>
 <div className="dash-title"> {t('categories_Jobs')} - {getJobs.length}</div>

  {currentResultsJobs
   .map(category => (
 
  <center>
  <div class="main-list" key={category._id}>


  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>



  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

   
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

       { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getJobs.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>

{/* //////////////////////////////////////////////نهاية الوظائف/////////////////////////////////////////////// */}









 {/* /////////////////////////////////////////////// الخدمات//////////////////////////////////////////////// */}
 <div className={showServices} style={{width:'88%'}}>
 <div className="dash-title"> {t('categories_Services')} - {getServices.length}</div>

  {currentResultsServices
   .map(category => (
 
  <center>
  <div class="main-list" key={category._id}>


  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>



  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

   
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

        { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getServices.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>

{/* //////////////////////////////////////////////نهاية الخدمات/////////////////////////////////////////////// */}









 

 {/* ///////////////////////////////////////////السلع والمنتجات //////////////////////////////////////////////// */}
 <div className={showClassifieds} style={{width:'88%'}}>
 <div className="dash-title"> {t('categories_Classifieds')} - {getClassifieds.length}</div>

  {currentResultsClassifieds
   .map(category => (
 
  <center>
  <div class="main-list" key={category._id}>


  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>



  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

   
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

        { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getClassifieds.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>

{/* //////////////////////////////////////////////نهاية السلع/////////////////////////////////////////////// */}





 {/* ///////////////////////////////////////////الاجهزة //////////////////////////////////////////////// */}
 <div className={showElectronics} style={{width:'88%'}}>
 <div className="dash-title"> {t('categories_Electronics')} - {getElectronics.length}</div>
  {currentResultsElectronics
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

   
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

       { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getElectronics.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////نهاية الاجهزة//////////////////////////////////////////////// */}





 {/* ///////////////////////////////////////////بداية الحيوانات //////////////////////////////////////////////// */}
 <div className={showAnimals} style={{width:'88%'}}>
 <div className="dash-title"> {t('categories_Animals')} - {getAnimals.length}</div>
  {currentResultsAnimals
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
  
    </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

   
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

          { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getAnimals.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////نهاية الحيوانات//////////////////////////////////////////////// */}






 {/* ///////////////////////////////////////////بداية الاثاث //////////////////////////////////////////////// */}
 <div className={showFurniture} style={{width:'88%'}}>
 <div className="dash-title"> {t('categories_Furniture')} - {getFurniture.length}</div>
  {currentResultsFurniture
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
    </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

   
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

        { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getFurniture.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////نهاية الاثاث//////////////////////////////////////////////// */}







 {/* ///////////////////////////////////////////بداية المستلزمات الشخصية //////////////////////////////////////////////// */}
 <div className={showPersonalitems} style={{width:'88%'}}>
 <div className="dash-title"> {t('categories_Personalitems')} - {getPersonalitems.length}</div>
  {currentResultsPersonalitems
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

   
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

         { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getPersonalitems.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////نهاية المستلزمات الشخصية//////////////////////////////////////////////// */}








 {/* ///////////////////////////////////////////بداية الاكل والشراب //////////////////////////////////////////////// */}
 <div className={showFooddrinks} style={{width:'88%'}}>
 <div className="dash-title"> {t('categories_Fooddrinks')} - {getFooddrinks.length}</div>
  {currentResultsFooddrinks
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

    
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

         { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getFooddrinks.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////نهاية الاكل والشراب//////////////////////////////////////////////// */}



 

 {/* ///////////////////////////////////////////بداية ال health and fitness  //////////////////////////////////////////////// */}
 <div className={showHealthFitness} style={{width:'88%'}}>
 <div className="dash-title"> {t('categories_Health_Fitness')} - {getHealthFitness.length}</div>
  {currentResultsHealthFitness
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

    
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

         { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getHealthFitness.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////end of fitness and health//////////////////////////////////////////////// */}

 







 {/* /////////////////////////////////////////// SmartHome  //////////////////////////////////////////////// */}
 <div className={showSmartHome} style={{width:'88%'}}>
 <div className="dash-title"> {t('SmartHome')} - {getSmartHome.length}</div>
  {currentResultsSmartHome
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

    
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

         { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getSmartHome.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////end of SmartHome//////////////////////////////////////////////// */}











 {/* /////////////////////////////////////////// Games  //////////////////////////////////////////////// */}
 <div className={showGames} style={{width:'88%'}}>
 <div className="dash-title"> {t('Games')} - {getGames.length}</div>
  {currentResultsGames
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

    
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

         { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getGames.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////end of Games//////////////////////////////////////////////// */}









 {/* /////////////////////////////////////////// Sports  //////////////////////////////////////////////// */}
 <div className={showSports} style={{width:'88%'}}>
 <div className="dash-title"> {t('Sports')} - {getSports.length}</div>
  {currentResultsSports
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

    
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

         { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getSports.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////end of Sports//////////////////////////////////////////////// */}











 {/* /////////////////////////////////////////// Travel  //////////////////////////////////////////////// */}
 <div className={showTravel} style={{width:'88%'}}>
 <div className="dash-title"> {t('Travel')} - {getTravel.length}</div>
  {currentResultsTravel
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

    
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

         { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getTravel.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////end of Travel//////////////////////////////////////////////// */}











 {/* /////////////////////////////////////////// Baby  //////////////////////////////////////////////// */}
 <div className={showBaby} style={{width:'88%'}}>
 <div className="dash-title"> {t('Baby')} - {getBaby.length}</div>
  {currentResultsBaby
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

    
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

         { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getBaby.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////end of Baby//////////////////////////////////////////////// */}










 {/* /////////////////////////////////////////// Women  //////////////////////////////////////////////// */}
 <div className={showWomen} style={{width:'88%'}}>
 <div className="dash-title"> {t('Women')} - {getWomen.length}</div>
  {currentResultsWomen
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

    
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

         { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getWomen.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////end of Women//////////////////////////////////////////////// */}














 {/* /////////////////////////////////////////// Men  //////////////////////////////////////////////// */}
 <div className={showMen} style={{width:'88%'}}>
 <div className="dash-title"> {t('Men')} - {getMen.length}</div>
  {currentResultsMen
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

    
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

         { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getMen.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////end of Men//////////////////////////////////////////////// */}












 {/* /////////////////////////////////////////// Girls  //////////////////////////////////////////////// */}
 <div className={showGirls} style={{width:'88%'}}>
 <div className="dash-title"> {t('Girls')} - {getGirls.length}</div>
  {currentResultsGirls
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

    
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

         { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getGirls.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////end of Girls//////////////////////////////////////////////// */}













 {/* /////////////////////////////////////////// Boys  //////////////////////////////////////////////// */}
 <div className={showBoys} style={{width:'88%'}}>
 <div className="dash-title"> {t('Boys')} - {getBoys.length}</div>
  {currentResultsBoys
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

    
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

         { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getBoys.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////end of Boys//////////////////////////////////////////////// */}















 {/* /////////////////////////////////////////// Computers  //////////////////////////////////////////////// */}
 <div className={showComputers} style={{width:'88%'}}>
 <div className="dash-title"> {t('Computers')} - {getComputers.length}</div>
  {currentResultsComputers
   .map(category => (
  <center>
  <div class="main-list" key={category._id}>
  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name} 
   </a>
  </div>

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

    
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button> 
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

         { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getComputers.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>


{/* ///////////////////////////////////////////////end of Computers//////////////////////////////////////////////// */}







           </div>
        </div>
        </div>

    )

 
const notValidPage =(
  <Fragment>
         <center> 
       <Spinner />
      </center>
  </Fragment>
)
  
    return(
      <div className="aqle3-main">
      <div className="mainword2"> 
      {i18next.language === 'ar'&&(<Navbar />)}
      {i18next.language === 'en'&&(<NavbarEnglish />)}
      <center>  
      <div className='info-nav-main-frame'> 
      {user.validity === "super" || user.validity === "admin"  || user.validity === "normal"  ?  CategoriesValid : notValidPage}
      </div>
      </center>
    
    </div>
    </div>
    );
}
 
 

Categories.propTypes = {
    //getMarkets: PropTypes.func.isRequired,
    //market: PropTypes.object.isRequired,
    deleteCategory: PropTypes.func.isRequired,
  };


  const mapStateToProps = state => ({
    category: state.category
  });


    
  export default connect(
    mapStateToProps,
    {deleteCategory}
  )(Categories);

 
