import React, { Fragment, useEffect ,useState } from 'react';
import { Link } from 'react-router-dom';
import PostsArabic from './PostsArabic'
import PostsEnglish from './PostsEnglish'
import { useTranslation } from 'react-i18next';
import Navbar from '../../components/layout/Navbar';
import NavbarEnglish from '../../components/layout/NavbarEnglish';
 
export const Posts = ({match}) => {
  const Lang = match.params.lang;
  const [t, i18next] = useTranslation()
  return (

<Fragment> 
<div className="aqle3-main" >
<div className="mainword2">
{i18next.language === 'ar'&&( <Fragment>  <Navbar />  </Fragment>)}
{i18next.language === 'en'&&( <Fragment> <NavbarEnglish /> </Fragment> )}

<div className="mainword-section-left">
{i18next.language === 'ar'&&( <Fragment>   <PostsArabic /> </Fragment>)}
{i18next.language === 'en'&&( <Fragment>  <PostsEnglish /> </Fragment> )}

</div>

</div>
</div>
</Fragment>
  )
}
  
  
export default Posts