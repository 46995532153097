import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addComment } from '../../actions/post';
 
import { useTranslation } from 'react-i18next';
import Spinner from '../layout/Spinner';
 
const CommentForm = ({ postId, addComment }) => {

  const [t, i18next] = useTranslation()
  const [text, setText] = useState('');
  
  return (
    <center>
      <div className="aqle3-main">
       <div className="mainword2">
        
       
    <div className='formcomment11 '>
    
    <div className=''>
      <form
        className='' 
        onSubmit={e => {
          e.preventDefault();
          addComment(postId, { text });
          setText('');
        }}
      > 
  
      {i18next.language === 'ar' &&(
        <Fragment> 
        <input
         className="Formcomments"
          name='text'
          placeholder={t('postItems_comment_form_writeComment')}
          value={text}
          onChange={e => setText(e.target.value)}
          required
        />
         
        <button className="Commentbutton" type='submit'  >  
        {t('postItems_comment_form_sendComment')} {' '}
          <i class="fa fa-paper-plane" aria-hidden="true"></i> 
          </button>
          </Fragment>
      )}


 

{i18next.language === 'en' &&(
        <Fragment> 

<button className="CommentbuttonENglish" type='submit'  >  
        {t('postItems_comment_form_sendComment')} {' '}
          <i class="fa fa-paper-plane" aria-hidden="true"></i> 
          </button>
 
        <input
         className="FormcommentsEnglish"
          name='text'
          placeholder={t('postItems_comment_form_writeComment')}
          value={text}
          onChange={e => setText(e.target.value)}
          required
        /> 
         
    

          </Fragment>
      )}
        
      </form>
    </div>
    </div>
    
    </div>
    </div>
 
    </center>
   
    

   
  );
};

CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired
};

export default connect(
  null,
  { addComment }
)(CommentForm);
