import React, {Fragment,useEffect,useState } from "react"; 
// import { useSnackbar } from "notistack"; 
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { editPost } from '../../actions/post';
import { useTranslation } from 'react-i18next';
import { useFormik } from "formik"; 
   
const EditPost11 = ({isOpen, toggle,postID,Main_paragraph1,title1,price1, editPost}) => {
  
  const [t, i18next] = useTranslation()
//   const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    document.body.classList.toggle("modal-open", isOpen);
  }, [isOpen]);


 

  const [formData, setFormData] = useState({
    Main_paragraph:'' || Main_paragraph1,
    title:''|| title1,
    price: '' || price1
    });

    const { Main_paragraph, title, price } = formData;

    const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

 
  // const formik = useFormik({ 
  //   initialValues: {
  //       Main_paragraph: ""|| Main_paragraph ,
  //       title: ""|| title ,
  //       price: ""|| price ,
  //   }, 
  //   onSubmit: (values) => {
  //   // console.log(values);
  //   let formData = new URLSearchParams();
  //   // formData.append("_id",postID);
  //   formData.append("Main_paragraph", values.Main_paragraph);
  //   formData.append("title", values.title);
  //   formData.append("price", values.price);
  //   editPost(formData,postID);

    // editPost(formData,postID).then((res) => {
    //     // console.log(res);
    //     //  enqueueSnackbar(res?.message);
    //     if (res?.response_code === 200) {

    //         toggle();
    //       // navigate(`/property/${id}`);
    //     //   window.location = `/property/${id}`
    //     }
    //   });
  //   },
  // });





    const onSubmit = async e => {
      e.preventDefault();
       await editPost({title, Main_paragraph, price},postID);

       window.location=`/posts/${postID}`

      
    };

 
 

  const handleDelete = () => {
    toggle();

  
    }
 




  return (
    
    <>
    <div
      className={isOpen ? "modal fade show" : "modal fade"}
      id="DeleteModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="DeleteModalTitle"
      aria-hidden={isOpen}
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
  
          {i18next.language === 'ar' &&(<div className="abokhaled-title-button-new-blue">تحديث  </div>)}
          {i18next.language === 'en' &&(<div className="abokhaled-title-button-new-blue">Update the product</div>)}
 
          <button
                type="button"
                className="abokhaled-close-button-new-blue"
                data-dismiss="modal"
                onClick={toggle} 
              > 
                <i className="fa fa-close fa-2x"></i>
              </button>

          
          </div>
          <div className="modal-body text-center delete-modal">
        
      
          
          
 

   <div className='mainForm'>
    
    <div className=''>
      <form
        className='' 
        onSubmit={e => onSubmit(e)}
      > 
    
      {i18next.language === 'ar' &&(
        <Fragment> 


<span> عنوان المنتج </span>
        <input
         className="login-input"
          placeholder='عنوان المنتج'
          name='title'
          value={title} 
          onChange={e => onChange(e)}
           
        />

        <span> وصف المنتج </span>
        <input
         className="login-input"
          placeholder='وصف المنتج'
          name='Main_paragraph'
          value={Main_paragraph} 
          onChange={e => onChange(e)}
          required
        />



<span> السعر </span>
        <input
         className="login-input"
          placeholder='السعر'
          name='price'
          value={price} 
          onChange={e => onChange(e)}
           
        />


    <button className="Formbutton" type='submit'   onClick={toggle}  >  
        تحديث   
      
          </button>
         
    
          </Fragment>
      )}


 

{i18next.language === 'en' &&(
        <Fragment> 

 
<input
         className="login-input"
          placeholder='وصف المنتج'
          name='Main_paragraph'
          value={Main_paragraph} 
          onChange={e => onChange(e)}
          required
        />


        <input
         className="login-input"
          placeholder='عنوان المنتج'
          name='title'
          value={title} 
          onChange={e => onChange(e)}
           
        />


        <input
         className="login-input"
          placeholder='السعر'
          name='price'
          value={price} 
          onChange={e => onChange(e)}
           
        />


    <button className="Formbutton" type='submit'   onClick={toggle}  >  
        Update  
         
          </button>

           </Fragment>
      )}
        
      </form>
    </div>
    </div>




  
           
            <div className="modal-footer border-0 justify-content-center">
              
              {/* <button
                type="button"
                className="abokhaled-close-button-new-blue"
                data-dismiss="modal"
                onClick={toggle} 
              > 
                <i className="fa fa-close fa-2x"></i>
              </button> */}


              {/* <button
                type="button" 
                className="abokhaled-delete-button-new-red"
                onClick={handleDelete}
              >
                <i class="fa fa-trash fa-2x" aria-hidden="true"></i>
              </button> */}


            </div>


          </div>
        </div>
      </div>
    </div>
    {isOpen && <div className="modal-backdrop fade show"></div>}
  </>

  )
}


EditPost11.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  editPost: PropTypes.func.isRequired
};
  

export default connect(
  null,
  { editPost }
)(EditPost11);


 