import React, { Fragment, useEffect ,useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import ff from './system-icon.jpg';
import { useTranslation } from 'react-i18next';
 
import axios from 'axios';

    

const mySidenav0 = 'sidenavnoneEnglish';
const sidenav = 'sidenavEnglish';


 
const Navbar = ({ auth: { isAuthenticated, loading }, logout,shopID,shop }) => {

  const [t, i18next] = useTranslation()

  const [mySidenav, setmySidenav] = useState(mySidenav0)

  const [categories11,setCategory11]= useState([])
 
  const openMenu = async e => {
 
    setmySidenav(sidenav);
  };

  

  const closeMenu = async e => {
 
    setmySidenav(mySidenav0);
  };


 

  
  useEffect(() => {
   
    // axios.get('/api/categories/public')
    // .then(res => {
    //   console.log(res);
    // setCategory11(res.data)
    // })
    // .catch((err) => {
    //   console.log(err);
    // })




  }, []);


    
 
  const authLinks = (
 
        <ul className="topnavSYSEN" >
        <div className="logoContentSYSEN">
        <Link to={`/shops/${shopID}`}>
        {/* <img className="logo" src={ff}/> */}
        {shop.map(shop => ( <img className="logo"  src={shop.shop_logo_img} />    ))} 
        </Link>
        </div>
<div id="mySidenav"   className={mySidenav}>
 
  <center> 
 <button onClick={closeMenu} className="closebtn">&times;</button>
 

 {/* {categories11.map(catego=>(
<Fragment> 
  <a href={`/displayCategoryItems/${catego._id}#/${catego.c_name}`} > 
 <span><img className="" src={catego.image} style={{width:'50px' , height:'50px', marginRight:'20px'}}/></span>
 {catego.c_name} </a> 
  </Fragment>
 ))} */}
  <Link to='/'> <i class="fa fa-user" aria-hidden="true"></i>{' '}Home</Link>
  <Link to='/dashboard/main'> <i class="fa fa-cog" aria-hidden="true"></i> {' '}  Control Panel </Link>
  <Link to='/dashboard/posts'> <i class="fa fa-cog" aria-hidden="true"></i> {' '}  Products </Link>

  {/* <a href='/dashboard/MyProfile'> <i class="fa fa-user" aria-hidden="true"></i>{' '}  Profile</a>
 <Link to='/shops'>  <i class="fa fa-user-circle" aria-hidden="true"></i> {' '} Members </Link>
 <Link to='/dashboard/posts'> <i class="fa fa-plus" aria-hidden="true"></i>{' '}  Add your ad</Link>
 <Link to='/membership/prices'> <i className="fa fa-star fa-1x"></i>{' '}  Premium membership</Link> */}


 <Link className="" onClick={logout} to='/'>
          
          <span className='LogoutColor'> <i class="fa fa-power-off" aria-hidden="true"></i>{' '} logout </span>
          <i className='fas fa-sign-out-alt' />
        </Link>


 {i18next.language === 'ar' && <Link  onClick={()=>{i18next.changeLanguage('en')}}> 
          <span className='hide-sm'>{t('lang')} </span>
          {' '}<i className="fa fa-globe fa-1x"></i> 
        </Link>}

        {i18next.language === 'en' && <Link  onClick={()=>{i18next.changeLanguage('ar')}}> 
          <span className='hide-sm'>{t('lang')} </span>
          {' '}<i className="fa fa-globe fa-1x"></i> 
        </Link>}

 </center> 
</div> 
      {/* <li className="left">
      <Link to='/'>
        <img src={ss} width="60px" height="40px"/>
        </Link>
        </li> */}

    <li className="">
        <Link to='/'>
        <i class="fa fa-home" aria-hidden="true"></i> 
        {' '}Home</Link>
      </li>

      {/* <li className="">
        <a href='/dashboard/MyProfile'>
        <i class="fa fa-user" aria-hidden="true"></i> 
        {' '}Profile</a>
      </li> */}
      {/* <li className="">
        <Link to='/dashboard/chats'>
         <i class="fa fa-envelope" aria-hidden="true"></i>  
         {' '} Inbox
         
          </Link>
      </li> */}
 

{/* <li className="">
        <Link to='/dashboard/posts'>
        <i class="fa fa-plus" aria-hidden="true"></i>{' '}
          <span className='hide-sm'>Add Your Ads</span>
        </Link>
      </li> */}


       <li className="">
        <Link to='/dashboard/main'>
        <i class="fa fa-cog" aria-hidden="true"></i>{' '}
          <span className='hide-sm'>Dashboard</span>
        </Link>
      </li>  



      <li className="">
        <Link to='/dashboard/posts'>
        <i class="fa fa-cog" aria-hidden="true"></i>{' '}
          <span className='hide-sm'>Products</span>
        </Link>
      </li>  


      {/* <li className="">
      <Link to='/membership/prices'>
        <i className="fa fa-star fa-1x"></i> {' '}
          <span className='hide-sm'>Premium membership </span>
        </Link>

      </li> */}



      {/* <li className="">
        <Link to='/shops'>
         <i class="fa fa-user-circle" aria-hidden="true"></i>
         {' '} Members
        </Link>
      </li> */}
 

    <li className="">
     {i18next.language === 'ar' && <Link  onClick={()=>{i18next.changeLanguage('en')}}> 
          <span className='hide-sm'>{t('lang')} </span>
          {' '}<i className="fa fa-globe fa-1x"></i> 
        </Link>}

        {i18next.language === 'en' && <Link  onClick={()=>{i18next.changeLanguage('ar')}}> 
          <span className='hide-sm'>{t('lang')} </span>
          {' '}<i className="fa fa-globe fa-1x"></i> 
        </Link>}

      </li> 
      
      <li className=" ">
        <Link className="LogoutColor" onClick={logout} to='/'>
          <i className='fas fa-sign-out-alt' />{' '}
          <span className=''><i class="fa fa-power-off" aria-hidden="true"></i></span>
        </Link>
      </li> 

      <li>
      <button className="buttonNavEN" onClick={openMenu} >  &#9776; </button>
      </li>
 
 
 

    </ul>
  );
 
  const guestLinks = (
    
    <ul className="topnavSYSEN">

        <div className="logoContentSYSEN">
        <Link to={`/shops/${shopID}`}>
        {/* <img className="logo" src={ff}/> */}
        {shop.map(shop => ( <img className="logo"  src={shop.shop_logo_img} />    ))} 
        </Link>
        </div>

 
    
         
 
<button className="buttonNavEN" onClick={openMenu}  >  &#9776;   </button>
 

<div id="mySidenav" className={mySidenav}>
 <center> 
 <button onClick={closeMenu} className="closebtn">&times;</button>
 {/* {categories11.map(catego=>(

<Fragment> 

<a href={`/displayCategoryItems/${catego._id}#/${catego.c_name}`} > 
<span><img className="" src={catego.image} style={{width:'50px' , height:'50px', marginRight:'20px'}}/></span>
{catego.c_name} </a>

</Fragment>

 ))} */}

 {/* <Link to='/user/createAccount'> <i class="fa fa-id-card fa-1x" ></i> {' '} Create Account </Link> */}

 <Link to='/user/login'> <i class="fa fa-sign-in fa-1x" aria-hidden="true"></i> {' '} Login </Link>
 {/* <Link to='/membership/prices'><i className="fa fa-star fa-1x"></i> {' '}   Premium membership </Link>
 <Link to='/shops'> <i class="fa fa-user-circle fa-1x" aria-hidden="true"></i>{' '} Members  </Link> */}

  {i18next.language === 'ar' && <Link  onClick={()=>{i18next.changeLanguage('en')}}> 
          <span className='hide-sm'>{t('lang')} </span>
          {' '}<i className="fa fa-globe fa-1x"></i> 
        </Link>}

        {i18next.language === 'en' && <Link  onClick={()=>{i18next.changeLanguage('ar')}}> 
          <span className='hide-sm'>{t('lang')} </span>
          {' '}<i className="fa fa-globe fa-1x"></i> 
        </Link>} 

 </center>
</div>




 
      
 
    {/* <li className="">
        <Link to='/user/createAccount'>
        <i class="fa fa-id-card fa-1x" ></i> {' '}
          <span className='hide-sm'>  Create Account </span>
          </Link>
      </li> */}


      <li className="">
        <Link to='/user/login'> 
        <i class="fa fa-sign-in fa-1x" aria-hidden="true"></i> {' '}
        
        <span className='hide-sm'>Login </span>
        </Link>
      </li>
 
  
      {/* <li className="">
        <Link to='/membership/prices'>

        <i className="fa fa-star fa-1x"></i> {' '}
          <span className='hide-sm'>Premium membership </span>
        </Link>


 
        <Link to='/shops'>
         <i class="fa fa-user-circle fa-1x" aria-hidden="true"></i>
         {' '} Members
        
        </Link>
  
      </li> */}

   

    <li className="">
     {i18next.language === 'ar' && <Link  onClick={()=>{i18next.changeLanguage('en')}}> 
          <span className='hide-sm'>{t('lang')} </span>
          {' '}<i className="fa fa-globe fa-1x"></i> 
        </Link>}

        {i18next.language === 'en' && <Link  onClick={()=>{i18next.changeLanguage('ar')}}> 
          <span className='hide-sm'>{t('lang')} </span>
          {' '}<i className="fa fa-globe fa-1x"></i> 
        </Link>}

      </li> 
 

 
    </ul>





  );
 
  return (
    <Fragment>
    <center> 
      {!loading && (
        <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
      )}
      </center>
    </Fragment>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logout }
)(Navbar);
