import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Footer from '../layout/Footer';
import Navbar from '../layout/Navbar';
import NavbarEnglish from '../layout/NavbarEnglish';
import { useTranslation } from 'react-i18next';


import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


import homepicAR from "./homepicAR.jpg"; 
import homepicAR1 from "./homepicAR1.png"; 
import homepicAR2 from "./homepicAR2.png"; 
import homepicAR3 from "./homepicAR33.png"; 
import homepicAR4 from "./homepicAR4.png"; 
import homepicAR5 from "./homepicAR5.jpg"; 
import homepicAR6 from "./homepicAR6.jpg"; 
import homepicAR7 from "./homepicAR7.jpg"; 
import homepicAR8 from "./homepicAR8.jpg"; 
import homepicAR9 from "./homepicAR9.png"; 


const HomePageWelcomeEnglish = () => {
    const [t, i18next] = useTranslation()




    const settings = {
      className: "",
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 1000,
      pauseOnHover: true,
      rtl: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };


  return (

    <Fragment>
    
    <div className="aqle3-main">
    <div className="mainword2">   
     
     {i18next.language === 'ar'&&(<Navbar /> )}
     {i18next.language === 'en'&&(<NavbarEnglish /> )}
 
   <center>  
     <div className="homePageWrapper1" >  
      <h1>   Quick and easy start   </h1>
      <p>
      Create your online store in minutes, and link your products to an integrated set of smart digital solutions for payments and marketing with ease Because your success does not need to be complicated
      </p>

    <Link to='/user/createAccount'> <button> Create online store  </button>  </Link>

    <img src={homepicAR} />


<center> 
<h1>  The easiest e-commerce platform    </h1>
<p>
Create your online store and join tens of thousands of successful global brands with Faizads today
 </p>
</center>


<center><div className='statistics'>
  <div className='right'> 
  <div > 
  36,000   <i class="fa fa-plus" aria-hidden="true"></i>  </div>
   <div>
    stores
   </div>
   </div>
 

  <div className='left'>  
  <div> 
  14,000$    <i class="fa fa-plus" aria-hidden="true"></i>  </div>
   <div>
   sales
   </div>
   </div>


</div></center>

</div>
 </center>







 <center> 
 
<div className="homePageWrapper1" >  
<h1>  Easy and simple steps       </h1>
<p>
Through a control panel, you will start working immediately, and building your project will be very easy
  </p>
 </div>
</center>



 <center> 
 <div className="homePageWrapper2-english" >  
 
     <div className='right'> <img src={homepicAR1}/> </div>

    <div className='left'>  <h1>  step 1  </h1>
    <p>
    Set up your profile
    </p>
    <ul>
      <li>Complete all of your personal information</li>
      
      
    </ul>
    </div>
 </div>
 </center> 







 <center> 
 <div className="homePageWrapper2-english" >  

 <div className='right'> <img src={homepicAR2} /> </div>

    <div className='left'>  <h1>  step 2  </h1>
    <p>
    Add your online store information
    </p>
    <ul>
      <li>  
      In this step, you will add everything related to your online store, including the logo, identity, description of the store, and contact information
         </li>
      <li> 
        
      This step is important for your store to be complete and for customers to contact you through your online store
          </li>
      
   
   
    </ul>
    </div>
    
 </div>
 </center> 






 




 <center> 
 <div className="homePageWrapper2-english" >  

 <div className='right'> <img src={homepicAR3} /> </div>


    <div className='left'>  <h1>  step 3  </h1>
    <p>
    Add store categories 
      </p>

    <ul>
      <li>  Through the control panel, you can add the main categories of your online store </li>
      <li>  Classify products according to their types </li>
      <li> New categories can be added, deleted or modified </li>
      <li> You can add an unlimited number of categories for your products  </li>
   
    </ul>
    </div>
 </div>
 </center> 





 <center> 
 <div className="homePageWrapper2-english" >  

 <div className='right'> <img src={homepicAR4} /> </div>

    <div className='left'>  <h1>   step 4   </h1>
    <p>
    Add the products
     </p>
    <ul>
      <li>  Manage products through the control panel with ease   </li>
      <li> Add products in product categories  </li>
      <li> You can add the products and add the details of the colors and sizes </li>
      <li> You can add images that show the size of the product or images that show the color of the product </li>
      <li> You can add a number of images to your product to display the products clearly and to cover all the important aspects and features for each product </li>
     </ul>
    </div>
 </div></center>






 

 <center> 
 <div className="homePageWrapper2-english" >  

 <div className='right'> <img src={homepicAR5} /> </div>

    <div className='left'>  <h1>   step 5    </h1>
    <p>
    Activate payment methods

      </p>
    <ul>
       <li>Make your payments with the click of a button </li>
      <li>  With faizads platform, your customers can pay in multiple ways    </li>
      <li> Your store will accept payment methods from all over the world </li>
     
    </ul>
    </div>




 </div></center>



















 <center> 
 <div className="homePageWrapper2-english" >  

 <div className='right'> <img src={homepicAR6}/> </div>

    <div className='left'>  <h1>   step 6     </h1>
    <p>
    Buy templates
      </p>
    <ul>
      
      <li> We have a number of different templates that are designed to impress your customers and suit all types of stores   </li>
      <li> Your sales growth starts with an easy and unique shopping experience </li>
      <li> After entering the control panel, you can enter the types of templates and choose what suits your store from the custom templates </li>
    </ul>
    </div>



 </div></center>






 <center> 
 <div className="homePageWrapper2-english" >   


    <div className='right'> <img src={homepicAR7} /> </div>

    <div className='left'>  <h1>  step 7  </h1>
    <p>
    Add your domain
      </p>
      <ul>
      <li> You can get a private domain </li>
      <li>If you have a domain from another service provider, you can link it to faizads, and domain management and subscription renewal will remain through the service provider</li>
      <li>If you have a domain from another service provider, you can transfer it to faizads, and thus you will be able to manage the domain and renew your subscription through faizads  </li>
    </ul>
    </div>



 </div></center>







 <center> 
 <div className="homePageWrapper2-english" >  

     <div className='right'> <img src={homepicAR8} /> </div>
 
    <div className='left'>  <h1>  step 8  </h1>
    <p>
    Store launch
       </p>
       <ul>

       <li>  
       Be available to display your products in front of customers and consumers 24 hours a day, 7 days a week.
      </li>


      <li>  
      Be present anywhere in the world without the need to open a head office for your company or stores
        </li>


        <li>  
        Sell your products in many countries around the world and spread your own brand
        </li>

 

     </ul>
    </div>



 </div></center>


  













     <center>  
     <div className="homePageWrapper3" >  
     <div className='right'>
      <h1>   Own a professional store on the faizads platform    </h1>
      
      <p>
      Create online store  with flexible tools and integrated solutions that help you in every step towards growing your sales and marketing your products
      </p>
      </div>

      <div className='left'>
      <Link to='/user/createAccount'> <button> Create online store  </button>  </Link>
     </div>

     </div>
     </center>









 
{/* ////////////////////////////////////////Slider Start////////////////////////////////////////////////////// */}
<center>  
<div className='homePageWrapper4'>
  {/* <title> شركاء النجاح </title> */}
  
  <div className='right'  >  <h1>  Success Partners  </h1>
    
    </div>

    <div className='left' style={{borderTopLeftRadius:'10px',borderBottomLeftRadius:'10px'}} > 
  <Slider {...settings}>
 
 
 
      <Link > 
       <img  src='https://res.cloudinary.com/momad191/image/upload/v1689240910/first_magazine/fpa3g0etmglyxyqkht2o.jpg' 
     
      /> 
         </Link>
    


   
      <Link>  
      <img src='https://res.cloudinary.com/momad191/image/upload/v1662450105/first_magazine/vivqlkskod6pzdszeyvy.png' 
    
      />
       </Link>
 



  
       <Link>  
       <img src='https://res.cloudinary.com/momad191/image/upload/v1689331948/first_magazine/swjlioxjprnkbnm8w5rv.jpg' 
       
       /> 
       </Link>
    



  
       <Link>  
       <img src='https://res.cloudinary.com/momad191/image/upload/v1662378223/first_magazine/vjdocsw1fx0hpnxzvn6a.png' 
      
       /> 
       </Link>
 



     
       <Link>  
       <img src='https://res.cloudinary.com/momad191/image/upload/v1662454876/first_magazine/bcaqlmiuaiixfj3ijhze.jpg' 
        
       /> 
       </Link>
  



 
       <Link>  
       <img src='https://cdn.salla.network/salla.com/qIgRp07duwxIDzHdUHykBgjNfHb3xetejzqwsEKi-1.jpeg'  
     
       /> 
       </Link>
   



       <Link>  
       <img src='https://res.cloudinary.com/momad191/image/upload/v1663929953/first_magazine/najd9pclruyoqxzmzlfi.jpg'  
      
       /> 
       </Link>
  


       <Link>  
       <img src='https://res.cloudinary.com/momad191/image/upload/v1662452570/first_magazine/rrg55seack8jf2su1dyd.png'  
      
       /> 
       </Link>




    </Slider>
    </div>

</div>
</center>  
{/* ////////////////////////////////////////Slider end////////////////////////////////////////////////////// */}






<Footer />

     </div>
     </div>

     </Fragment>
  )
}

export default HomePageWelcomeEnglish