import React, { Fragment, useEffect ,useState } from 'react';
import { BrowserRouter as Router, Route, Switch,useParams  } from 'react-router-dom';

import Navbar from './components/layout/Navbar';
import NavbarEnglish from './components/layout/NavbarEnglish';
import Footer from './components/layout/Footer'; 
//import Home from './components/layout/Landing'; 
  
//import Home from './components/layout/home';

//import Home from './components/layout/FirstHome'; 

import Home from './components/pages/HomePageWelcome';
import HomeEnglish from './components/pages/HomePageWelcomeEnglish';


import Routes from './components/routing/Routes';
import { useTranslation } from 'react-i18next';
  
// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';


import cartReducer, { getTotals } from "./components/slices/cartSlice";

// import {Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';


import "./assets/css/plugin.css";
import './App.css'

// const stripePromise = loadStripe('sk_test_51Mc9WXBGHr76M3NCpb4hJI20KEfaLoN7pnIYsrJEfcC3uWh8gz2b8Uyg2RmJgkPB4MGxsBQ7feEpq0B7qQSV4dGm00gkiDXIwW');

if (localStorage.token) {
  setAuthToken(localStorage.token);
}


 

const App = () => {

  const [t, i18next] = useTranslation()

  useEffect(() => {
    store.dispatch(loadUser());

  },[]);
 

  
 

  return (
    <Provider store={store}>
      <Router>
        <Fragment>

       
     
{/* {i18next.language === 'ar'&&(<Navbar />)}
    {i18next.language === 'en'&&(<NavbarEnglish />)} */}
         
         
           
          <Switch>
            {/* <Route exact path='/:lang' component={Home} /> */}

            
          
            <Route exact path='/' component={i18next.language === 'ar'? Home : HomeEnglish} />

            <Route component={Routes} />
          
          
             
          </Switch>
          {/* <Footer /> */}
        </Fragment>
      </Router>
    </Provider>
  );
};

export default App;
