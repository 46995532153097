import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import smalllogo from './system-icon-2.png';
  
const Footer = ({shop}) => {
  // const [t, i18next] = useTranslation()
  return (
    <Fragment>
<footer className="myFooter" >
 
<center>
<div className='vertical-menu-wrapper' >




<ul className='vertical-menu-about'>
<li> 
    <a>
   {/* <img src={smalllogo} width={65} height={35} />  */}
 
   {shop.map(shop => (<img src={shop.shop_logo_img} width={200}  style={{borderRadius:'15px'}} /> ))} 
</a> </li>
<li>   {shop.map(shop => (<a> {shop.shop_name}</a>  ))}  </li>
{/* <li>  <a>  الرياض المملكة العربية السعودية </a> </li> */}
{/* <li>  <a> USA Casper, WY 82601</a> </li> */}
{/* <li>   <a> <i class="fa fa-phone-square fa-1x" aria-hidden="true"></i> 0558692541 </a> </li> */}
</ul>  
  




<ul className='vertical-menu'>
<li>   <div className='vertical-menu-active'> </div> </li>
<li><Link to='/company/about#' > عن المتجر   </Link></li>
<li><Link to='/company/terms#' > الشروط والأحكام  </Link></li>
<li><Link to='/company/contact#' > تواصل معنا  </Link></li>
<li><Link to='/company/customer-reviews#' > اراء العملاء  </Link></li>
</ul>




<ul className='vertical-menu'>
<li>   <div className='vertical-menu-active'> </div> </li>
<li><Link to='/company/refund-policy#' > سياسة الاسترجاع  </Link></li>
<li><Link to='/company/customer-support#' >  خدمة العملاء  </Link></li>
<li><Link to='/company/privacy#' > سياسة الخصوصية   </Link></li>
<li><Link to='/company/cancelation-and-refund-policy#' > الإرجاع والاستبدال  </Link></li>
<li><Link to='/company/shipping-and-delivery-policy#' > الشحن والتوصيل  </Link></li>
</ul>






{/* {i18next.language === 'en'&&(
<ul className='vertical-menu'>
<li>   <div className='vertical-menu-active'> </div> </li>
<li>  <a href="/company/about">About us </a></li>
<li>  <a href="/company/contact">Contact</a> </li>
<li>  <a href="/company/privacy">Privacy</a> </li>
<li>  <a href="/company/terms">Terms and Conditions</a></li>
</ul>
)} */}




<ul className='vertical-menu'> 
<li>  <div className='vertical-menu-active'>  </div> </li>
<li> <Link to="/register#">انشاء حساب </Link> </li>
<li> <Link to="/login#">دخول الأعضاء</Link> </li>
{/* <li> <a href="/membership/prices">باقات العضوية</a> </li>
<li> <a href="/user/registrationMethod">طريقة التسجيل</a> </li> */}
</ul>



{/* {i18next.language === 'en'&&(
<ul className='vertical-menu'> 
<li>  <div className='vertical-menu-active'>  </div> </li>
<li> <a href="/user/createAccount">Create account </a> </li>
<li> <a href="/user/login">Login</a> </li>
<li> <a href="/membership/prices">Membership</a> </li>
<li> <a href="/user/registrationMethod">How it works </a> </li>
</ul>
)} */}





{/* {i18next.language === 'en'&&(
<ul className='vertical-menu'> 
<li>  <div className='vertical-menu-active'>  </div> </li>
<li> <a href="/company/affiliate">Affiliates </a> </li>
<li> <a href="/#">Blog</a> </li>
<li> <a href="/#">Events</a> </li>
<li> <a href="/#">News</a> </li>
</ul>
)} */}


<ul className='vertical-menu'> 
<li>  <div className='vertical-menu-active'>  </div> </li>
{/* <li> <a href="/company/affiliate">انضم إلى برنامج الشركاء </a> </li> */}
<li> <Link to="/#">المدونة</Link> </li>
<li> <Link to="/#">مناسبات </Link> </li>
<li> <Link to="/#">أخبار</Link> </li>
</ul>














</div>
</center>
       
<center> 
<div className="paymentMethodeSectionFooter">  
{/* <i class="fa fa-cc-amex fa-3x" aria-hidden="true"></i>
<i class="fa fa-cc-paypal fa-3x" aria-hidden="true"></i> */}
<i class="fa fa-cc-stripe fa-3x" aria-hidden="true"></i>
<i class="fa fa-cc-mastercard fa-3x" aria-hidden="true"></i>
<i class="fa fa-cc-visa fa-3x" aria-hidden="true"></i>
</div>
</center>



<footer className="myFooter2">
<div className="copyrighttext">  
          © 2023 ladyshop14.com online shop - All Rights Reserved.
</div>

              </footer> 
</footer>
   
    </Fragment>
  );
};






export default Footer;
