import React from 'react'

const success = () => {
    return (
        <div>
         <h1>success </h1>   
        </div>
    )
}

export default success
