import React, { Fragment, useEffect ,useState } from 'react';
import { Link, Redirect } from 'react-router-dom';  
import Navbar from '../../components/layout/Navbar';
import NavbarEnglish from '../../components/layout/NavbarEnglish';
import Footer from '../../components/layout/Footer';
import { useTranslation } from 'react-i18next';
 
const Prices=({match}) =>{



  const [GoldenMonthly,setGoldenMonthly]= useState(31)
  const [GoldenSixMonth,setGoldenSixMonth]= useState(186)
  const [GoldenAnnual,setGoldenAnnual]= useState(372)



  const [SilverMonthly,setSilverMonthly]= useState(21)
  const [SilverSixMonth,setSilverSixMonth]= useState(126)
  const [SilverAnnual,setSilverAnnual]= useState(252)



  const [BronzeMonthly,setBronzeMonthly]= useState(11)
  const [BronzeSixMonth,setBronzeSixMonth]= useState(66)
  const [BronzeAnnual,setBronzeAnnual]= useState(132)
 
  const [mode,setMode]= useState('monthly')

  const MonthlyOptions =() =>{
    setMode('monthly')
  }
  const SixMonthsOptions =() =>{
    setMode('sixMonth')
  }
  const AnnualOptions =() =>{
    setMode('annual')
  }

 
  const Lang = match.params.lang;
  const [t, i18next] = useTranslation()

return (
<Fragment> 
<div className="aqle3-main"  >
<div className="mainword2">
  
 { i18next.language === 'ar' &&(<Navbar />)}
 { i18next.language === 'en' &&(<NavbarEnglish />)}



  { i18next.language === 'ar'&&(

<Fragment> 
<center>
 <div className="main-pricing" >
 {/* <div className="pricing-title">  اختر الباقة الإعلانية المناسبة لمتجرك  </div>
 <div className="pricing-title"> خطط مثالية لزيادة مبيعات متجرك وتوسيع نطاق أهدافك  </div>
 <div className="loginSmalltitle">  شراء الباقة  لمرة واحدة فقط ولمدة محددة بدون التزام شهري ‏  </div> */}

 
 {/* <div className="paymentMethodeSection">  
<center> 
 <i class="fa fa-cc-amex fa-4x" aria-hidden="true"></i> 
  <i class="fa fa-cc-paypal fa-4x" aria-hidden="true"></i> 
 <i class="fa fa-cc-stripe fa-4x" aria-hidden="true"></i>
 <i class="fa fa-cc-mastercard fa-4x" aria-hidden="true"></i>
 <i class="fa fa-cc-visa fa-4x" aria-hidden="true"></i>
 </center>
 </div> */}




 <center> 
<div className="paymentMethodeSection">  

 
{mode === 'monthly'&& (
<Fragment>
<center> 
<button className='button-pricing-period-right-active' onClick={MonthlyOptions}>  شهري </button>
<button className='button-pricing-period-center' onClick={SixMonthsOptions}>  ستة أشهر </button>
<button className='button-pricing-period-left' onClick={AnnualOptions}>  سنوي </button>
</center>
</Fragment>
)}



{mode === 'annual'&& (
<Fragment>
<center> 
<button className='button-pricing-period-right' onClick={MonthlyOptions}>  شهري </button>
<button className='button-pricing-period-center' onClick={SixMonthsOptions}>   ستة أشهر </button>
<button className='button-pricing-period-left-active' onClick={AnnualOptions}>  سنوي </button>
</center>
</Fragment>
)}


 

{mode === 'sixMonth'&& (
<Fragment>
<center> 
<button  className='button-pricing-period-right' onClick={MonthlyOptions}>  شهري </button>
<button  className='button-pricing-period-center-active' onClick={SixMonthsOptions}>  سته أشهر </button>
<button  className='button-pricing-period-left' onClick={AnnualOptions}>  سنوي </button>
</center>
</Fragment>
)}

</div>
</center>

 
 
          

 {/* <div className=""> 
 <ul className="price">
    <li className="header">عضوية البداية</li>
    <li className="grey">
    {mode === 'monthly' && <> $6  </>}
    {mode === 'sixMonth' && <> $36 </>}
    {mode === 'annual' && <> $72  </>}  
    </li>

    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  متجر إلكتروني إحترافي </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  الدروبشيبينق</li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  طلبات غير محدودة </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   استيراد 10 منتجات      </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   الدعم الفني   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   دعم الدردشة المباشرة   </li>

<li className="grey">
{mode === 'monthly' && <> <li className="grey"><Link to="/membership/prices/start" className="button-pricing">اشترك</Link></li></>}
{mode === 'sixMonth' && <> <li className="grey"><Link to="/membership/prices/start/sixMonth" className="button-pricing">اشترك</Link></li></>}
{mode === 'annual' && <> <li className="grey"><Link to="/membership/prices/start/annual" className="button-pricing">اشترك</Link></li></>}
</li>
</ul>
</div> */}

 



{/* <div className="">
  <ul className="price">
    <li className="bronze">البرونزية</li>
    <li className="grey">   
    {mode === 'monthly' && <> $11  </>}
    {mode === 'sixMonth' && <> $60 </>}
    {mode === 'annual' && <> $130  </>}
    </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  متجر إلكتروني إحترافي </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  الدروبشيبينق</li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  طلبات غير محدودة </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   استيراد 50 منتج      </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   الخيارات المتقدمة   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   الدعم الفني   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   دعم الدردشة المباشرة   </li>
  {mode === 'monthly' && <> <li className="grey"><Link to="/membership/prices/bronze" className="button-pricing">اشترك</Link></li></>}
 {mode === 'sixMonth' && <> <li className="grey"><Link to="/membership/prices/bronze/sixMonth" className="button-pricing">اشترك</Link></li></>}
 {mode === 'annual' && <> <li className="grey"><Link to="/membership/prices/bronze/annual" className="button-pricing">اشترك</Link></li></>}
  </ul>
</div> */}



 
{/* <div className="">
  <ul className="price">
    <li className="silver">الفضية</li>
    <li className="grey"> 
    {mode === 'monthly' && <> $21  </>}
    {mode === 'sixMonth' && <> $120   </>}
    {mode === 'annual' && <> $250  </>}
    </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  متجر إلكتروني إحترافي </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  الدروبشيبينق</li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  طلبات غير محدودة </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   استيراد 100 منتج      </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   الخيارات المتقدمة   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   يشمل طباعة الشعاروالتغليف المجاني   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   الدعم الفني   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   دعم الدردشة المباشرة   </li>
     {mode === 'monthly' && <> <li className="grey"><Link to="/membership/prices/silver" className="button-pricing">اشترك</Link></li></>}
    {mode === 'sixMonth' && <> <li className="grey"><Link to="/membership/prices/silver/sixMonth" className="button-pricing">اشترك</Link></li></>}
    {mode === 'annual' && <> <li className="grey"><Link to="/membership/prices/silver/annual" className="button-pricing">اشترك</Link></li></>}
  </ul>
</div> */}



 

   <div className="">
    <ul className="price">
    <li className="gold">الذهبية</li>
    <li className="grey"> 
    {mode === 'monthly' && <> $31  </>}
    {mode === 'sixMonth' && <>$180  </>}
    {mode === 'annual' && <> $300  </>}  
    </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   اعداد سريع وسهل لمتجرك في دقائق   </li> 
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   اضافة جميع معلومات التواصل الخاصة بالمتجر   </li> 
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   اضافة الهوية  - الإستايل الخاص بمتجرك    </li> 
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   اضافة تصنيفات غير محدود    </li> 
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   اضافة منتجات غير محدود   </li> 
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   تفعيل وسائل الدفع    </li> 
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   قوالب مميزة تناسب مختلف أنواع المتاجر    </li> 
  <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   دعم مباشر 24/12   </li> 


    {/* <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  متجر إلكتروني إحترافي </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  الدروبشيبينق</li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  طلبات غير محدودة </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   استيراد 300 منتج      </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   الخيارات المتقدمة   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   يشمل طباعة الشعاروالتغليف المجاني   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   الدعم الفني   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   دعم الدردشة المباشرة   </li> */}
   
    {mode === 'monthly' && <> <li className="grey"><Link to="/membership/prices/golden" className="button-pricing">اشترك</Link></li></>}
    {mode === 'sixMonth' && <> <li className="grey"><Link to="/membership/prices/golden/sixMonth" className="button-pricing">اشترك</Link></li></>}
    {mode === 'annual' && <> <li className="grey"><Link to="/membership/prices/golden/annual" className="button-pricing">اشترك</Link></li></>}
  </ul>
</div>
 

 

{/* <div className="">
  <ul className="price">
    <li className="header"> <i class="fa fa-rocket" aria-hidden="true"></i> الخاصة</li>
    <li className="grey">تواصل معنا</li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span> جميع المميزات الذهبية</li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   متجر الكتروني متطور   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   مثالية للوكالات والمحترفين   </li>
    <li className="grey"><Link to="/ar/membership/prices/special" className="button-pricing">تواصل معنا <i class="fa fa-envelope" aria-hidden="true"></i></Link></li>
  </ul>
</div> */}


</div>


  


</center>
</Fragment>
  )} 



 

{i18next.language === 'en'&&(
////////////////////////////////////ENGLISH//////////////////////////////////////
<Fragment> 
<center> 
 <div className="main-pricing" >
 {/* <div className="pricing-title"> Choose the appropriate advertising package   </div>
 <div className="pricing-title"> Perfect plans to increase sales  of your products and services globally  </div>
 <div className="pricing-title">  The Wide Spread Of Your Hop Link </div> */}
 {/* <div className="loginSmalltitle">One-time purchase of the package for a specified period without a monthly commitment </div> */}



 {/* <div className="paymentMethodeSection">  
<center> 
 <i class="fa fa-cc-amex fa-4x" aria-hidden="true"></i> 
 <i class="fa fa-cc-paypal fa-4x" aria-hidden="true"></i>
 <i class="fa fa-cc-stripe fa-4x" aria-hidden="true"></i>
 <i class="fa fa-cc-mastercard fa-4x" aria-hidden="true"></i>
 <i class="fa fa-cc-visa fa-4x" aria-hidden="true"></i>
 </center>
 </div> */}
  
<center> 
<div className="paymentMethodeSection">  

 
{mode === 'monthly'&& (
<Fragment>
<center> 
<button className='button-pricing-period-right-active' onClick={MonthlyOptions}>  Monthly </button>
<button className='button-pricing-period-center' onClick={SixMonthsOptions}>  six Months </button>
<button className='button-pricing-period-left' onClick={AnnualOptions}>  Annual </button>
</center>
</Fragment>
)}



{mode === 'annual'&& (
<Fragment>
<center> 
<button className='button-pricing-period-right' onClick={MonthlyOptions}>  Monthly </button>
<button className='button-pricing-period-center' onClick={SixMonthsOptions}>  six Months </button>
<button className='button-pricing-period-left-active' onClick={AnnualOptions}>  Annual </button>
</center>
</Fragment>
)}


 

{mode === 'sixMonth'&& (
<Fragment>
<center> 
<button  className='button-pricing-period-right' onClick={MonthlyOptions}>  Monthly </button>
<button  className='button-pricing-period-center-active' onClick={SixMonthsOptions}>  six Months </button>
<button  className='button-pricing-period-left' onClick={AnnualOptions}>  Annual </button>
</center>
</Fragment>
)}

</div>
</center>


 

<center> 
<div className='pricesPlanWrapper'> 


 
 
 {/* <div className="">
  <ul className="price">
    <li className="header">Start membership</li>
    <li className="grey">
    {mode === 'monthly' && <> $6  </>}
    {mode === 'sixMonth' && <> $36 </>}
    {mode === 'annual' && <> $72  </>}  
    </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  Professional online store </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  dropshipping</li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  Unlimited requests </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Import 300 products      </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Advanced Options   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Includes logo printing and free packaging   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Technical support   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Live chat support   </li>
<li className="grey">
{mode === 'monthly' && <> <li className="grey"><Link to="/membership/prices/start" className="button-pricing">Subscribe</Link></li></>}
{mode === 'sixMonth' && <> <li className="grey"><Link to="/membership/prices/start/sixMonth" className="button-pricing">Subscribe</Link></li></>}
{mode === 'annual' && <> <li className="grey"><Link to="/membership/prices/start/annual" className="button-pricing">Subscribe</Link></li></>}
     </li>
  </ul>
</div> */}
 



   

{/* <div className="">
  <ul className="price">
    <li className="bronze">Bronze Membership</li>
    <li className="grey"> 
    {mode === 'monthly' && <> $11  </>}
    {mode === 'sixMonth' && <> $60 </>}
    {mode === 'annual' && <> $130  </>}
    </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  Professional online store </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  dropshipping</li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  Unlimited requests </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Import 300 products      </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Advanced Options   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Includes logo printing and free packaging   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Technical support   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Live chat support   </li>
 {mode === 'monthly' && <> <li className="grey"><Link to="/membership/prices/bronze" className="button-pricing">Subscribe</Link></li></>}
 {mode === 'sixMonth' && <> <li className="grey"><Link to="/membership/prices/bronze/sixMonth" className="button-pricing">Subscribe</Link></li></>}
 {mode === 'annual' && <> <li className="grey"><Link to="/membership/prices/bronze/annual" className="button-pricing">Subscribe</Link></li></>}

  </ul>
</div> */}





 
{/* <div className="">
  <ul className="price">
    <li className="silver">Silver Membership</li>
    <li className="grey">
    {mode === 'monthly' && <> $21  </>}
    {mode === 'sixMonth' && <> $120   </>}
    {mode === 'annual' && <> $250  </>}
    </li>
     <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  Professional online store </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  dropshipping</li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  Unlimited requests </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Import 300 products      </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Advanced Options   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Includes logo printing and free packaging   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Technical support   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Live chat support   </li>
    {mode === 'monthly' && <> <li className="grey"><Link to="/membership/prices/silver" className="button-pricing">Subscribe</Link></li></>}
    {mode === 'sixMonth' && <> <li className="grey"><Link to="/membership/prices/silver/sixMonth" className="button-pricing">Subscribe</Link></li></>}
    {mode === 'annual' && <> <li className="grey"><Link to="/membership/prices/silver/annual" className="button-pricing">Subscribe</Link></li></>}
  </ul>
</div> */}

 
 

    <div className="">
    <ul className="price">
    <li className="gold">Golden Membership</li>
    <li className="grey"> 
    {mode === 'monthly' && <> $31  </>}
    {mode === 'sixMonth' && <>$180  </>}
    {mode === 'annual' && <> $300  </>} 
    </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  Quick and easy setup of your store in minutes </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  Add all contact information for the store</li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>  Add the identity - the style of your store </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Add unlimited categories      </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Add unlimited products   </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Activate payment methods   </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>    templates suitable for different stores   </li>
    <li><span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Live support 24/12   </li>

    {mode === 'monthly' && <> <li className="grey"><Link to="/membership/prices/golden" className="button-pricing">Subscribe</Link></li></>}
    {mode === 'sixMonth' && <> <li className="grey"><Link to="/membership/prices/golden/sixMonth" className="button-pricing">Subscribe</Link></li></>}
    {mode === 'annual' && <> <li className="grey"><Link to="/membership/prices/golden/annual" className="button-pricing">Subscribe</Link></li></>}
  </ul>
</div>
 
</div>
</center>


{/* <div className="">
  <ul className="price">
    <li className="header"> <i class="fa fa-rocket" aria-hidden="true"></i> Special</li>
 
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span> All golden features</li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   advanced public Ads page   </li>
    <li>  <span className="features"> <i class="fa fa-check-square fa-2x" aria-hidden="true"></i> </span>   Agencies and professionals   </li>
    <li className="grey"><Link to="/en/membership/prices/special" className="button-pricing">Connect with us <i class="fa fa-envelope" aria-hidden="true"></i></Link></li>
  </ul>
</div> */}








 

</div>









</center>
</Fragment>


    
    )}




 


 

<Footer />
</div>
</div>
</Fragment>
    )};
 
export default Prices
