import axios from 'axios';
import { setAlert } from './alert'; 
import {
 
  addToCart, decreaseCart, removeFromCart, getTotals, clearCart
 
} from './types';

 
  

// Add like
export const addToCartt = product => async dispatch => {
  
    dispatch({
      type: addToCart,
      payload:  product 
    });
    
    alert(product)
 
};
 
