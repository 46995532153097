import React, { Fragment } from 'react';
import Navbar from './Navbar';
import NavbarEnglish from './NavbarEnglish';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const [t, i18next] = useTranslation()
  return (
    <Fragment>
      { i18next.language === 'ar' &&(<Navbar />)}
      { i18next.language === 'en' &&(<NavbarEnglish />)}
      
      <br/><br/><br/><br/><br/><br/><br/>
      {i18next.language === 'en'&&(
      <Fragment>
      <center> 
      <h1 className='dash-title'>
      <i className='fa fa-exclamation-triangle' /> Sorry, this page does not exist
      </h1>
      </center>
      </Fragment>
      )}




{i18next.language === 'ar'&&(
      <Fragment>
      <center> 
      <h1 className='dash-title'>
      عذرا، هذه الصفحة غير موجودة
       {' '}<i className='fa fa-exclamation-triangle' />
      </h1>
     
      </center>
      </Fragment>
      )}



      <br/><br/><br/><br/><br/><br/><br/>
    </Fragment>
  );
};
 
export default NotFound;
