import React, { Fragment, useEffect ,useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'; 
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; 
import Spinner from '../layout/Spinner'; 
import DashboardActionsArabic from './DashboardActionsArabic'; 
import Experience from './Experience';
import Education from './Education';
import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import RequestForm from '../requests/RequestForm';      
import moment from 'moment';
import Moment from 'react-moment';  
// import 'moment/locale/ar';
import { useTranslation } from 'react-i18next';
import Navbar from '../layout/Navbar';
import NavbarEnglish from '../layout/NavbarEnglish';

function MarketingResults() {

  const [t, i18next] = useTranslation()
  moment.locale('ar');
  
  // const [oneShop,setOneShop]= useState([])
  const [userShop,setuserShop]= useState([])

  const [subscription,setSubscription]= useState([])
  const [user,setUser]= useState([])
  const [userPosts,setUserPosts]= useState([])
  // const [userPostsInToday,setUserPostsInToday]= useState([])
  
 
  const [userRef,setUserRef]= useState([])
  const [visible,setVisible]= useState(10)
  const [RefToPay,setRefToPay]= useState([])


  const RefToPayResults = RefToPay.slice(0,visible);


  const loadMore = async e => {
    setVisible(visible+10)

}

  // const [OndemandAmount,setOndemandAmount]= useState([])
  const [visibleAllRequest,setVisibleAllRequest]= useState(10)
  const [allRequest,setAllRequest]= useState([])

  const allRequestResults = allRequest.slice(0,visibleAllRequest);

  const loadMoreRequests = async e => {
    setVisibleAllRequest(visibleAllRequest+10)

}

 
  const [ProfitCompleteRequest,setProfitCompleteRequest]= useState([])
  const [CheckInRequest,setCheckInRequest]= useState([])


  const [lastrequest,setLastRequest]= useState([])

  let totalpaidamounts =0;
  ProfitCompleteRequest.forEach(p=>{
    totalpaidamounts += p.amount;
    })

 
  let totalRequestedToPay =0;
  
  allRequest.forEach(p=>{
    totalRequestedToPay += p.amount;
    
    })

    
  const profit = (RefToPay.length*4) - (totalRequestedToPay);
 
    




  useEffect(() => {

    axios.get('/api/shops/usershop')
    .then(res => {
      console.log(res);
      setuserShop(res.data)
    })
    .catch((err) => {
      console.log(err);
    })
 

    axios.get('/api/subscriptions/lastsubscription')
    .then(res => {
      console.log(res);
      setSubscription(res.data)
    })
    .catch((err) => {
      console.log(err);
    })


    axios.get('/api/auth')
    .then(res => {
      console.log(res);
      setUser(res.data)
    })
    .catch((err) => {
      console.log(err);
    }) 

 
 
    axios.get('/api/posts/userposts')
    .then(res => {
      //console.log(res);
      setUserPosts(res.data)
    })
    .catch((err) => {
      console.log(err);
    })

 
    // axios.get('/api/posts/userPostsInToday')
    // .then(res => {
    //   //console.log(res);
    //   setUserPostsInToday(res.data)
    // })
    // .catch((err) => {
    //   console.log(err);
    // })

 
    axios.get('/api/auth/ref')
    .then(res => {
      //console.log(res);
      setUserRef(res.data)
    })
    .catch((err) => {
      console.log(err);
    })

  

    axios.get('/api/subscriptions/refToPayaffiliate')
    .then(res => {
      //console.log(res);
      setRefToPay(res.data)
    })
    .catch((err) => {
      console.log(err);
    })



    // axios.get('/api/auth/OndemandAmount')
    // .then(res => {
    //   //console.log(res);
    //   setOndemandAmount(res.data)
    // })
    // .catch((err) => {
    //   console.log(err);
    // })

    

    axios.get('/api/profitrequests/allrequests')
    .then(res => {
      //console.log(res);
      setAllRequest(res.data)
    })
    .catch((err) => {
      console.log(err);
    })

 
  
    axios.get('/api/profitrequests/complete')
    .then(res => {
      //console.log(res);
      setProfitCompleteRequest(res.data)
    })
    .catch((err) => {
      console.log(err);
    })



    axios.get('/api/profitrequests/checkin')
    .then(res => {
      //console.log(res);
      setCheckInRequest(res.data)
    })
    .catch((err) => {
      console.log(err);
    })




    axios.get('/api/profitrequests/lastrequest')
    .then(res => {
   
      setLastRequest(res.data)
    })
    .catch((err) => {
      console.log(err);
    })




  }, []);




  return (

  
      <Fragment>


{i18next.language === 'ar'&&( <Fragment>  <Navbar /></Fragment>)}
{i18next.language === 'en'&&(<Fragment><NavbarEnglish /></Fragment> )}

 
{i18next.language === 'ar'&&(
  <Fragment>
   
        <center> 
        <div className="affiliate-nav">
        <div className="affiliateTitle" >التسويق بالعمولة  </div>
        <div className="affiliateTitle2" > 
        {!user.username ?(<>loading ...</> ):(<>
         <a href={`https://faizads.com/user/createAccount/${user.username}`}> 
                 {`https://faizads.com/user/createAccount/${user.username}`}
           </a> </> )}
           
         </div>
  
   <table>
    {/* <tr>
     
     <th> <i class="fa fa-link fa-1x" aria-hidden="true"></i>   {'   '}
         {user.username ?(<>
         <a href={`https://faizads.com/user/createAccount/${user.username}`}> 
                 {`https://faizads.com/user/createAccount/${user.username}`}
           </a> </> ):(<>loading ...</> )}
   
           {'   '}
         <i class="fa fa-link fa-1x" aria-hidden="true"></i> </th>
         <th> الرابط الخاص بك   </th>
    </tr> */}
  
  <tr>
  <th>
     ({RefToPay.length})
    </th>
    <th>
       العضوية المدفوعة عن طريقك 
    </th>
  </tr>
  
   
   
  <tr>
  <th>
    
      (${profit})
     {' '}
     {profit >= 12 &&
     <RequestForm userId={user._id} amout11={profit} />
     } 
  
    </th>
    <th>
      أرباحك الحالية 
    </th>
  </tr>
    
  
  <tr>
  <th>
    ( ${lastrequest.amount} ) 
    
    </th>
    <th>
    ( اخر مبلغ تم طلبه بانتظار التحويل )
    </th>
  </tr>
   
  
  
   
  <tr>
  <th>
     {CheckInRequest.length}
    </th>
    <th>
   ( طلبات في قائمة الانتظار حاليا )
    </th>
  </tr>
  
  
  <tr>
  <th>
     {ProfitCompleteRequest.length}
    </th>
    <th>
      طلبات تم دفعها لك     
    </th>
  </tr>
  
  
  <tr>
  <th>
     (${totalpaidamounts})
    </th>
    <th>
      مجموع الأرباح التي تم تحويلها لحسابك     
    </th>
  </tr>
  
  
  
   
  
  
  
  
  
  
  
    
  </table>
          
     
  
         </div>
        </center>
  
  
  {/* ///////////////////////////////////////////////////'طلبات الأرباح////////////////////////////////////////////////////////////// */}
  
        <center> 
        <div className="affiliate-nav">
   
       <center> <div className="affiliateTitle" >  قائمة طلبات أرباحك  : </div>   </center>
  
     <table id="myTable">
    <tr>
      <th >الحالة</th>
      <th >المبلغ</th>
      <th>تاريخ  الطلب </th>
    
    </tr>
   
    
    {allRequestResults
     .map(post => (
         <Fragment>
    <tr key={post._id}>
    <td className='membersList'>{post.status ==='check-in'?(
     <>جاري التنفيذ</>
    ):(
      <> مكتمل</>
    )}</td>
      <td className='membersList'>${post.amount}</td>
      <td className='membersList'><Moment format='YYYY/MM/DD'>{post.date}</Moment>    </td>
    
    </tr>
        </Fragment>
          ))}
  
  
  </table>
  
  
  
   
     
   
  {visibleAllRequest < allRequest.length && (
      <center> 
    <button   onClick={loadMoreRequests} 
        className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> المزيد  </button> 
         </center>
    )}
  
  
  
          </div>
          </center>
  
  
  
  {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
  
        <center> 
        <div className="affiliate-nav">
   
       <center> <div className="affiliateTitle" >العضوية المدفوعة عن طريقك:  </div>   </center>
  
     <table id="myTable">
    <tr>
      <th >نوع العضوية</th>
      <th >التاريخ</th>
      <th>الاسم</th>
    </tr>
  
    
    {RefToPayResults
     .map(post => (
         <Fragment>
     {post.membership_class === 'bronze'&&(
    <tr className='bronze-text'>
      
    <td className='membersList'>
  {post.membership_class === 'bronze'&&(<h1 className='bronze-text'> برونزية</h1> )}
  {post.membership_class === 'golden'&&(<h1 className='gold-text'> ذهبية</h1> )}
  {post.membership_class === 'silver'&&(<h1 className='silver-text'> فضية</h1> )}
  {post.membership_class === 'special'&&(<> خاصة</> )}
  {post.membership_class === 'free'&&(<> مجانية</> )}
    </td>
      <td className='membersList'><Moment format='YYYY/MM/DD'>{post.membership_renewal_date}</Moment></td>
      <td className='membersList'>{post.first_name}  {post.last_name}   </td>
    </tr>
    )}
  
    
  
  
  
  {post.membership_class === 'golden'&&(
    <tr className='gold-text'>
      
    <td className='membersList'>
  {post.membership_class === 'bronze'&&(<h1 className='bronze-text'> برونزية</h1> )}
  {post.membership_class === 'golden'&&(<h1 className='gold-text'> ذهبية</h1> )}
  {post.membership_class === 'silver'&&(<h1 className='silver-text'> فضية</h1> )}
  {post.membership_class === 'special'&&(<> خاصة</> )}
  {post.membership_class === 'free'&&(<> مجانية</> )}
    </td>
      <td className='membersList'><Moment format='YYYY/MM/DD'>{post.membership_renewal_date}</Moment></td>
      <td className='membersList'>{post.first_name}  {post.last_name}   </td>
    </tr>
    )}
  
  
  
  {post.membership_class === 'silver'&&(
    <tr className='silver-text'>
      
    <td className='membersList'>
  {post.membership_class === 'bronze'&&(<h1 className='bronze-text'> برونزية</h1> )}
  {post.membership_class === 'golden'&&(<h1 className='gold-text'> ذهبية</h1> )}
  {post.membership_class === 'silver'&&(<h1 className='silver-text'> فضية</h1> )}
  {post.membership_class === 'special'&&(<> خاصة</> )}
  {post.membership_class === 'free'&&(<> مجانية</> )}
    </td>
      <td className='membersList'><Moment format='YYYY/MM/DD'>{post.membership_renewal_date}</Moment></td>
      <td className='membersList'>{post.first_name}  {post.last_name}   </td>
    </tr>
    )}
  
        </Fragment>
          ))}
  <tr>
   
   <td>(${profit}) </td> 
   <td>أرباحك الحالية </td>
   <td></td>
   </tr>
  
  </table>
  
  
  
   
     
  
         {/* <div className='comments'>
          {user.profitRequests.map(p => (
            <p key={comment._id} comment={comment} postId={post._id} />
          ))}
        </div>   */}
  
    
    
  
  {visible < RefToPay.length && (
      <center> 
    <button   onClick={loadMore} 
        className="loadMorebtn">  <i class="fa fa-arrow-down fa-0x"></i> عرض المزيد  </button> 
         </center>
    )}
  
  
  
          </div>
          </center>
  
          </Fragment>
  )}
        


{i18next.language === 'en'&&(
  <Fragment>
 
 <center> 
      <div className="affiliate-nav">
      <div className="affiliateTitle" >Affiliate Marketing  </div>
      <div className="affiliateTitle2" > 
      {!user.username ?(<>loading ...</> ):(<>
       <a href={`https://faizads.com/user/createAccount/${user.username}`}> 
               {`https://faizads.com/user/createAccount/${user.username}`}
         </a> </> )}
         
       </div>


<table>
  {/* <tr>
  <th> Your link   </th>
   <th>       <i class="fa fa-link fa-1x" aria-hidden="true"></i>   {'   '}
     {user.username ?(<>
       <a href={`https://faizads.com/user/createAccount/${user.username && user.username}`}> 
      {`https://faizads.com/user/createAccount/${user.username && user.username}`}
         </a> </> ):(<>loading ...</> )}  {'   '}
       <i class="fa fa-link fa-1x" aria-hidden="true"></i> 
       </th>
  </tr> */}

<tr>

<th>
  Paid membership for you 
  </th>
<th>
   ({RefToPay.length})
  </th>
</tr>

 
 
<tr>

<th>
  Your current earnings 
  </th>
<th>
  
    (${profit})
   {' '}
   {profit >= 12 &&
   <RequestForm userId={user._id && user._id} amout11={profit} />
   } 

  </th>
 
</tr>
  

<tr>
<th>
  ( The last amount requested is pending transfer )
  </th>
<th>
  ( ${lastrequest.amount} ) 
  
  </th>

</tr>
 


 
<tr>
<th>
 ( Requests are currently on queue )
  </th>
<th>
   {CheckInRequest.length}
  </th>

</tr>


<tr>
<th>
  Requests that have been paid to you     
  </th>
<th>
   {ProfitCompleteRequest.length}
  </th>
</tr>


<tr>
<th>
  Total earnings transferred to your account     
  </th>
<th>
   (${totalpaidamounts})
  </th>
</tr>



 







  
</table>
        
   

       </div>
      </center>




      <center> 
      <div className="affiliate-nav">
 
     <center> <div className="affiliateTitle" >  List of your earnings requests   </div>   </center>

   <table id="myTable">
  <tr>
    <th>Date </th>
    <th >The amount</th>
    <th >Status</th>
  </tr>
 
  
  {allRequestResults
   .map(post => (
       <Fragment>
  <tr key={post._id}>
  <td className='membersList'><Moment format='YYYY/MM/DD'>{post.date}</Moment>    </td>
  <td className='membersList'>${post.amount}</td>
  <td className='membersList'>{post.status ==='check-in'?(
   <>processing</>
  ):(
    <> complete</>
  )}</td>
  
  </tr>
      </Fragment>
        ))}


</table>



 
   
 
{visibleAllRequest < allRequest.length && (
    <center> 
  <button   onClick={loadMoreRequests} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> More  </button> 
       </center>
  )}



        </div>
        </center>





      <center> 
      <div className="affiliate-nav">
 
     <center> <div className="affiliateTitle" >Paid membership for you  </div>   </center>

   <table id="myTable">
  <tr>
  <th>The name</th>
  <th >Date</th>
  <th >Membership type</th>
  
   
  </tr>

  
  {RefToPayResults
   .map(post => (
       <Fragment>
   {post.membership_class === 'bronze'&&(
  <tr className='bronze-text'>
 <td className='membersList'>{post.first_name}  {post.last_name}   </td>
 <td className='membersList'><Moment format='YYYY/MM/DD'>{post.membership_renewal_date}</Moment></td>
  <td className='membersList'>
{post.membership_class === 'bronze'&&(<h1 className='bronze-text'> Bronze</h1> )}
{post.membership_class === 'golden'&&(<h1 className='gold-text'> Golden</h1> )}
{post.membership_class === 'silver'&&(<h1 className='silver-text'> Silver</h1> )}
{post.membership_class === 'special'&&(<> Special</> )}
{post.membership_class === 'free'&&(<> Free</> )}
  </td>
  </tr>
  )}





{post.membership_class === 'golden'&&(
  <tr className='gold-text'>
 <td className='membersList'>{post.first_name}  {post.last_name}   </td>
 <td className='membersList'><Moment format='YYYY/MM/DD'>{post.membership_renewal_date}</Moment></td>
  <td className='membersList'>
{post.membership_class === 'bronze'&&(<h1 className='bronze-text'> Bronze</h1> )}
{post.membership_class === 'golden'&&(<h1 className='gold-text'> Golden</h1> )}
{post.membership_class === 'silver'&&(<h1 className='silver-text'> Silver</h1> )}
{post.membership_class === 'special'&&(<> Special</> )}
{post.membership_class === 'free'&&(<> Free</> )}
  </td>
  </tr>
  )}



{post.membership_class === 'silver'&&(
  <tr className='silver-text'>
 <td className='membersList'>{post.first_name}  {post.last_name}   </td>
 <td className='membersList'><Moment format='YYYY/MM/DD'>{post.membership_renewal_date}</Moment></td>
  <td className='membersList'>
{post.membership_class === 'bronze'&&(<h1 className='bronze-text'> Bronze</h1> )}
{post.membership_class === 'golden'&&(<h1 className='gold-text'> Golden</h1> )}
{post.membership_class === 'silver'&&(<h1 className='silver-text'> Silver</h1> )}
{post.membership_class === 'special'&&(<> Special</> )}
{post.membership_class === 'free'&&(<> Free</> )}
  </td>
  </tr>
  )}

      </Fragment>
        ))}
<tr>
 
<td></td>
 <td>Your current earnings </td>
 <td>(${profit}) </td> 
 
 </tr>

</table>



 
   

       {/* <div className='comments'>
        {user.profitRequests.map(p => (
          <p key={comment._id} comment={comment} postId={post._id} />
        ))}
      </div>   */}




{visible < RefToPay.length && (
    <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-0x"></i>  More  </button> 
       </center>
  )}

</div>
</center>
  </Fragment>
)}

</Fragment>
  )
}


MarketingResults.propTypes = {
  // getCurrentProfile: PropTypes.func.isRequired,
  // deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  // profile: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
  auth: state.auth,
   profile: state.profile
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, deleteAccount }
)(MarketingResults);
