import React, { useState,useEffect ,Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import axios from 'axios';
import Spinner from '../layout/Spinner';
import Navbar from '../layout/Navbar';
import NavbarEnglish from '../layout/NavbarEnglish';
import Footer from '../../components/layout/Footer';
import { useTranslation } from 'react-i18next';

function ContactConfirmation() {
    const [t, i18next] = useTranslation()
  return (
    <div className="aqle3-main">
    <div className="mainword2">
    {i18next.language === 'ar'&&(<Navbar />)}
    {i18next.language === 'en'&&(<NavbarEnglish />)}
  
    <center> 
    {i18next.language === 'ar' &&(<div className='dash-title'> Your message was sent successfully </div>)}
    {i18next.language === 'en' &&(<div className='dash-title'> Your message was sent successfully </div>)}

    <div className='dash-title'>Thank you </div>
    </center>

    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

  
    <Footer />

     </div>
     </div>
  )
}

export default ContactConfirmation