import React, { Fragment, useEffect ,useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteCategory } from '../../actions/category';
import ConfirmButton from "./ConfirmButton";
import axios from 'axios';
import Spinner from '../layout/Spinner';
import Navbar from '../layout/Navbar';
import NavbarEnglish from '../layout/NavbarEnglish';
import { useTranslation } from 'react-i18next';
  
   
const SystemCategories = ({loading , deleteCategory,match}) => {
  const [t, i18next] = useTranslation()
  const [user,setUser]= useState([])

 
 
 

 

   
  
    const [getProperties,setProperties]= useState([])
 

 
    
    
    const [visible,setVisible]= useState(20)

    const currentResultsProperties = getProperties.slice(0,visible);
 
    


    


    const loadMore = async e => {
        setVisible(visible+10)
    }


    useEffect(() => {

      axios.get('/api/auth')
      .then(res => {
        setUser(res.data)
      }) 
      .catch((err) => {
        console.log(err);
      })
 
 
        axios.get('/api/categories/by/username')
        .then(res => {
          //console.log(res);
          setProperties(res.data)
        })
        .catch((err) => {
          console.log(err);
        })

  
      
         
 
      }, [user]);


 

      const CategoriesValid = ( 
  
        <div className="">

        <center>
        <div class="main-list" style={{border:'none'}} >
        <div className="dash-title"> {t('categories_management_title')} </div>
        {/* <Link to="/dashboard/main" className="Action-button-plus-admin">  <i className="fa fa-arrow-left fa-1x"></i> {t('backButton')} </Link> */}
        <Link to="/dashboard/categories/AddSystemCategory" className="Action-button-plus-admin">  <i className="fa fa-plus fa-1x"></i> {t('addButton')}   </Link>
        </div>
        </center>
  
   

 <div className=''>


 {/* /////////////////////////////////////////////// العقارات//////////////////////////////////////////////// */}
 <div style={{width:'100%'}}>
  
 {/* <div className="dash-title"> {t('categories_properties')} - {getProperties.length} </div> */}
 
  
  {currentResultsProperties
   .map(category => (
  
  <center>
  <div class="main-list" key={category._id}>


  <div className="title-in-list">
  <a class="title-in-list" href="#">
  {i18next.language === 'ar'&& category.c_AR_name}
  {i18next.language === 'en'&& category.c_EN_name}    
  </a>
  </div>

 

  {category.image && (
  <img src={category.image} style={{ width: '120px', height:'80px',marginBottom:''}} />
  )}

   
    <div>
	<p class="list-details"><span className="redColor">{t('desc_title')}:</span>{category.c_description} </p>


	<p class="list-button">
  {/* <button class="Action-button-status">  <i class="fa fa-eye fa-1x"></i>  </button>  */}
	<Link to={`/dashboard/categories/editCategory/${category._id}`} style={{textDecoration:'none'}}> <button class="Action-button-status">     <i className="fa fa-edit fa-1x"></i>   </button> </Link> 

          { i18next.language === 'ar' &&(
            <ConfirmButton
            dialog={["", "هل أنت متأكد؟", "تأكيد الحذف"]}
            action={() => deleteCategory(category._id)}
              />
          )}

          { i18next.language === 'en' &&(
            <ConfirmButton
            dialog={["", "?are you sure", "Confirm deletion"]}
            action={() => deleteCategory(category._id)}
              />
          )}
           

	{/* <button class="Action-button-delete">  <i class="fa fa-close fa-1x"></i>  </button> */}
	</p>
	</div> 
</div>
</center>
 ))}



{visible < getProperties.length && (
  <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> {t('moreButton')} </button> 
      </center>
  )}

</div>
{/* ///////////////////////////////////////////////نهاية العقارات//////////////////////////////////////////////// */}







 






           </div>
        </div>
       

    )

 
const notValidPage =(
  <Fragment>
         <center> 
       <Spinner />
      </center>
  </Fragment>
)
   
    return(
      <div className="aqle3-main">
      <div className="mainword2"> 

     
     
      {i18next.language === 'ar'&&(<Navbar />)}
      {i18next.language === 'en'&&(<NavbarEnglish />)}

      {i18next.language === 'ar'&&(
      <center> 
      <div className='info-nav-main-frame'> 
      <div className="mainword-section-left">
      {getProperties.length < 0 ? notValidPage : CategoriesValid} 
      </div>
      </div>
      </center>
      )}


  {i18next.language === 'en'&&(
      <center> 
      <div className='info-nav-main-frame-english'> 
      <div className="mainword-section-right">
      {getProperties.length < 0 ? notValidPage : CategoriesValid} 
      </div>
      </div>
      </center>
      )}
     


    

    </div>
    </div>
    );
}
 
 

SystemCategories.propTypes = {
    //getMarkets: PropTypes.func.isRequired,
    //market: PropTypes.object.isRequired,
    deleteCategory: PropTypes.func.isRequired,
  };


  const mapStateToProps = state => ({
    category: state.category
  });


    
  export default connect(
    mapStateToProps,
    {deleteCategory}
  )(SystemCategories);

 
