import axios from 'axios';
import { setAlert } from './alert'; 
import {
  GET_POSTS,
  POST_ERROR,
  UPDATE_LIKES,
  UPDATE_CLICKS, 
  DELETE_POST,
  ADD_POST,
  GET_POST,
  ADD_COMMENT,
  ADD_COLOR,
  ADD_SIZE, 
  REMOVE_COMMENT,
  UPDATE_FIVESTARS,
  UPDATE_FOURSTARS,
  UPDATE_THREESTARS,
  UPDATE_TWOSTARS,
  UPDATE_ONESTARS,
  UPDATE_POST, 
  GET_COLORS,
  COLORS_ERROR,
  GET_SIZES,
  SIZES_ERROR,
  DELETE_SIZE,
  DELETE_COLOR
} from './types';

 
// Get posts
export const getPosts = () => async dispatch => {
  try {
    const res = await axios.get('/api/posts');

    dispatch({
      type: GET_POSTS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add like
export const addLike = id => async dispatch => {
  
  try {
    const res = await axios.put(`/api/posts/like/${id}`);

    dispatch({
      type: UPDATE_LIKES,
      payload: { id, likes: res.data }
    });
    
    
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Remove like
export const removeLike = id => async dispatch => {
  try {
    const res = await axios.put(`/api/posts/unlike/${id}`);

    dispatch({
      type: UPDATE_LIKES,
      payload: { id, likes: res.data }
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



// Add click
export const addClick = id => async dispatch => {
  
  try {
    const res = await axios.put(`/api/posts/click/${id}`);

    dispatch({ 
      type: UPDATE_CLICKS,
     
      payload: { id, clicks: res.data }
    });
    
    
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

  
// Delete post
export const deletePost = id => async dispatch => {
  try {
    await axios.delete(`/api/posts/${id}`);

    dispatch({
      type: DELETE_POST,
      payload: id
    });

    dispatch(setAlert('Post Removed', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add post
export const addPost = formData => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  try {
    const res = await axios.post('/api/posts', formData, config);
 
    dispatch({
      type: ADD_POST,
      payload: res.data
    });
    window.location= `/dashboard/posts/`
    dispatch(setAlert('Post Created', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
   
// Get post
export const getPost = id => async dispatch => {
  try {
    const res = await axios.get(`/api/posts/${id}`);
   
    dispatch({
      type: GET_POST,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};





 
 

 



// Add comment
export const addComment = (postId, formData) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }; 

  try {
    const res = await axios.post(
      `/api/posts/comment/${postId}`,
      formData,
      config
    );

    dispatch({
      type: ADD_COMMENT,
      payload: res.data
    });

    dispatch(setAlert('Comment Added', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


   

// Add color
export const addColor = (postID,{color_AR_name,color_EN_name, color_code,image}) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }; 
 
  const body = JSON.stringify({color_AR_name,color_EN_name, color_code,image,postID});

  try {
    const res = await axios.post(
      `/api/posts/colors/${postID}`,
      body,
      config
    );

    dispatch({
      type: ADD_COLOR,
      payload: res.data
    });
       
    dispatch(setAlert('color Added', 'success'));
  
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


 

// Add size
export const addSize = (postID,{size_AR_name,size_EN_name,size_code,image}) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }; 

  const body = JSON.stringify({size_AR_name,size_EN_name,size_code,image,postID});

  try {
    const res = await axios.post(
      `/api/posts/sizes/${postID}`,
      body,
      config
    );

    dispatch({
      type: ADD_SIZE,
      payload: res.data
    });

    dispatch(setAlert('size Added', 'success'));
 
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
 
  
// Delete Color
 
export const deleteColor = (postID, colorID) => async dispatch => {
  try {
    await axios.delete(`/api/posts/colors/${postID}/${colorID}`);

    dispatch({
      type: DELETE_COLOR,
      payload: colorID
    });

    dispatch(setAlert('color Removed', 'success'));

    window.location=`/posts/${postID}`
  } catch (err) {
    dispatch({
      type: COLORS_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};




// Delete Size
export const deleteSize = (postID,sizeID) => async dispatch => {
  try {
    await axios.delete(`/api/posts/sizes/${postID}/${sizeID}`);

    dispatch({
      type: DELETE_SIZE,
      payload: sizeID
    });
    dispatch(setAlert('Size Removed', 'success'));
    window.location=`/posts/${postID}`

  } catch (err) {
    dispatch({
      type: SIZES_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


// Delete comment
export const deleteComment = (postId, commentId) => async dispatch => {
  try {
    await axios.delete(`/api/posts/comment/${postId}/${commentId}`);

    dispatch({
      type: REMOVE_COMMENT,
      payload: commentId
    });

    dispatch(setAlert('Comment Removed', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};


 

// Add five_stars
export const addFiveStars = id => async dispatch => {
   
  try {
    const res = await axios.put(`/api/posts/five_stars/${id}`);

    dispatch({
      type: UPDATE_FIVESTARS,
      payload: { id, five_stars: res.data }
    });
    
     
    
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};




// Add four_stars
export const addFourStars = id => async dispatch => {
   
  try {
    const res = await axios.put(`/api/posts/four_stars/${id}`);

    dispatch({
      type: UPDATE_FOURSTARS,
      payload: { id, four_stars: res.data }
    });
    
      
    
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



// Add three_stars
export const addThreeStars = id => async dispatch => {
   
  try {
    const res = await axios.put(`/api/posts/three_stars/${id}`);

    dispatch({
      type: UPDATE_THREESTARS,
      payload: { id, three_stars: res.data }
    });
    
   
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



// Add two_stars
export const addTwoStars = id => async dispatch => {
   
  try {
    const res = await axios.put(`/api/posts/two_stars/${id}`);

    dispatch({
      type: UPDATE_TWOSTARS,
      payload: { id, two_stars: res.data }
    });
    
    
    
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};




// Add one_stars
export const addOneStars = id => async dispatch => {
   
  try {
    const res = await axios.put(`/api/posts/one_stars/${id}`);

    dispatch({
      type: UPDATE_ONESTARS,
      payload: { id, one_stars: res.data }
    });
    
  
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};



 


 
// edit validity
// Register User
export const editActivation = (postId,formData) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }; 

  try {
    const res = await axios.post(
      `/api/posts/activatePost/${postId}`,  
      formData,
      config
    );

    dispatch({
      type: UPDATE_POST,
      payload: res.data
    });

    // dispatch(setAlert('Comment Added', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};
  





// edit post
// editPost
export const editPost = (formData,postID) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  }; 
 
  try {
    const res = await axios.post(
      `/api/posts/update/${postID}`,  
      formData,
      config
    );

    dispatch({
      type: UPDATE_POST,
      payload: res.data
    });

    // window.location=`/posts/${postID}`

    window.location= `/dashboard/posts/`

    // dispatch(setAlert('Comment Added', 'success'));
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};