	  import React, { Fragment } from 'react';
    import { Link } from 'react-router-dom';
    import { useTranslation } from 'react-i18next';
    import smalllogo from '../../images/smalllogo.png';
      
      const Footer = () => {
        const [t, i18next] = useTranslation()
		return (
		  <Fragment>
 <footer className="myFooter">
  
  <center>
<div className='vertical-menu-wrapper'>


 

<ul className='vertical-menu-about'>
<li>   <Link> <img src={smalllogo} width={65} height={65} /> </Link> </li>
  <li>  <Link> FAIZ ADS LLC </Link> </li>
  <li>  <Link> (312) W 2nd St Unit #A1434 </Link> </li>
  {/* <li>  <Link> USA Casper, WY 82601</Link> </li> */}
  <li>   <Link> <i class="fa fa-phone-square fa-1x" aria-hidden="true"></i> 12764775499+ </Link> </li>
 
  </ul>  




 
{i18next.language === 'ar'&&(
<ul className='vertical-menu'>
  <li>   <div className='vertical-menu-active'> </div> </li>
  <li>  <Link to="/company/about">من نحن </Link></li>
  <li>  <Link to="/company/contact">اتصل بنا</Link> </li>
  <li>  <Link to="/company/privacy">سياسة الخصوصية</Link> </li>
  <li>  <Link to="/company/terms">شروط الاستخدام</Link></li>
  </ul>
)}
{i18next.language === 'en'&&(
<ul className='vertical-menu'>
  <li>   <div className='vertical-menu-active'> </div> </li>
  <li>  <Link to="/company/about">About us </Link></li>
  <li>  <Link to="/company/contact">Contact</Link> </li>
  <li>  <Link to="/company/privacy">Privacy</Link> </li>
  <li>  <Link to="/company/terms">Terms and Conditions</Link></li>
  </ul>
)}

 

{i18next.language === 'ar'&&(
  <ul className='vertical-menu'> 
  <li>  <div className='vertical-menu-active'>  </div> </li>
  <li> <Link to="/user/createAccount">انشاء حساب </Link> </li>
  <li> <Link to="/user/login">دخول الأعضاء</Link> </li>
  <li> <Link to="/membership/prices">باقات العضوية</Link> </li>
  <li> <Link to="/user/registrationMethod">طريقة التسجيل</Link> </li>
  </ul>
)}
{i18next.language === 'en'&&(
  <ul className='vertical-menu'> 
  <li>  <div className='vertical-menu-active'>  </div> </li>
  <li> <Link to="/user/createAccount">Create account </Link> </li>
  <li> <Link to="/user/login">Login</Link> </li>
  <li> <Link to="/membership/prices">Membership</Link> </li>
  <li> <Link to="/user/registrationMethod">How it works </Link> </li>
  </ul>
)}
  




  {i18next.language === 'en'&&(
  <ul className='vertical-menu'> 
  <li>  <div className='vertical-menu-active'>  </div> </li>
  <li> <Link to="/company/affiliate">Affiliates </Link> </li>
  <li> <Link to="/#">Blog</Link> </li>
  <li> <Link to="/#">Events</Link> </li>
  <li> <Link to="/#">News</Link> </li>
  </ul>
)}

{i18next.language === 'ar'&&(
  <ul className='vertical-menu'> 
  <li>  <div className='vertical-menu-active'>  </div> </li>
  <li> <Link to="/company/affiliate">انضم إلى برنامج الشركاء </Link> </li>
  <li> <Link to="/">المدونة</Link> </li>
  <li> <Link to="/">مناسبات </Link> </li>
  <li> <Link to="/">أخبار</Link> </li>
  </ul>
)}



 
 



 

 
  

</div>
</center>
			 
<center> 
<div className="paymentMethodeSectionFooter">  
 <i class="fa fa-cc-amex fa-3x" aria-hidden="true"></i>
 <i class="fa fa-cc-paypal fa-3x" aria-hidden="true"></i>
 <i class="fa fa-cc-stripe fa-3x" aria-hidden="true"></i>
 <i class="fa fa-cc-mastercard fa-3x" aria-hidden="true"></i>
 <i class="fa fa-cc-visa fa-3x" aria-hidden="true"></i>
 </div>
 </center>
  
 
 
<footer className="myFooter2">
 <div className="copyrighttext">  
				© 2023 faizads.com online shops - All Rights Reserved.
	</div>
 
   				 </footer> 
   </footer>
		 
		  </Fragment>
		);
	  };

 
 

     

export default Footer;
 