import React, { Fragment, useEffect ,useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Navbar from '../../components/layout/Navbar';
import NavbarEnglish from '../../components/layout/NavbarEnglish';
import Footer from '../../components/layout/Footer';
import { useTranslation } from 'react-i18next';
import { addContact } from '../../actions/auth';
import { setAlert } from '../../actions/alert';
import axios from 'axios';

    
const Contact = ({setAlert,addContact}) => { 
 
  const [t, i18next] = useTranslation()
  // const Lang = match.params.lang;


  const [formData, setFormData] = useState({
    contact_name:'',
    contact_email: '',
    contact_msg:'',
    contact_reasone:''
  
    });

    const { contact_email,contact_msg,contact_name,contact_reasone } = formData;

    const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
      e.preventDefault();
      addContact({ contact_email,contact_msg,contact_name,contact_reasone});
       setAlert('category added', 'success');
      
    };
 
  return (
    <Fragment>


<div className="aqle3-main">
<div className="mainword2">  

      
      {i18next.language === 'ar'&&(<Navbar />)}
      {i18next.language === 'en'&&(<NavbarEnglish />)}
  

{i18next.language === 'ar'&&(
 <Fragment>
 <center>
 <div className='login-form' onSubmit={e => onSubmit(e)}> 
 <div className='FormCover'>  
     
     <h1 className="middle-text-primary"> تواصل معنا <i class="fa fa-envelope-square"></i>  </h1>

 

<form class="form" action="">


 <div className=''>
          <span className="login-text">    الاسم </span>
          <input
           className="login-input"
            type='text'
            name='contact_name'
           onChange={e => onChange(e)}
            
          />
          </div> 

 

      <div className=''>
          <span className="login-text"> البريد الإلكتروني </span>
          <input
           className="login-input"
            type='email'
            name='contact_email'
           onChange={e => onChange(e)}
            required
          />
          </div>


          <div className=''>
          <span className="login-text"> سبب التواصل </span>
          <select
           className="login-input-select"
            name='contact_reasone'
           onChange={e => onChange(e)}
            
          >
            <option>اختر </option>
          <option value='إبلاغ عن خلل'>إبلاغ عن خلل</option>
          <option value='إبلاغ عن شكوى'>إبلاغ عن شكوى</option>
          <option value='التقييم'>التقييم</option>
          <option value='الإعلان'>الإعلان</option>
          <option value='العضوية'>العضوية</option>
          <option value='الأعضاء'>الأعضاء</option>
          <option value='الردود'>الردود</option>
          <option value='الاشتراكات الشهرية'>الاشتراكات الشهرية</option>
          <option value='العمولة'>العمولة</option>
          <option value='أخرى'>أخرى</option>



          </select>
          </div>

 
 

          <div className=''>
          <span className="login-text"> رسالتك </span>
          <textarea
           className="login-input-textarea"
            name='contact_msg'
           onChange={e => onChange(e)}
            required
          />
          </div> 



          <button  className="Formbutton"  type='submit' value='Login'>ارسل <i class="fa fa-send"></i></button>
</form>

   </div>
   </div>
   </center>
    </Fragment>
)}











{/* ///////////////////////////////////////////ENGLISH//////////////////////////////////////////////////////////////// */}
{i18next.language === 'en'&&(
 <Fragment>
 <center>
 <div className='login-form' onSubmit={e => onSubmit(e)}> 
 <div className='FormCover'>  
     
     <h1 className="middle-text-primary"><i class="fa fa-envelope-square"></i>  Connect with us   </h1>

 

<form class="form" action="">


 <div className=''>
          <span className="login-text">    Name </span>
          <input
           className="login-input"
           
            type='text'
            name='contact_name'
           onChange={e => onChange(e)}
            
          />
          </div> 



      <div className=''>
          <span className="login-text"> Email </span>
          <input
           className="login-input"
            type='email'
            name='contact_email'
           onChange={e => onChange(e)}
            required
          />
          </div>


          <div className=''>
          <span className="login-text"> Reason for contact </span>
          <select
           className="login-input-select"
            name='contact_reasone'
           onChange={e => onChange(e)}
            
          >
          <option>Chose</option>
          <option value='إبلاغ عن خلل'>Report a bug</option>
          <option value='إبلاغ عن شكوى'>Report a complaint</option>
          <option value='التقييم'>Evaluation</option>
          <option value='الإعلان'>Advertising</option>
          <option value='العضوية'>Membership</option>
          <option value='الأعضاء'>Memberships</option>
          <option value='الردود'>replies</option>
          <option value='الاشتراكات الشهرية'>Monthly subscriptions</option>
          <option value='العمولة'>commissions</option>
          <option value='أخرى'>Other</option>



          </select>
          </div>

 
 

          <div className=''>
          <span className="login-text"> Message </span>
          <textarea
           className="login-input-textarea"
            name='contact_msg'
           onChange={e => onChange(e)}
            required
          />
          </div> 



          <button  className="Formbutton"  type='submit' value='Login'> <i class="fa fa-paper-plane" aria-hidden="true"></i> Send</button>
</form>

   </div>
   </div>
   </center>

   
    </Fragment>
)}

{/* /////////////////////////////////////////////////////////////////////////////////////////////////////// */}
   
<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>

   <Footer />
</div>
</div>
    </Fragment>
  );
};

 

Contact.propTypes = {
  addContact: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { setAlert,addContact }
)(Contact);
