import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment'; 
import moment from 'moment';
import 'moment/locale/ar';
import { deleteComment } from '../../actions/post';
import { useTranslation } from 'react-i18next';


const CommentItem = ({
  userID,
  userName,
  postId,
  comment: { _id, text,username, first_name,last_name, avatar, user, date },
  auth,
  deleteComment
}) =>{

  const [t, i18next] = useTranslation()

  {i18next.language === 'ar' && moment.locale('ar'); }
  {i18next.language === 'en' && moment.locale('en'); }
  {i18next.language === 'fr' && moment.locale('fr'); }

return(
  <center>  
   {userID === user ?(
    <Fragment>
    <div className='main-list-owner'>
    <div>
      <Link to={`/shops/${userName}`}>
        {avatar ?(
          <img className='round-img' src={avatar} alt='' />
        ):(
          <i class="fa fa-home fa-3x round-img" aria-hidden="true"></i>
        )}
         
      </Link>
    </div>
    <div> 
    {/* <div className='comments-title-username-owner'>{userName}@ </div> */}
    <div className='comments-title-name-owner'>{first_name} {last_name} صاحب الإعلان 
    {' '}<i class="fa fa-bullhorn" aria-hidden="true"></i>
    </div>
   
      <p className='about-title-owner'>{text}</p>
    

       
     

    
    
    </div>

    {!auth.loading && user === auth.user._id && (
        <button
          onClick={() => deleteComment(postId, _id)}
          type='button'
          className='Action-button-delete-comment-ar'
        >
          <i className='fa fa-trash' />
        </button>
      )}

<div className='main-list-bottom'>
  <center> 
<Moment format='YYYY/MM/DD'>{date}</Moment>  
</center>
</div>

  </div>

 
    </Fragment>
   ):(


    <Fragment>
<div className='main-list'>
<div>
  <Link to='/shops'>
    {avatar ?(
      <img className='round-img' src={avatar} alt='' />
    ):(
      <i class="fa fa-user fa-3x round-img" aria-hidden="true"></i>
    )}
     
  </Link>
</div>

{/* <div className='comments-title-username'>{user}@ </div> */}
<div className='comments-title-name'>{first_name} {last_name} {''}  <br/>

 

{i18next.language === 'ar' && (
      <Fragment>
       
         التاريخ<Moment format='YYYY/MM/DD'>{date}</Moment> 
       
      </Fragment>
     )}

{i18next.language === 'en' && (
      <Fragment>
      
       Date: <Moment format='YYYY/MM/DD'>{date}</Moment>
       
      </Fragment>
     )}
   
 

</div>


  <center> <p className='about-title'>{text}</p></center>

 



{!auth.loading && user === auth.user._id && (
    <button
      onClick={() => deleteComment(postId, _id)}
      type='button'
      className='Action-button-delete-comment-ar'
    >
      <i className='fa fa-trash' />
    </button>
  )}









<div className='main-list-bottom'>
  <center> 
  <Moment format='YYYY/MM/DD'>{date}</Moment>
</center>
</div>




</div>


</Fragment>


   )}
  
  </center>
);
}

CommentItem.propTypes = {
  postId: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { deleteComment }
)(CommentItem);
