import React, {Fragment,useEffect,useState } from "react"; 
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { deleteColor } from '../../actions/post';
import { useTranslation } from 'react-i18next';

const DeleteColor = ({isOpen, toggle,colorID,postID, deleteColor}) => {

  const [t, i18next] = useTranslation()
  

  useEffect(() => {
    document.body.classList.toggle("modal-open", isOpen);
  }, [isOpen]);

 
  

    const onSubmit = async e => {
      e.preventDefault();
     await  deleteColor(postID,colorID);
      window.location=`/posts/${postID}`
       toggle();
    };


  

  // const handleDelete = () => {
  //   toggle();

  
  //   }
 




  return (
    
    <>
    <div
      className={isOpen ? "modal fade show" : "modal fade"}
      id="DeleteModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="DeleteModalTitle"
      aria-hidden={isOpen}
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
{colorID} 
          {i18next.language === 'ar' &&(<div className="abokhaled-title-button-new-blue">حذف  لون</div>)}
          {i18next.language === 'en' &&(<div className="abokhaled-title-button-new-blue"> Delete color</div>)}

          <button
                type="button"
                className="abokhaled-close-button-new-blue"
                data-dismiss="modal"
                onClick={toggle} 
              > 
                <i className="fa fa-close fa-2x"></i>
              </button>

          
          </div>
          <div className="modal-body text-center delete-modal">
        
      
          
          


   <div className='mainForm'>
    
    <div className=''>
      <form
        className='' 
        onSubmit={e => onSubmit(e)}
      > 
   
      {i18next.language === 'ar' &&(
        <Fragment> 
     
 <h1 className="about-title">أنت على وشك حذف هذا اللون، هل أنت متأكد ؟</h1>

     <button className="Formbutton"  >  
        حذف  {' '}
          <i class="fa fa-trash" aria-hidden="true"></i> 
          </button>


          </Fragment>
      )}


 

{i18next.language === 'en' &&(
        <Fragment> 

   

         
<button className="Formbutton" type='submit'  >  
          <i class="fa fa-plus" aria-hidden="true"></i>  {' '}
          Delete 
          </button>

          </Fragment>
      )}
        
      </form>
    </div>
    </div>




  
           
            <div className="modal-footer border-0 justify-content-center">
              
              {/* <button
                type="button"
                className="abokhaled-close-button-new-blue"
                data-dismiss="modal"
                onClick={toggle} 
              > 
                <i className="fa fa-close fa-2x"></i>
              </button> */}


              {/* <button
                type="button" 
                className="abokhaled-delete-button-new-red"
                onClick={handleDelete}
              >
                <i class="fa fa-trash fa-2x" aria-hidden="true"></i>
              </button> */}


            </div>


          </div>
        </div>
      </div>
    </div>
    {isOpen && <div className="modal-backdrop fade show"></div>}
  </>

  )
}


DeleteColor.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  deleteColor: PropTypes.func.isRequired
};
  

export default connect(
  null,
  { deleteColor }
)(DeleteColor);


 