import React, { Fragment, useEffect ,useState } from 'react';
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
 

import { Link } from 'react-router-dom';
import axios from 'axios';
import FollowUpForm from '../followups/FollowUpForm';   
import RatingForm from '../ratings/RatingForm';
   
import { deleteFollowup } from '../../actions/followup';
import { addClick } from '../../actions/post';
import cover from './default-cover.jpg';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Alert from '../layout/Alert';
import { setAlert } from '../../actions/alert';
import ConfirmButton from "./ConfirmButton";

import noimg from './noimg.png';
  

import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/en-gb';

import Spinner from '../layout/Spinner';   
import Navbar from '../layout/NavbarSamaher'; 
import NavbarEnglish from '../layout/NavbarSamaherEnglish';
import FooterSYS from '../layout/FooterSYS';
import NavbarSamaherCategories from '../layout/NavbarSamaherCategories';
import NavbarSamaherEnglishCategories from '../layout/NavbarSamaherEnglishCategories';
import { useTranslation } from 'react-i18next';

 
import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
 
const formatter = new Intl.NumberFormat('en',{
  
  style:'decimal',
  // signDisplay:'always',
  useGrouping:true,
  notation:'compact'

});
  

// import { addToCart } from "../../reducers/cart"; 

 const Myshop = ({setAlert,match,deleteFollowup,loading,addClick,iid}) => {

  //  const dispatch = useDispatch();
  // const navigate = useNavigate();
  
  const [t, i18next] = useTranslation()

  const [userCategories,setUserCategories]= useState([])

  const [userShop,setuserShop]= useState([])

  const [Display,setDisplay]= useState('FollowButtons')
  const [IfUserFollow,setIfUserFollow]= useState([])
  const [UserFollowing,setUserFollowing]= useState([])
  const [UserFollowers,setUserFollowers]= useState([])
  
   
    const [shop,setShop]= useState([])
    const [shopImg,setImg]= useState([])

    const [posts,setPosts]= useState([])
    const [user,setUser]= useState([])
    const [visible,setVisible]= useState(12)
    const currentResults = posts.slice(0,visible);

 

    const [larg,setLarg]= useState('displayI')
    const [list,setList]= useState('noneDisplayI')
    
    const [showStyleLarge,setshowStyleLarge]= useState('showStyleSelected')
    const [showStyleList,setshowStyList]= useState('showStyle')



    const showLarg = async e => { 
      setLarg('displayI')
      setList('noneDisplayI')
      setshowStyleLarge('showStyleSelected')
      setshowStyList('showStyle')
    };
   
    const showList = async e => {
      setList('displayI')
      setLarg('noneDisplayI')
      setshowStyList('showStyleSelected')
      setshowStyleLarge('showStyle')
    };
  
 
    const loadMore = async e => {
      setVisible(visible+10)
    } 
 
    const hideButton = async e => {
      setDisplay('UNFollowButtons')
    }
 
    const showButton = async e => {
      setDisplay('FollowButtons')
    }
    
    const UnFollow = async e => {
      window.alert('delete function')
    }

    
    
    useEffect(() => {


      axios.get('/api/categories/by/username/'+match.params.username)
      .then(res => {
        setUserCategories(res.data)
      })
      .catch((err) => {
        console.log(err);
      })


    
        axios.get('/api/shops/'+match.params.username)
          .then(res => {
            setShop(res.data)
          })
          .catch((err) => {
            console.log(err);
          })


          axios.get('/api/shops/image/'+match.params.username)
          .then(res => {
            setImg(res.data)
          })
          .catch((err) => {
            console.log(err);
          })


    

          axios.get('/api/posts/postsofshop/'+match.params.username)
          .then(res => {
            setPosts(res.data)
          })
          .catch((err) => {
            console.log(err);
          })


         

  

          axios.get('/api/followups/doyoufollow/'+match.params.username)
          .then(res => {
            setIfUserFollow(res.data)
          })
          .catch((err) => {
            console.log(err);
          })


          axios.get('/api/followups/userfollowing/'+match.params.username)
          .then(res => {
            setUserFollowing(res.data)
          })
          .catch((err) => {
            console.log(err);
          })


          axios.get('/api/followups/userfollowers/'+match.params.username)
          .then(res => {
            setUserFollowers(res.data)
          })
          .catch((err) => {
            console.log(err);
          })

 
          axios.get('/api/shops/usershop')
          .then(res => {
            // console.log(res);
            setuserShop(res.data)
          })
          .catch((err) => {
            console.log(err);
          })


          axios.get('/api/auth')
          .then(res => {
            console.log(res);
            setUser(res.data)
          })
          .catch((err) => {
            console.log(err);
          })
      
      
 
        

      }, [IfUserFollow]);
 
      
 
 
// shop click
 const shopClick=(id)=> {
  axios.put('/api/shops/click/'+id)
  .then(response => {
   console.log(response.data)
    
 });
};

    
{i18next.language === 'ar' && moment.locale('ar'); }
{i18next.language === 'en' && moment.locale('en'); }
{i18next.language === 'fr' && moment.locale('fr'); }

 
 
// const handleAddToCart = (product) => {
//   dispatch(addToCart(product));
//   navigate("/shopping/cart");
// };




const settings = {
  className: "carousel-item-b swiper-slide",
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  adaptiveHeight: true,
  autoplay: true,
  autoplaySpeed: 1000,
  pauseOnHover: true,
  rtl: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

  
 
     return  loading ? (
      <Spinner/>
    ) : ( 
      <div  className="aqle3-main" >
      <div className="mainword2">
      {i18next.language === 'ar'&&(<Navbar shopID={match.params.username} shop={shop} />)}
      {i18next.language === 'en'&&(<NavbarEnglish shopID={match.params.username} shop={shop} />)}

     
      {i18next.language === 'ar'&&(<NavbarSamaherCategories shopID={match.params.username}  userCategories={userCategories}  />)}
      {i18next.language === 'en'&&(<NavbarSamaherEnglishCategories shopID={match.params.username} userCategories={userCategories}   />)}
 
   
      {/* {shop.map(shop => ( <img  src={shop.shop_logo_img} />    ))} */}

{/* /////////////////////////////////////////////////المنتجات////////////////////////////////////////////////// */}
 
  {posts.length <= 0 ?(
  <Fragment>
   <center>   <h1 className='noPostsExist'> ... </h1> </center>
  </Fragment>
 
):(
<Fragment> 


{posts.length > 0 &&(

  <Fragment>

 
 
<center> 
<div className='posts-shop-frame'>

 
<center> 
 <div style={{width:'100%'}}> 
 {i18next.language === 'ar'&&(<h1 className='BigTitle1'>   ... وصل حديثا   </h1>)}
 {i18next.language === 'en'&&(<h1 className='BigTitle1'>    Latest products ...    </h1>)}
 {/* <button  onClick={showLarg} className={showStyleLarge}> <i class="fa fa-th-large fa-1x" aria-hidden="true"></i> </button>
 <button  onClick={showList} className={showStyleList}> <i class="fa fa-th-list fa-1x" aria-hidden="true"></i> </button> */}
 </div>
 </center>


{/* ////////////////////////////////////////Slider Start////////////////////////////////////////////////////// */}
 <div>
  
        {posts?.length ? (
        <Slider {...settings}>
          {currentResults.map((element) => (
            <Fragment> 
          <div key={element._id} className="product">
            {/* <h3>{element.title}</h3> */}
                <Link  to={`/posts/${element._id}`}> 
                        <img
                          src={element.image}
                          alt=""
                          className="" 
                          width={200}
                          height={200}
                        />
                        </Link>
                        <h3>{element.title}</h3> 
                         
          </div>
   
          </Fragment>
            ))}
          </Slider>
            ) : null}
      </div>
  {/* ////////////////////////////////////////Slider end////////////////////////////////////////////////////// */}

 
{/* //////////////////////////////////////////////start of larg shap//////////////////////////////////////////// */}
 {larg === 'displayI'&&(
   <Fragment> 
 

 <div className="products">
          {posts &&
            currentResults?.map((product) => (
               
              <div key={product._id} className="product">
                
                {/* <h3> عنوان المنتح من 4 كلمات </h3> */}
                <Link  to={`/posts/${product._id}`}> 
                <img src={product.image} alt={product.title} />
                </Link>
                <h3>{product.title}</h3> 
                <div className="details">
                 {/* <span>{product.Main_paragraph}</span>
                  <span className="price">${product.price}</span>  */}
 
                
                <Link to={`/posts/${product._id}`}> 
                <button >
                 ${product.price}
                </button>
                </Link>

                <Link to={`/posts/${product._id}`}> 
                <button >
                <i class="fa fa-shopping-cart" aria-hidden="true"></i>  الشراء 
                </button></Link>


                </div> 



              </div>

            ))}
        </div>



{visible < posts.length && (
  <center> 
     {i18next.language === 'ar'&&(  <button   onClick={loadMore} className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> عرض المزيد من المنتجات   </button> )}
     {i18next.language === 'en'&&(  <button   onClick={loadMore} className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> View more products   </button> )}


   </center>
  )}


</Fragment>
)}
{/* //////////////////////////////////////////////end of larg shap//////////////////////////////////////////// */}




 
{/* //////////////////////////////////////////////العرض القوائم //////////////////////////////////////////// */}

{list === 'displayI'&&(
   <Fragment> 
{currentResults
  
 .map(post => ( 
   <center> 
  <div style={{width:'100%'}} className="main-list">

{post.premium === 'no' &&(
          <div className='title-in-list'>
          <Link onClick={() => addClick(post._id)} className='title-in-list'  target="_blank" to={`/${post.country_code}/${post.city_code}/${post.market_code}/${post.purpose_code}/posts/${post._id}`} >    
           {post.title}   
           </Link>
          </div>
        )}


{post.premium === 'yes' &&(
          <div className='title-in-list-premium'>
          <Link  onClick={() => addClick(post._id)} className='title-in-list-premium'  target="_blank" to={`/${post.country_code}/${post.city_code}/${post.market_code}/${post.purpose_code}/posts/${post._id}`} >    
            <i className="fa fa-star fa-1x"></i> {' '} / {post.title}       
           </Link>
          </div>
        )} 



 

<div className='section-list'> 
  <div className="" key={post._id}>
  <Link onClick={() => addClick(post._id)} target="_blank" to={`/${post.country_code}/${post.city_code}/${post.market_code}/${post.purpose_code}/posts/${post._id}`}  > 
  </Link>
   
  <div>
      {post.image ? (
        <Link  onClick={() => addClick(post._id)} target="_blank" to={`/${post.country_code}/${post.city_code}/${post.market_code}/${post.purpose_code}/posts/${post._id}`}  > <img className='image-in-list' src={post.image}  /></Link>
        ):(
    
            <Link  onClick={() => addClick(post._id)} target="_blank" to={`/${post.country_code}/${post.city_code}/${post.market_code}/${post.purpose_code}/posts/${post._id}`}  > <img className='image-in-list' src={noimg}  /></Link>
            )}
       </div>
  
  
  </div>
  {/* //////////////////section list end ///////// */}
  
  
  <div className="list-details" style={{marginTop:'20px'}}>

  {post.Main_paragraph} </div>

{/* ////////////////////end of list////////////////////////////////////// */}

    </div>

      

  <div className='section-list'> 
 

  {/* <Link onClick={() => shopClick(post.shop._id)} to={`/shops/${post.shop.username}`} target="_blank"> 
  <button className="button-in-list-home-big">
  <i class="fa fa-home fa-1x" aria-hidden="true"></i>   
  </button>
  </Link>  */}


  <Link  onClick={() => addClick(post._id)} to={`/main/${post.country_code}/${post.city_code}/${post.market_code}`} target="_blank" style={{textDecoration:'none'}} >   <button className="button-in-list-home-small" > 
  {i18next.language === 'ar' && post.market.m_AR_name}
  {i18next.language === 'en' && post.market.m_EN_name}{' '}
   <i className="fa fa-list fa-1x"></i> 
      </button>
    </Link>
  
  
 


 
  <button className="button-in-list-home-small">
  <i class="fa fa-clock-o" aria-hidden="true"></i> {' '} 
  {moment(post.date).startOf('minut').fromNow()}   
  </button>



  <button className="button-in-list-home-small" >
  {i18next.language === 'ar'&& <>ينتهي</>} 
  {i18next.language === 'en'&& <>Expire</>}{' '}
  {moment(post.expired).endOf('day').fromNow()}   
  <i class="fa fa-hourglass-end" aria-hidden="true"></i> 
  </button>
  


  <button className="button-in-list-home-small" >
  <i class="fa fa-comments" aria-hidden="true"></i> {' '} 
  {formatter.format(post.comments.length) } 
  </button>


  <Link> 
  <button className="button-in-list-home-small" > 
  {i18next.language === 'ar' && post.city.city_AR_name} 
  {i18next.language === 'en' && post.city.city_EN_name}{' '}   
  <i class="fa fa-map-marker" aria-hidden="true"></i> 
 
   </button>
  </Link>


  <Link > 
  <button className="button-in-list-home-small" >
  <i class="fa fa-eye" aria-hidden="true"></i>  {formatter.format(post.clicks.length) }  
  </button>
  </Link>

  </div>

    



 


 </div> 
 </center>
 ))} 





{visible < posts.length && (
  <center> 
  <button   onClick={loadMore} 
  className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i>  عرض المزيد من المنتجات  </button> 
   </center>
  )}


</Fragment>
)}











 
 </div>
 </center>
 </Fragment>
)}


</Fragment>
)}
 
<FooterSYS shop={shop} />

            </div> 
            </div> 
             )}
 
 

 Myshop.propTypes = {
  deleteFollowup: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  addClick: PropTypes.func.isRequired,
  
};
  
export default connect(
  null,
  { setAlert,deleteFollowup,addClick }
)(Myshop);
 