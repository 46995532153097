import React, { Fragment, useEffect ,useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; 
import Spinner from '../layout/Spinner'; 
import DashboardActionsArabic from './DashboardActionsArabic'; 
import Experience from './Experience';
import Education from './Education';
import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import RequestForm from '../requests/RequestForm';      
import moment from 'moment';
import Moment from 'react-moment';  
// import 'moment/locale/ar';
import Navbar from '../layout/Navbar';
import NavbarEnglish from '../layout/NavbarEnglish';
const Dashboard = ({
  getCurrentProfile,
  deleteAccount,
  loading,
     
 
  // auth: { user },
  // profile: { profile, loading }
}) => {


  moment.locale('ar');
  
  // const [oneShop,setOneShop]= useState([])


  const [subscription,setSubscription]= useState([])
  const [user,setUser]= useState([])
  const [userShop,setuserShop]= useState([])
  const [categories,setCategories]= useState([])
  const [userPosts,setUserPosts]= useState([])
 const [userPostsInToday,setUserPostsInToday]= useState([])
   
 
  const [userRef,setUserRef]= useState([])
  const [visible,setVisible]= useState(10)
  const [RefToPay,setRefToPay]= useState([])


  const RefToPayResults = RefToPay.slice(0,visible);


  const loadMore = async e => {
    setVisible(visible+10)

}

  // const [OndemandAmount,setOndemandAmount]= useState([])
  const [visibleAllRequest,setVisibleAllRequest]= useState(10)
  const [allRequest,setAllRequest]= useState([])

  const allRequestResults = allRequest.slice(0,visibleAllRequest);

  const loadMoreRequests = async e => {
    setVisibleAllRequest(visibleAllRequest+10)

}

 
  const [ProfitCompleteRequest,setProfitCompleteRequest]= useState([])
  const [CheckInRequest,setCheckInRequest]= useState([])


  const [lastrequest,setLastRequest]= useState([])

  let totalpaidamounts =0;
  ProfitCompleteRequest.forEach(p=>{
    totalpaidamounts += p.amount;
    })

 
  let totalRequestedToPay =0;
  
  allRequest.forEach(p=>{
    totalRequestedToPay += p.amount;
    
    })

    
  const profit = (RefToPay.length*4) - (totalRequestedToPay);
 
    

  const myref = user.name;
  useEffect(() => {
  

    axios.get('/api/shops/usershop')
    .then(res => {
      console.log(res);
      setuserShop(res.data)
    })
    .catch((err) => {
      console.log(err);
    })
 

    axios.get('/api/subscriptions/lastsubscription')
    .then(res => {
      console.log(res);
      setSubscription(res.data)
    })
    .catch((err) => {
      console.log(err);
    })


    axios.get('/api/auth')
    .then(res => {
      console.log(res);
      setUser(res.data)
    })
    .catch((err) => {
      console.log(err);
    }) 

 
 
    axios.get('/api/posts/userposts')
    .then(res => {
      //console.log(res);
      setUserPosts(res.data)
    })
    .catch((err) => {
      console.log(err);
    })

 
    axios.get('/api/posts/userPostsInToday')
    .then(res => {
      //console.log(res);
      setUserPostsInToday(res.data)
    })
    .catch((err) => {
      console.log(err);
    })

 
    axios.get('/api/auth/ref')
    .then(res => {
      //console.log(res);
      setUserRef(res.data)
    })
    .catch((err) => {
      console.log(err);
    })

  

    axios.get('/api/subscriptions/refToPayaffiliate')
    .then(res => {
      //console.log(res);
      setRefToPay(res.data)
    })
    .catch((err) => {
      console.log(err);
    })



    // axios.get('/api/auth/OndemandAmount')
    // .then(res => {
    //   //console.log(res);
    //   setOndemandAmount(res.data)
    // })
    // .catch((err) => {
    //   console.log(err);
    // })

    

    axios.get('/api/profitrequests/allrequests')
    .then(res => {
      //console.log(res);
      setAllRequest(res.data)
    })
    .catch((err) => {
      console.log(err);
    })

 
  
    axios.get('/api/profitrequests/complete')
    .then(res => {
      //console.log(res);
      setProfitCompleteRequest(res.data)
    })
    .catch((err) => {
      console.log(err);
    })



    axios.get('/api/profitrequests/checkin')
    .then(res => {
      //console.log(res);
      setCheckInRequest(res.data)
    })
    .catch((err) => {
      console.log(err);
    })




    axios.get('/api/profitrequests/lastrequest')
    .then(res => {
   
      setLastRequest(res.data)
    })
    .catch((err) => {
      console.log(err);
    })




    axios.get('/api/shops/usershop')
    .then(res => {
      console.log(res);
      setuserShop(res.data)
    })
    .catch((err) => {
      console.log(err);
    })



    axios.get('/api/categories/by/username')
    .then(res => {
      //console.log(res);
      setCategories(res.data)
    })
    .catch((err) => {
      console.log(err);
    })
   
     

  }, []);

 
  return loading  ? (
    <Spinner />
  ) : (
    <Fragment>
 
 
        {/* <Navbar /> */}
      
 
       
 


      <div className="mainForm">



  
 
<div className="login-title"></div>

<center>  
<div className='info-nav-main-frame'> 
<div className="info-nav">
<div className="DashBoxTitle" > {user.first_name && user.first_name} {user.last_name && user.last_name} مرحبا  </div>
{/* <p className='DashBoxTitleSmallInfo'> كل تجارة ناجحة تبدأ بمتجر مذهل! </p> */}

  
{moment(subscription.membership_renewal_expiry_date).isBefore(Date.now()) ?(
   <Fragment>
  
 <center> 
 
   <Link to='/membership/prices' className="Dash-button-end-subscription">
 البدء باختيار خطتك
</Link>
 
   </center>
 </Fragment>
):( 
  <Fragment>
{subscription.membership_class === 'golden' &&(
  <Fragment>
<center>
      <Link className="Dash-button-gold">     تنتهي يوم :<Moment format='YYYY/MM/DD'>{subscription.membership_renewal_expiry_date}</Moment>  </Link>
      <Link className="Dash-button-gold">  <i className="fa fa-user fa-0x"></i> عضوية ذهبية  </Link>
      {/* <Link className="Dash-button-gold">  {subscription.user.first_name && subscription.user.first_name}  { subscription.user.last_name && subscription.user.last_name} </Link> */}
</center>
</Fragment>
)} 




{subscription.membership_class === 'silver' &&(
  <Fragment>
<center>
      <Link className="Dash-button-silver">  تنتهي يوم : <Moment format='YYYY/MM/DD'>{subscription.membership_renewal_expiry_date}</Moment> </Link>
      <Link className="Dash-button-silver">  <i className="fa fa-user fa-0x"></i> عضوية فضية  </Link>
      {/* <Link className="Dash-button-silver">   {subscription.user.first_name && subscription.user.first_name}  { subscription.user.last_name && subscription.user.last_name}  </Link> */}
</center>
</Fragment>
)}


 


{subscription.membership_class === 'bronze' &&(
  <Fragment>
<center>
      <Link className="Dash-button-bronze">  تنتهي يوم : <Moment format='YYYY/MM/DD'>{subscription.membership_renewal_expiry_date}</Moment> </Link>
      <Link className="Dash-button-bronze">  <i className="fa fa-user fa-0x"></i> عضوية برونزية  </Link>
      {/* <Link className="Dash-button-bronze">   {subscription.user.first_name && subscription.user.first_name}  { subscription.user.last_name && subscription.user.last_name}  </Link> */}
</center>
</Fragment>
)}



{subscription.membership_class === 'special' &&(
  <Fragment>
<center>
      <Link className="Dash-button-special">   تنتهي يوم :<Moment format='YYYY/MM/DD'>{subscription.membership_renewal_expiry_date}</Moment> </Link>
      <Link className="Dash-button-special">  <i className="fa fa-user fa-0x"></i> عضوية خاصة  </Link>
      {/* <Link className="Dash-button-special">  {subscription.user.first_name && subscription.user.first_name}  { subscription.user.last_name && subscription.user.last_name}  </Link> */}

</center>
</Fragment>
)}

 

{subscription.membership_class === 'free' &&(
  <Fragment>
<center>
      <Link className="Dash-button-special">   تنتهي يوم : <Moment format='YYYY/MM/DD'>{subscription.membership_renewal_expiry_date}</Moment> </Link>
      <Link className="Dash-button-special ">  <i className="fa fa-user fa-0x"></i> عضوية مجانية  </Link>
      {/* <Link className="Dash-button-special ">  {subscription.user.first_name && subscription.user.first_name}  { subscription.user.last_name && subscription.user.last_name}  </Link> */}
</center>
</Fragment>
)} 

 
 
{subscription.membership_class === '' &&(
  <Fragment>
<center>
<Link to='/membership/prices' className="Dash-button-special ">  <i className="fa fa-rocket fa-0x"></i> اختر خطتك الآن للبدء  </Link>
{/* <Link className="Dash-button-special "> مرحبا {user.first_name && user.first_name}  { user.last_name && user.last_name}  </Link> */}
</center>
</Fragment>
)}  

 
</Fragment>
 
)}
    



</div>
</div>
</center>  
   
<center>  
{/* {userShop ?(
<Fragment> 
<Link to='//dashboard/myshops/edit' > <button className="Dash-button-open">   الاستايل ومعلومات المتجر   </button>  </Link>
<Link to={`/shops/${user.username}`} target="_blank" > <button className="Dash-button"> <i class="fa fa-external-link-square fa-0x" aria-hidden="true"></i> استعراض   </button>  </Link>
</Fragment>
  ):(
  <Link to='/dashboard/create-shop'> <button className="Dash-button">  فتح متجر  </button>  </Link>
 )} */}



<div className='info-nav-main-frame'> 


<div className="Dash-button">   <i class="fa fa-whatsapp fa-2x" aria-hidden="true"></i>  </div> 
<div className="Dash-button">   <i class="fa fa-phone fa-2x" aria-hidden="true"></i>  </div> 
<div className="Dash-button">   <i class="fa fa-envelope fa-2x" aria-hidden="true"></i>  </div> 
<div className="Dash-button">    للمساعدة </div> 

 
<div className="info-nav-warning">
<a className='warning'>  <i class="fa fa-info-circle" aria-hidden="true"></i>  المتجر حالياً بوضع الصيانة.   </a> 
<a href={`/shops/${user.username}`} target='_blank' className='danger'> 
<i class="fa fa-search" aria-hidden="true"></i> معاينة المتجر 
</a>
 </div>





        <div className="info-nav-topp">
        <div className="DashBoxTitle" > خطوات إعداد المتجر</div>
        <p className='DashBoxTitleSmallInfo'> متجرك بحاجة إلى إتمام هذه المهام الأساسية، أكمل إعدادها الآن .. سيتم حفظ مدخلاتك تلقائياً لتتمكن من العودة لها لاحقاً </p>
       </div>

 <div className="info-nav-warning">
<button className='steps'>  <i class="fa fa-square" aria-hidden="true"></i>  الخطوة 1  </button> 

<button className='stepopen'>اعداد الملف الشخصي</button> 

{user.country_code ?(
  <a className='checked'>
  <i class="fa fa-check-circle fa-2x" aria-hidden="true"></i> 
  </a> 
):(
  <Link to='/dashboard/MyProfile' className='checked'>
 الملف الشخصي
  </Link> 
)}


 </div>


 <div className="info-nav-warning">
<button className='steps'>  <i class="fa fa-square" aria-hidden="true"></i>  الخطوة 2  </button> 
<button className='stepopen'>اضافة معلومات  المتجر </button> 

{userShop.shop_logo_img ?(
  <a className='checked'>
  <i class="fa fa-check-circle fa-2x" aria-hidden="true"></i> 
  </a> 
):(
  <Link to='/dashboard/myshops/edit' className='checked'>
  معلومات المتجر
  </Link> 
)}

 </div>





 <div className="info-nav-warning">
<button className='steps'>  <i class="fa fa-square" aria-hidden="true"></i>  الخطوة 3  </button> 
<button className='stepopen'>اضافة تصنيفات المتجر </button> 

{categories.length > 0 ?(
  <a className='checked'>
  <i class="fa fa-check-circle fa-2x" aria-hidden="true"></i> 
  </a> 
):(
  <Link to='/dashboard/Systemcategories' className='checked'>
  تصنيفات المتجر
  </Link> 
)}

 </div>



 <div className="info-nav-warning">
<button className='steps'>  <i class="fa fa-square" aria-hidden="true"></i>  الخطوة 4  </button> 
<button className='stepopen'>اضافة المنتجات </button> 

 

  {userPosts.length > 0 ?(
  <a className='checked'>
   <i class="fa fa-check-circle fa-2x" aria-hidden="true"></i> 
  </a> 
):(
  <Link to='/dashboard/posts' className='checked'>
  اضف المنتجات
  </Link> 
)}

 </div>



 <div className="info-nav-warning">
<button className='steps'>  <i class="fa fa-square" aria-hidden="true"></i>  الخطوة 5  </button> 
<button className='stepopen'>تفعيل وسائل الدفع  </button> 

 
  {user.bank_swift ?(
  <a className='checked'>
  <i class="fa fa-check-circle fa-2x" aria-hidden="true"></i> 
  </a> 
):(
  <Link to='/dashboard/MyProfile' className='checked'>
   تفعيل الدفع
  </Link> 
)}
 
 </div>



 <div className="info-nav-warning">
<button className='steps'>  <i class="fa fa-square" aria-hidden="true"></i>  الخطوة 6  </button> 
<button className='stepopen'>اضافة الدومين الخاص (اختياري) </button> 

<button className='checked'>
  {/* <i class="fa fa-check-circle fa-2x" aria-hidden="true"></i>  */}
  اضافة دومين
  </button> 

 </div>
  



<div className="info-nav-warning">
<button className='steps'>  <i class="fa fa-square" aria-hidden="true"></i>  الخطوة 7  </button> 
<button className='stepopen'>شراء قالب  (اختياري) </button> 
<button className='checked'>
  {/* <i class="fa fa-check-circle fa-2x" aria-hidden="true"></i>  */}
  شراء قالب
  </button> 
  
 </div>




 <div className="info-nav-warning">
<button className='steps'>  <i class="fa fa-square" aria-hidden="true"></i>  الخطوة 8  </button> 
<button className='stepopen'>التوثيق (اجباري) </button> 

<button className='checked'>
  {/* <i class="fa fa-check-circle fa-2x" aria-hidden="true"></i>  */}
  توثيق المتجر
  </button> 

 </div>



 <div className="info-nav-warning">
<button className='steps'>  <i class="fa fa-square" aria-hidden="true"></i>  الخطوة 9  </button> 
<button className='stepopen'>اطلاق المتجر  <i class="fa fa-rocket" aria-hidden="true"></i> </button> 

<button className='checked'>
  {/* <i class="fa fa-check-circle fa-2x" aria-hidden="true"></i>  */}
  اطلاق المتجر
  </button> 

 </div>
  




 </div>
    
 
      {/* <div className="info-nav"> */}
      {/* <div className="DashBoxTitle" > </div> */}
      {/* <center> */}
      {/* <div className="Dash-button">  ({subscription.available_ads}): عدد الاعلانات المخصصة لهذا الاشتراك   <i class="fa fa-buysellads fa-2x" aria-hidden="true"></i>  </div> */}
      {/* <div className="Dash-button">  ({userPosts && userPosts.length}):  عدد المنتجات      <i class="fa fa-bullhorn fa-2x" aria-hidden="true"></i> </div> */}
      {/* <div className="Dash-button">  ({subscription.available_ads - userPosts.length }) :رصيد الاعلانات المتبقي لديك <i class="fa fa-buysellads fa-2x" aria-hidden="true"></i> </div> */}
      {/* <div className="Dash-button">  ({userRef && userRef.length}): عدد عضوية الإحالة <i class="fa fa-users fa-2x" aria-hidden="true"></i> </div> */}
      {/* <div className="Dash-button">  ({userPostsInToday.length}): عدد الإعلانات المنشورة اليوم <i class="fa fa-calendar-check-o fa-2x" aria-hidden="true"></i> </div> */}
      {/* <div className="Dash-button">  (0.00)  عدد الطلبات  </div> */}
      {/* <div className="Dash-button">  (0.00)  مجموع المبيعات  </div> */}

      {/* </center> */}

       {/* </div> */}


 









      </center>


 

    
   

    {/* <DashboardActionsArabic /> */}


 
    
  {/* //////////////////////////////////////Start of affiliate /////////////////////////////////// */}

  {/* //////////////////////////////////////End of affiliate /////////////////////////////////// */}

 
{/*       
      {profile === null ? (
        <Fragment>
          <DashboardActions />
           
          <Experience experience={profile.experience} />
          <Education education={profile.education} />

          <div className='my-2'>
            <button className='btn btn-danger' onClick={() => deleteAccount()}>
              <i className='fas fa-user-minus' /> Delete My Account
            </button>
          </div>
           
        </Fragment>
      ) : (
        <Fragment>
          <p></p>
          <div className="login-title">  لم تقم باعداد ملفك يرجى اضافة بعض المعلومات </div>

          <center>
          <Link to='/create-profile' className="Dash-button">  <i className="fa fa-user-plus fa-2x"></i> انشيء ملفك  </Link>
        </center>
        
  
          
        </Fragment>
      )} */}


      </div>
     
    </Fragment>

  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};
  
const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, deleteAccount }
)(Dashboard);
 

 