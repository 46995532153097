import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Footer from '../../components/layout/Footer';
import Navbar from '../../components/layout/Navbar';
import NavbarEnglish from '../../components/layout/NavbarEnglish';
import { useTranslation } from 'react-i18next';


import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";


import homepicAR from "./homepicAR.jpg"; 
import homepicAR1 from "./homepicAR1.png"; 
import homepicAR2 from "./homepicAR2.png"; 
import homepicAR3 from "./homepicAR33.png"; 
import homepicAR4 from "./homepicAR4.png"; 
import homepicAR5 from "./homepicAR5.jpg"; 
import homepicAR6 from "./homepicAR6.jpg"; 
import homepicAR7 from "./homepicAR7.jpg"; 
import homepicAR8 from "./homepicAR8.jpg"; 
import homepicAR9 from "./homepicAR9.png"; 

const HomePageWelcome = () => {
    const [t, i18next] = useTranslation()




    const settings = {
      className: "",
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 1000,
      pauseOnHover: true,
      rtl: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };


  return (

    <Fragment>
   
    <div className="aqle3-main">
    <div className="mainword2">   
    
     {i18next.language === 'ar'&&(<Navbar /> )}
     {i18next.language === 'en'&&(<NavbarEnglish /> )}
 
   <center>  
     <div className="homePageWrapper1" >  
      <h1>  فايز ادز.. بداية سريعة وسهلة   </h1>
      <p>
      أنشئ متجرك الإلكتروني في دقائق، واربط منتجاتك بمجموعة متكاملة من الحلول الرقميَّة الذكيَّة للمدفوعات، والشحن، وإدارة المخزون، والتسويق، بكل سهولة؛ لأن نجاحك لا يحتاج إلى تعقيد.
     </p>

    <Link to='/user/createAccount'> <button> أنشئ متجرك الآن </button>  </Link>

     <img src={homepicAR} />


<center> 
<h1>  أسهل وأكبر منصَّة  للتجارة الإلكترونيَّة    </h1>
<p>
أنشئ متجرك الإلكتروني، وانضمَّ لعشرات الآلاف من العلامات العالمية الناجحة مع فايز ادز اليوم
 </p>
</center>


<center><div className='statistics'>
  <div className='right'> 
  <div > 
  36,000   <i class="fa fa-plus" aria-hidden="true"></i>  </div>
   <div>
    متجر
   </div>
   </div>
 

  <div className='left'>  
  <div> 
  14,000$    <i class="fa fa-plus" aria-hidden="true"></i>  </div>
   <div>
    مبيعات
   </div>
   </div>


</div></center>

</div>
 </center>







 <center> 
 
<div className="homePageWrapper1" >  
<h1>  خطوات سهلة وبسيطة       </h1>
<p>
من خلال لوحة تحكم ستبدأ بالعمل فورا وسيكون بناء متجرك في غاية السهولة 
  </p>
 </div>
</center>

  

 <center> 
 <div className="homePageWrapper2" >  
    <div className='right'>  <h1> الخطوة الأولى  </h1>
    <p>
  اعداد ملفك الشخصي 
    </p>
    <ul>
      <li>قم باكمال جميع معلوماتك الشخصية </li>
      <li> ليتواصل فريق الدعم الفني معك    </li>
      
    </ul>
    </div>
    <div className='left'> <img src={homepicAR1} /> </div>
 </div>
 </center> 







 <center> 
 <div className="homePageWrapper2" >  
    <div className='right'>  <h1>  الخطوة الثانية  </h1>
    <p>
 اضافة معلومات متجرك
    </p>
    <ul>
      <li>  في هذه الخطوة ستضيف كل ما يتعلق بالمتجر من شعار وهوية ووصف للمتجر  وبيانات التواصل </li>
      <li> هذه الخطوة مهمة ليكتمل متجرك ولتواصل العملاء مع مشروعك  </li>
      <li>   </li>
   
   
    </ul>
    </div>
    <div className='left'> <img src={homepicAR2} /> </div>
 </div>
 </center> 






 




 <center> 
 <div className="homePageWrapper2" >  
    <div className='right'>  <h1>  الخطوة الثالثة  </h1>
    <p>
    اضافة تصنيفات المتجر   
      </p>

    <ul>
      <li>  من خلال لوحة التحكم يمكنك اضافة التصنيفات الرئيسية لمتجرك </li>
      <li>  صنف المنتجات  حسب أنواعها </li>
      <li> يمكن اضافة تصنيفات جديدة أو حذفها أوتعديلها</li>
      <li> يمكنك اضافة عدد لامحدود من التصنيفات الخاصة بمنتجاتك</li>
   
    </ul>
    </div>
    <div className='left'> <img src={homepicAR3} /> </div>
 </div>
 </center> 





 <center> 
 <div className="homePageWrapper2" >  
    <div className='right'>  <h1>   الخطوة الرابعة   </h1>
    <p>
    اضافة المنتجات
     </p>
    <ul>
      <li>  ادارة المنتجات من خلال لوحة التحكم بكل سهولة   </li>
      <li> ادخال منتجات في التصنيفات الخاصة بالمنتج </li>
      <li> بإمكانك ادخال المنتجات وادخال تفاصيل  كالون والحجم </li>
      <li> بامكانك اضافة صور توضح حجم المنتج أو صور توضح لون المنتج </li>
      <li> بامكانك ان تضيف عدد من الصور لمنتجك لعرض المنتجات بوضوح ولتغطية جميع الجوانب الهامة والمميزات الخاصة بالمنتج </li>
     </ul>
    </div>
    <div className='left'> <img src={homepicAR4}/> </div>
 </div></center>






 

 <center> 
 <div className="homePageWrapper2" >  

    <div className='right'>  <h1>   الخطوة الخامسة    </h1>
    <p>
    تفعيل وسائل الدفع

      </p>
    <ul>
       <li>فعل مدفوعاتك الالكترونية بضغطة زر </li>
      <li>  مع نظام فائز ادز يمكن لعملائك الدفع بوسائل متعددة    </li>
      <li> متجرك سيقبل وسائل دفع من جميع انحاء العالم </li>
     
    </ul>
    </div>


    <div className='left'> <img  src={homepicAR5} /> </div>


 </div></center>



















 <center> 
 <div className="homePageWrapper2" >  
    <div className='right'>  <h1>   الخطوة السادسة     </h1>
    <p>
    شراء قالب 
      </p>
    <ul>
      
      <li> لدينا عدد من القوالب المختلفة والتي صمّمت لتبهر عملائك  وتناسب جميع أنواع المتاجر   </li>
      <li> نمو مبيعاتك يبدأ بتجربة تسوق سهلة وفريدة </li>
      <li> بعد الدخول الى لوحة التحكم بإمكانك الدخول على انواع القوالب واختيار ما يناسب متجرك من القوالب المخصصة </li>
    </ul>
    </div>

    <div className='left'> <img src={homepicAR6} /> </div>


 </div></center>






 <center> 
 <div className="homePageWrapper2" >  
    <div className='right'>  <h1>  الخطوة السابعة  </h1>
    <p>
   اضافة الدومين الخاص بك
      </p>
      <ul>
       <li> يمكنك الحصول على دومين خاص </li>
      <li> إذا كان لديك دومين من مزود خدمة آخر يمكنك ربطه بفائز ادز وستبقى إدارة الدومين وتجديد الإشتراك من خلال مزود الخدمة</li>
      <li> إذا كان لديك دومين من مزود خدمة آخر يمكنك نقله إلى فائز ادز وبالتالي ستتمكن من إدارة الدومين وتجديد الإشتراك من خلال فائز ادز</li>
    </ul>
    </div>

    <div className='left'> <img src={homepicAR7}/> </div>


 </div></center>







 <center> 
 <div className="homePageWrapper2" >  
    <div className='right'>  <h1>  الخطوة الثامنة  </h1>
    <p>
      اطلاق المتجر
       </p>
       <ul>

       <li>  
       كن متاح لعرض منتجاتك أمام العملاء والمستهلكين على مدار الساعة طوال أيام الأسبوع .
      </li>


      <li>  
      كن متواجد في أي مكان في العالم دون الحاجة إلى فتح مكتب رئيسي لشركتك أو متاجرك.

      </li>



      <li>  
      بيع منتجاتك في العديد من البلدان حول العالم وانشر علامتك التجارية الخاصة
       </li>


    


     </ul>
    </div>

    <div className='left'> <img src={homepicAR8} /> </div>


 </div></center>


  













     <center>  
     <div className="homePageWrapper3" >  
     <div className='right'>
      <h1>  امتلك متجراً احترافياً في منصة فائز ادز    </h1>
      
      <p>
      أنشئ متجرك الآن بأدوات مرنة وحلول متكاملة تساعدك في كل خطوة نحو نمو مبيعاتك وتسويق منتجاتك
      </p>
      </div>

      <div className='left'>
      <Link to='/user/createAccount'> <button> أنشئ متجرك الآن </button>  </Link>
     </div>

     </div>
     </center>









 
{/* ////////////////////////////////////////Slider Start////////////////////////////////////////////////////// */}
<center>  
<div className='homePageWrapper4'>
  {/* <title> شركاء النجاح </title> */}
  
  <div className='right'  >  <h1>  شركاء النجاح  </h1>
    
    </div>

    <div className='left' style={{borderTopLeftRadius:'10px',borderBottomLeftRadius:'10px'}} > 
  <Slider {...settings}>
 
 
 
      <Link > 
       <img  src={homepicAR9}
     
      /> 
         </Link>
    


   
      <Link>  
      <img src='https://res.cloudinary.com/momad191/image/upload/v1662450105/first_magazine/vivqlkskod6pzdszeyvy.png' 
    
      />
       </Link>
 



  
       <Link>  
       <img src='https://res.cloudinary.com/momad191/image/upload/v1689331948/first_magazine/swjlioxjprnkbnm8w5rv.jpg' 
       
       /> 
       </Link>
    



  
     <Link>  
       <img src='https://res.cloudinary.com/momad191/image/upload/v1662378223/first_magazine/vjdocsw1fx0hpnxzvn6a.png' 
      
       /> 
       </Link>
  



     
      <Link>  
       <img src='https://res.cloudinary.com/momad191/image/upload/v1662454876/first_magazine/bcaqlmiuaiixfj3ijhze.jpg' 
        
       /> 
       </Link>
 


   



      <Link>  
       <img src='https://res.cloudinary.com/momad191/image/upload/v1663929953/first_magazine/najd9pclruyoqxzmzlfi.jpg'  
      
       /> 
       </Link>
  


       <Link>  
       <img src='https://res.cloudinary.com/momad191/image/upload/v1662452570/first_magazine/rrg55seack8jf2su1dyd.png'  
      
       /> 
       </Link>

 


    </Slider>
    </div>

</div>
</center>  
{/* ////////////////////////////////////////Slider end////////////////////////////////////////////////////// */}






<Footer />

     </div>
     </div>

     </Fragment>
  )
}

export default HomePageWelcome