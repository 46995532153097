import React, { Fragment, useEffect ,useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; 
import Spinner from '../layout/Spinner'; 
import DashboardActionsArabic from './DashboardActionsArabic'; 
import Experience from './Experience';
import Education from './Education';
import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import RequestForm from '../requests/RequestForm';      
import moment from 'moment';
import Moment from 'react-moment';  
// import 'moment/locale/ar';
import { useTranslation } from 'react-i18next';
import Navbar from '../layout/Navbar';
import NavbarEnglish from '../layout/NavbarEnglish';

function OrdersResults() {

  const [t, i18next] = useTranslation()
  moment.locale('ar');
  
  // const [oneShop,setOneShop]= useState([])
  const [userShop,setuserShop]= useState([])

  const [subscription,setSubscription]= useState([])
  const [user,setUser]= useState([])
  const [userPosts,setUserPosts]= useState([])
  // const [userPostsInToday,setUserPostsInToday]= useState([])
  
 
  const [userRef,setUserRef]= useState([])
  const [visible,setVisible]= useState(10)
  const [RefToPay,setRefToPay]= useState([])


  const RefToPayResults = RefToPay.slice(0,visible);


  const loadMore = async e => {
    setVisible(visible+10)

}

  // const [OndemandAmount,setOndemandAmount]= useState([])
  const [visibleAllRequest,setVisibleAllRequest]= useState(10)
  const [allRequest,setAllRequest]= useState([])

  const allRequestResults = allRequest.slice(0,visibleAllRequest);

  const loadMoreRequests = async e => {
    setVisibleAllRequest(visibleAllRequest+10)

}

 
  const [ProfitCompleteRequest,setProfitCompleteRequest]= useState([])
  const [CheckInRequest,setCheckInRequest]= useState([])


  const [lastrequest,setLastRequest]= useState([])

  let totalpaidamounts =0;
  ProfitCompleteRequest.forEach(p=>{
    totalpaidamounts += p.amount;
    })

 
  let totalRequestedToPay =0;
  
  allRequest.forEach(p=>{
    totalRequestedToPay += p.amount;
    
    })

    
  const profit = (RefToPay.length*4) - (totalRequestedToPay);
 
    




  useEffect(() => {

    axios.get('/api/shops/usershop')
    .then(res => {
      console.log(res);
      setuserShop(res.data)
    })
    .catch((err) => {
      console.log(err);
    })
 

    axios.get('/api/subscriptions/lastsubscription')
    .then(res => {
      console.log(res);
      setSubscription(res.data)
    })
    .catch((err) => {
      console.log(err);
    })


    axios.get('/api/auth')
    .then(res => {
      console.log(res);
      setUser(res.data)
    })
    .catch((err) => {
      console.log(err);
    }) 

 
 
    axios.get('/api/posts/userposts')
    .then(res => {
      //console.log(res);
      setUserPosts(res.data)
    })
    .catch((err) => {
      console.log(err);
    })

 
    // axios.get('/api/posts/userPostsInToday')
    // .then(res => {
    //   //console.log(res);
    //   setUserPostsInToday(res.data)
    // })
    // .catch((err) => {
    //   console.log(err);
    // })

 
    axios.get('/api/auth/ref')
    .then(res => {
      //console.log(res);
      setUserRef(res.data)
    })
    .catch((err) => {
      console.log(err);
    })

  

    axios.get('/api/subscriptions/refToPayaffiliate')
    .then(res => {
      //console.log(res);
      setRefToPay(res.data)
    })
    .catch((err) => {
      console.log(err);
    })



    // axios.get('/api/auth/OndemandAmount')
    // .then(res => {
    //   //console.log(res);
    //   setOndemandAmount(res.data)
    // })
    // .catch((err) => {
    //   console.log(err);
    // })

    

    axios.get('/api/profitrequests/allrequests')
    .then(res => {
      //console.log(res);
      setAllRequest(res.data)
    })
    .catch((err) => {
      console.log(err);
    })

 
  
    axios.get('/api/profitrequests/complete')
    .then(res => {
      //console.log(res);
      setProfitCompleteRequest(res.data)
    })
    .catch((err) => {
      console.log(err);
    })



    axios.get('/api/profitrequests/checkin')
    .then(res => {
      //console.log(res);
      setCheckInRequest(res.data)
    })
    .catch((err) => {
      console.log(err);
    })




    axios.get('/api/profitrequests/lastrequest')
    .then(res => {
   
      setLastRequest(res.data)
    })
    .catch((err) => {
      console.log(err);
    })




  }, []);




  return (

  
      <Fragment>


{i18next.language === 'ar'&&( <Fragment>  <Navbar /></Fragment>)}
{i18next.language === 'en'&&(<Fragment><NavbarEnglish /></Fragment> )}

 
{i18next.language === 'ar'&&(
  <Fragment>
     
        <center> 
        <div className="affiliate-nav">
   
       <center> <div className="affiliateTitle" >  قائمة الطلبات </div>   </center>
   
     <table id="myTable">
      <tr>
      <th >استعراض الطلب </th>
      <th >حالة الدفع</th>
      <th >حالة التوصيل</th>
      <th >المبلغ</th>
      <th>تاريخ  الطلب </th>
    </tr>
    {allRequestResults
     .map(post => (
         <Fragment>
    <tr key={post._id}>

    <td className='membersList'> <button> تفاصيل</button>  </td>

    <td className='membersList'>{post.payment_status} </td>
 
    <td className='membersList'> 
    {post.delivery_status}
    
    </td>
      <td className='membersList'>${post.total}</td>
      <td className='membersList'><Moment format='YYYY/MM/DD'>{post.date}</Moment>    </td>
    
    </tr>
        </Fragment>
          ))}
  
  
  </table>
  
  
  
   
     
   
  {visibleAllRequest < allRequest.length && (
      <center> 
    <button   onClick={loadMoreRequests} 
        className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> المزيد  </button> 
         </center>
    )}
  
  
  
          </div>
          </center>
  
  
  
  {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
  

  
          </Fragment>
  )}
        


{i18next.language === 'en'&&(
  <Fragment>
 
 
  

      <center> 
      <div className="affiliate-nav">
 
     <center> <div className="affiliateTitle" >  List of orders   </div>   </center>

   <table id="myTable">
  <tr>
    <th>Date </th>
    <th >The amount</th>
    <th >payment status</th>
    <th >delivery status</th>
    <th >details</th>
  </tr>
 
  
  {allRequestResults
   .map(post => (
       <Fragment>
  <tr key={post._id}>
  <td className='membersList'><Moment format='YYYY/MM/DD'>{post.date}</Moment>    </td>
  <td className='membersList'>${post.total}</td>
  <td className='membersList'> {post.payment_status}</td>

<td className='membersList'>{post.delivery_status} </td>
<td className='membersList'> <button> edit</button>  </td>

  
  </tr>
      </Fragment>
        ))}


</table>



 
   
 
{visibleAllRequest < allRequest.length && (
    <center> 
  <button   onClick={loadMoreRequests} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> More  </button> 
       </center>
  )}



        </div>
        </center>



 
  </Fragment>
)}

</Fragment>
  )
}

  
OrdersResults.propTypes = {
  // getCurrentProfile: PropTypes.func.isRequired,
  // deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  // profile: PropTypes.object.isRequired
};
   
const mapStateToProps = state => ({
   auth: state.auth,
   profile: state.profile
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, deleteAccount }
)(OrdersResults);
