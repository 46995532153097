import React, { Fragment, useEffect ,useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import Moment from 'react-moment';
      
import { addLike, removeLike, deletePost, addFiveStars , addFourStars, addThreeStars, addTwoStars, addOneStars } from '../../actions/post';

import ConfirmButton from "./ConfirmButton";
import axios from 'axios';

import greenFace from './greenFace.png';
import noimg from './noimg.png';
 

import redFace from './redFace.png';
import yallowFace from './yallowFace.png';
import Navbar from '../../components/layout/Navbar';
 
 
const noneDisplayI = 'noneDisplayI';
const displayI = 'displayI';
      
  
const PostsEnglish = ({loading ,match, addLike,auth,five_stars,
  removeLike, 
  deletePost,
  addFiveStars, addFourStars, addThreeStars, addTwoStars,addOneStars,
}) => {
 
   
  const [user,setUser]= useState([])
  // const [getfivestarsuser,setgetfivestarsuser]= useState([])

    const [getPosts,setPosts]= useState([])
    const [get5sum,setget5sum]= useState([])
    const [get4sum,setget4sum]= useState([])
    const [get3sum,setget3sum]= useState([])


    const [activationComplete,setgetactivationComplete]= useState([])
    const [activationNo,setgetactivationNo]= useState([])
    const [premiumComplete,setgetpremiumComplete]= useState([])


    
    
    
    // const [getAuth,setAuth]= useState([])
    // const [getVote,setVoting]= useState([])

    const [visible,setVisible]= useState(10)
    const [showLike,setShowLike]= useState(true)
    // const [search,setSearch]= useState('')

    const currentResults = getPosts.slice(0,visible);
    const currentactivationComplete = activationComplete.slice(0,visible);
    const currentactivationNo = activationNo.slice(0,visible);
    const currentPremiumComplete = premiumComplete.slice(0,visible);

    
 

    //  const searchChanged = async e =>
    //  setSearch({ search: e.target.value });

    
    const [showModal ,setShowModal]= useState(true)
    const [hideModal  ,setHideModal]= useState(true)


    

    const loadMore = async e => {
        setVisible(visible+10)

    }



    const [optionAll, setmyoptionAll] = useState(displayI);
    const optionoptionAll1 = async e => {
      setmyoptionAll(displayI);

      setmyoptionPremium(noneDisplayI);
      setmyoptionActive(noneDisplayI);
      setmyoptionActiveWait(noneDisplayI);
    }



    const [optionPremium, setmyoptionPremium] = useState(noneDisplayI);
    const optionPremium1 = async e => {
      setmyoptionPremium(displayI);

      setmyoptionAll(noneDisplayI);
      setmyoptionActive(noneDisplayI);
      setmyoptionActiveWait(noneDisplayI);
    }



    const [optionActive, setmyoptionActive] = useState(noneDisplayI);
    const optionActive1 = async e => {
      setmyoptionActive(displayI);

      setmyoptionAll(noneDisplayI);
      setmyoptionPremium(noneDisplayI);
      setmyoptionActiveWait(noneDisplayI);
    }


    const [optionActiveWait, setmyoptionActiveWait] = useState(noneDisplayI);
    const optionActiveWait1 = async e => {
      setmyoptionActiveWait(displayI);

      setmyoptionAll(noneDisplayI);
      setmyoptionPremium(noneDisplayI);
      setmyoptionActive(noneDisplayI);
    }

 

    useEffect(() => {
      
        axios.get('/api/posts/userposts')
        .then(res => {
          //console.log(res);
          setPosts(res.data)
        })
        .catch((err) => {
          console.log(err);
        })



        axios.get('/api/posts/get5sumUser')
        .then(res => {
          //console.log(res);
          setget5sum(res.data)
        })
        .catch((err) => {
          console.log(err);
        })



        axios.get('/api/posts/get4sumUser')
        .then(res => {
          //console.log(res);
          setget4sum(res.data)
        })
        .catch((err) => {
          console.log(err);
        })



        axios.get('/api/posts/get3sumUser')
        .then(res => {
          //console.log(res);
          setget3sum(res.data)
        })
        .catch((err) => {
          console.log(err);
        })




        axios.get('/api/posts/activationComplete')
        .then(res => {
          //console.log(res);
          setgetactivationComplete(res.data)
        })
        .catch((err) => {
          console.log(err);
        })



        axios.get('/api/posts/activationNo')
        .then(res => {
          //console.log(res);
          setgetactivationNo(res.data)
        })
        .catch((err) => {
          console.log(err);
        })




        axios.get('/api/posts/premiumComplete')
        .then(res => {
          //console.log(res);
          setgetpremiumComplete(res.data)
        })
        .catch((err) => {
          console.log(err);
        })

 
  
     

 

        // axios.get('/api/posts/getfivestarsuser')
        // .then(res => {
        //   //console.log(res);
        //   setgetfivestarsuser(res.data)
        // })
        // .catch((err) => {
        //   console.log(err);
        // })

 

        // axios.get('/api/votings')
        // .then(res => {
        //   //console.log(res);
        //   setVoting(res.data)
        // })
        // .catch((err) => {
        //   console.log(err);
        // })




        // axios.get('/api/auth')
        // .then(res => {
        //   //console.log(res);
        //   setAuth(res.data)
        // })
        // .catch((err) => {
        //   console.log(err);
        // })


        axios.get('/api/auth')
        .then(res => {
          console.log(res);
          setUser(res.data)
        }) 
        .catch((err) => {
          console.log(err);
        })



      },[getPosts]);




     
     const showModal1 = async e => {
      setShowModal(true)
      };

     
        const hideModal1 = async e => {
        setHideModal(false)
      };

     
      return loading ? (
        <center> 
        <div className="main-list" >
           <Spinner/> 
           </div>
           </center>
      ) : (
        <div>
        
        {/* <Navbar /> */}

          
    
     
        <div className="mainword-section-right">
        <div className="mainForm">
  

      <center>
        {/* <div className="dash-title"> Your Products </div> */}
        {/* <Link to="/dashboard/posts/AddPremiumPost" className="Action-button-plus">  <i className="fa fa-star fa-1x"></i> أضف إعلان مميز </Link> */}
        {/* <Link to="/dashboard/posts/searchPost" className="Action-button-add-ads">  <i className="fa fa-search fa-1x"></i> search </Link> */}
       
        {/* {user.validity === "admin"|| user.validity === "super" &&(
          <>
        <Link to="/dashboard/posts/AddSpecialPost" className="Action-button-add-ads">  <i className="fa fa-plus fa-1x"></i> add special post #  </Link>
   
          </>
        )} */}

        </center>


      <center>
      <div className='statistics'> 
      <center>
      <Link to="/dashboard/posts/AddSpecialPostsystem" className="button-statistics">  <i className="fa fa-plus fa-1x"></i> Add the products </Link>

      <div className="button-statistics" onClick={optionoptionAll1}> All the products: <span>{ getPosts.length} </span> <i class="fa fa-filter" aria-hidden="true"></i> </div></center>
      </div>
      </center>

      <center>
      <div className='statistics'> 
      {/* <center> <div className="button-statistics1" onClick={optionActive1}>   <i className="fa fa-check fa-1x"></i>  Activated  :{activationComplete.length}   <i class="fa fa-filter" aria-hidden="true"></i> </div></center> */}
      {/* <center> <div className="button-statistics2" onClick={optionActiveWait1}>  <i className="fa fa-circle-o-notch fa-1x"></i>  Waiting for activation:{activationNo.length}   <i class="fa fa-filter" aria-hidden="true"></i> </div></center> */}
      {/* <center> <div className="button-statistics3" onClick={optionPremium1}>   <i className="fa fa-star fa-1x"></i>   عدد الاعلانات المميزة  :{premiumComplete.length}  </div></center> */}

      </div>
      </center>



{ getPosts.length < 1 &&
<Fragment> 
 
<center> 
<div className="main-list" >
   <Spinner/> 
   </div>
   </center>

{/* <div className="dash-title"> Start publishing your services or products </div> */}

</Fragment>
}



{/* //start All   ----------------------------------*/}     
     
<center>
    <div className={optionAll}> 
 
 {currentResults
   
  

 .map(post => (
     <Fragment>
         
        
       
<center>
    <div className="main-list" key={post._id}>

    

      {post.premium === 'no' &&(
        <div className='title-in-list'>
        <a className='title-in-list'  target="_blank" href={`/posts/${post._id}#/${post.purpose_code}/${post.title}`}  >    
         {post.title}  
         </a>
        </div>
      )}
 

        {post.premium === 'yes' &&(
        <div className='title-in-list-premium'>
        <a className='title-in-list-premium'  target="_blank" href={`/posts/${post._id}#/${post.purpose_code}/${post.title}`}  >    
         {post.title}  
         </a>
        </div>
      )}


  <div className='section-list-english'>
    <div>
    {post.image ? (
      <Link   to={`/posts/${post._id}#/${post.purpose_code}/${post.title}`} style={{color:'#fff',textDecoration:'none'}} > <img className='image-in-list' src={post.image}  /></Link>
      ):(
  
        <Link   to={`/posts/${post._id}#/${post.purpose_code}/${post.title}`} style={{color:'#fff',textDecoration:'none'}} > <img className='image-in-list' src={noimg}  /></Link>
      )}
</div>

   
     
      <div>

        
   
 
          
      <p className="list-details-english"> 
      {/* <span className="redColor"> User :</span>{post.user.username} | */}
      <span className="redColor">Category :</span>{post.category.c_EN_name} |
      <span className="redColor">Market :</span>{post.market.m_EN_name}|
      <span className="redColor">shop :</span>{post.user.shop_name ? post.user.shop_name:<>
      <a target="_blank" href={`/shops/${post.user.username}`}  >  Visit the advertiser's store </a>
    </> }
       </p>
     {/* <p className="list-details"> {post.Main_paragraph} </p> */}
 


<p className="list-button">

{/* {post.premium === 'no' &&(
  <button className="Action-button-status">   Normal     <i className="fa fa-circle-o-notch fa-1x"></i></button> 
)} */}

 
{/* {post.premium === 'yes' &&(
  <button className="Action-button-status-premium">   special     <i className="fa fa-star fa-1x"></i></button> 
)} */}




      {/* {post.activation === 'no' && (
       <button className="Action-button-status">   Waiting for activation     <i className="fa fa-circle-o-notch fa-1x"></i></button> 
      )} */}
      
      {/* {post.activation === 'yes' && (
      <button className="Action-button-status-active">   Activated     <i className="fa fa-check fa-1x"></i></button> 
      )} */}

   

   {/* <button className="Action-button-status">   Posted on <Moment format='YYYY/MM/DD'>{post.date}</Moment>  <i className="fa fa-calendar fa-1x"></i></button>  */}

   {/* <button className="Action-button-status">  {post.price}   <i className="fa fa-dollar fa-1x"></i></button>  */}



 {/* {!auth.loading && post.user === auth.user._id && ( */}
<Fragment> 
 <Link to={`/posts/${post._id}#/${post.purpose_code}/${post.title}`}  style={{textDecoration:'none'}} > <button  className="Action-button-status" >  Edit post <i className="fa fa-edit fa-1x"></i></button>   </Link> 

{/* <Link to={`/dashboard/posts/editPostActivate/${post._id}`}   style={{textDecoration:'none'}} > <button  className="Action-button-status" >  تفعيل الإعلان <i className="fa fa-edit fa-1x"></i></button>   </Link>   */}

{/* <Link   style={{textDecoration:'none'}} > <button  className="Action-button-status" >  {post.clicks.length} <i className="fa fa-eye fa-1x"></i></button>   </Link>  */}

 
            <ConfirmButton
            dialog={[" ", "Are you sure ?", "Again for deletion"]}
            action={() => deletePost(post._id)}
              />

  </Fragment>
 

      </p>


 

{/*   
<div className='' style={{display:'flex',alignContent:'center', width:'40%'}}>




<button className='Action-button-update' >
<img  src={greenFace} />
{post.five_stars.length} 
</button>






<button className='Action-button-update' >
<img  src={yallowFace} />
<span>{post.four_stars.length} </span>
</button>


  


<button className='Action-button-update'>
<img  src={redFace} />
<span>{post.three_stars.length}</span>
</button>
</div> */}


         


        

 


      </div> 

      </div>
  </div>

  </center>

     </Fragment>
      ))}









  {/* onClick={() => loadMore()} */}
  {visible < getPosts.length && (
    <center> 
  <button   onClick={loadMore} 
      className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> More  </button> 
       </center>
  )}

        </div>
        </center>
  {/* //End All   ----------------------------------*/}     



  </div>






     {/* //Active  ----------------------------------*/}
 <center>
    <div className={optionActive}> 

   
   


    {currentactivationComplete
   
  

   .map(post => (
       <Fragment>
           
           
        
  <center>
      <div className="main-list" key={post._id}>
  
      {post.premium === 'no' &&(
        <div className='title-in-list'>
        <a className='title-in-list'  target="_blank" href={`/posts/${post._id}#/${post.purpose_code}/${post.title}`}  >    
         {post.title}  
         </a>
        </div>
      )}


        {post.premium === 'yes' &&(
        <div className='title-in-list-premium'>
        <a className='title-in-list-premium'  target="_blank" href={`/posts/${post._id}#/${post.purpose_code}/${post.title}`}  >    
         {post.title}  
         </a>
        </div>
      )}
  
    <div className='section-list-english'>
      <div>
      {post.image ? (
        <a target="_blank" href={`/posts/${post._id}#/${post.title}`} style={{color:'#fff',textDecoration:'none'}} > <img className='image-in-list' src={post.image}  /></a>
        ):(
    
          <a target="_blank" href={`/posts/${post._id}#/${post.title}`} style={{color:'#fff',textDecoration:'none'}} > <img className='image-in-list' src={noimg}  /></a>
        )}
  </div>
  
    
       
        <div>
  
          
  
   
            
        <p className="list-details-english"> <span className="redColor"> User :</span>{post.user.username} | <span className="redColor">Category :</span>{post.category.c_EN_name} | <span className="redColor">Market :</span>{post.market.m_EN_name}

        | <span className="redColor">shop :</span>{post.user.shop_name ? post.user.shop_name:<>
      
      <a target="_blank" href={`/shops/${post.user.username}`}  >  Visit the advertiser's store </a>
    </> }

       </p>    
       <p className="list-details"> {post.Main_paragraph} </p>   
  
   
  
  
  
        <p className="list-button">
  
        {post.activation === 'no' &&(
  
        <button className="Action-button-status">  Waiting for activation     <i className="fa fa-circle-o-notch fa-1x"></i></button> 
    
        )}

       {post.activation === 'yes' && (
  
       <button className="Action-button-status-active">   Activated     <i className="fa fa-check fa-1x"></i></button> 
  
        )}
  
  
  
  {post.premium === 'no' &&(
  
  <button className="Action-button-status">   Normal     <i className="fa fa-circle-o-notch fa-1x"></i></button> 
  
  )}
  
  
  {post.premium === 'yes' &&(
  
  <button className="Action-button-status-premium">   Special     <i className="fa fa-star fa-1x"></i></button> 
  
  )}
  
  
   <button className="Action-button-status">   Posted on  <Moment format='YYYY/MM/DD'>{post.date}</Moment>  <i className="fa fa-calendar fa-1x"></i></button> 
  
  
  

  
  
  
  
   {/* {!auth.loading && post.user === auth.user._id && ( */}
  <Fragment> 
  
  <Link to={`/dashboard/posts/editPost/${post._id}`}   style={{textDecoration:'none'}} > <button  className="Action-button-status" >  Edit post <i className="fa fa-edit fa-1x"></i></button>   </Link> 
  

   

   
              <ConfirmButton
              dialog={[" ", "are you sure ?", "again for deletion"]}
              action={() => deletePost(post._id)}
                />
  
  
  
  
    </Fragment>

  </p>
  

  
  
        </div> 
  
        </div>
    </div>
  
    </center>
  
       </Fragment>
        ))}
  
  
  
  
  
  
  
  
  
    {/* onClick={() => loadMore()} */}
    {visible < activationComplete.length && (
      <center> 
    <button   onClick={loadMore} 
        className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> More  </button> 
         </center>
    )}

 
    </div>
    </center>




  {/* //Active waiting  ----------------------------------*/}
 <center>
    <div className={optionActiveWait}> 
  

    {currentactivationNo
   
  

   .map(post => (
       <Fragment>
           
          
        
  <center>
      <div className="main-list" key={post._id}>
  
      {post.premium === 'no' &&(
        <div className='title-in-list'>
        <a className='title-in-list'  target="_blank" href={`/posts/${post._id}#/${post.purpose_code}/${post.title}`}  >    
         {post.title}  
         </a>
        </div>
      )}


        {post.premium === 'yes' &&(
        <div className='title-in-list-premium'>
        <a className='title-in-list-premium'  target="_blank" href={`/posts/${post._id}#/${post.purpose_code}/${post.title}`}  >    
         {post.title}  
         </a>
        </div>
      )}

  
    <div className='section-list-english'>
      <div>
      {post.image ? (
        <a target="_blank" href={`/posts/${post._id}#/${post.purpose_code}/${post.title}`} style={{color:'#fff',textDecoration:'none'}} > <img className='image-in-list' src={post.image}  /></a>
        ):(
    
          <a target="_blank" href={`/posts/${post._id}#/${post.purpose_code}/${post.title}`} style={{color:'#fff',textDecoration:'none'}} > <img className='image-in-list' src={noimg}  /></a>
        )}
  </div>
  
    
       
        <div>
  
          
  
   
            
        <p className="list-details"> <span className="redColor">the user :</span>{post.user.username} | <span className="redColor">Category :</span>{post.category.c_AR_name} | <span className="redColor">market :</span>{post.market.m_AR_name}
      
        | <span className="redColor">shop :</span>{post.user.shop_name ? post.user.shop_name:<>
      
      <a target="_blank" href={`/shops/${post.user.username}`}  >  Visit the advertiser's store </a>
    </> }
       </p>  
       <p className="list-details"> {post.Main_paragraph} </p>     
  

      
  
        <p className="list-button">
  
        {post.activation === 'no' && (
  
         <button className="Action-button-status">   Waiting for activation     <i className="fa fa-circle-o-notch fa-1x"></i></button> 
    
        )}

        {post.activation === 'yes' && (
  
         <button className="Action-button-status-active">   Activated     <i className="fa fa-check fa-1x"></i></button> 
  
        )}
  
  
  
  {post.premium === 'no' &&(
  
  <button className="Action-button-status">   Normal     <i className="fa fa-circle-o-notch fa-1x"></i></button> 
  
  )}
  
  
  {post.premium === 'yes' &&(
  
  <button className="Action-button-status-premium">   Special     <i className="fa fa-star fa-1x"></i></button> 
  
  )}
  
  
    <button className="Action-button-status">   Posted on <Moment format='YYYY/MM/DD'>{post.date}</Moment>  <i className="fa fa-calendar fa-1x"></i></button>

  
  
   {/* {!auth.loading && post.user === auth.user._id && ( */}
  <Fragment> 
  
  <Link to={`/dashboard/posts/editPost/${post._id}`}   style={{textDecoration:'none'}} > <button  className="Action-button-status" >  Edit post <i className="fa fa-edit fa-1x"></i></button>   </Link> 
  
   <Link to={`/dashboard/posts/editPostActivate/${post._id}`}   style={{textDecoration:'none'}} > <button  className="Action-button-status" >  Activation <i className="fa fa-edit fa-1x"></i></button>   </Link> 

   
              <ConfirmButton
              dialog={[" ", "are you sure ?", "again for deletion"]}
              action={() => deletePost(post._id)}
                />
  
  
  
  
    </Fragment>

        </p>

  
        </div> 
  
        </div>
    </div>
  
    </center>
  
       </Fragment>
        ))}
  
  
  
  
  
  
  
  
  
    {/* onClick={() => loadMore()} */}
    {visible < activationNo.length && (
      <center> 
    <button   onClick={loadMore} 
        className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> More  </button> 
         </center>
    )}




 
    </div>
    </center>


{/* //premium  ----------------------------------*/}
    <center>
    <div className={optionPremium}> 
    
       



    {currentPremiumComplete
   
  

   .map(post => (
       <Fragment>
           
          
        
  <center>
      <div className="main-list" key={post._id}>
  
      {post.premium === 'no' &&(
        <div className='title-in-list'>
        <a className='title-in-list'  target="_blank" href={`/posts/${post._id}#/${post.purpose_code}/${post.title}`}  >    
         {post.title}  
         </a>
        </div>
      )}


        {post.premium === 'yes' &&(
        <div className='title-in-list-premium'>
        <a className='title-in-list-premium'  target="_blank" href={`/posts/${post._id}#/${post.purpose_code}/${post.title}`}  >    
         {post.title}  
         </a>
        </div>
      )}
   
    <div className='section-list-english'>
      <div>
      {post.image ? (
        <a target="_blank" href={`/posts/${post._id}#/${post.purpose_code}/${post.title}`} style={{color:'#fff',textDecoration:'none'}} > <img className='image-in-list' src={post.image}  /></a>
        ):(
    
          <a target="_blank" href={`/posts/${post._id}#/${post.purpose_code}/${post.title}`} style={{color:'#fff',textDecoration:'none'}} > <img className='image-in-list' src={noimg}  /></a>
        )}
  </div>
  
    
       
        <div>
  
          
  
   
            
        <p className="list-details"> <span className="redColor">the user :</span>{post.name} | <span className="redColor">Category :</span>{post.CategoryName} | <span className="redColor">Details</span>{post.Main_paragraph} </p>
       
  
        <p className="list-button">
  
        {post.activation === 'no' && (
  
      <button className="Action-button-status">   Waiting for activation     <i className="fa fa-circle-o-notch fa-1x"></i></button> 
    
        )}

        {post.activation === 'yes' && (
  
      <button className="Action-button-status-active">   Activated     <i className="fa fa-check fa-1x"></i></button> 
  
        )}
  
  
  
  {post.premium === 'no' &&(
  
   <button className="Action-button-status">   Normal     <i className="fa fa-circle-o-notch fa-1x"></i></button> 
  
  )}
  
  
  {post.premium === 'yes' &&(
  
    <button className="Action-button-status-premium">   Special     <i className="fa fa-star fa-1x"></i></button> 
  
  )}
  
  
    <button className="Action-button-status">   Posted on <Moment format='YYYY/MM/DD'>{post.date}</Moment>  <i className="fa fa-calendar fa-1x"></i></button>
  

   {/* {!auth.loading && post.user === auth.user._id && ( */}
  <Fragment> 
  
  <Link to={`/dashboard/posts/editPost/${post._id}`}   style={{textDecoration:'none'}} > <button  className="Action-button-status" >  Edit post <i className="fa fa-edit fa-1x"></i></button>   </Link> 

  <Link to={`/dashboard/posts/editPostActivate/${post._id}`}   style={{textDecoration:'none'}} > <button  className="Action-button-status" >  Activation <i className="fa fa-edit fa-1x"></i></button>   </Link> 

   
              <ConfirmButton
              dialog={[" ", "are you sure ?", "again for deletion"]}
              action={() => deletePost(post._id)}
                />
  
  
  
  
    </Fragment>
 
  
        </p>
  
  
        </div> 
  
        </div>
    </div>
  
    </center>
  
       </Fragment>
        ))}
  
  
  
  
  
  
  
  
  
    {/* onClick={() => loadMore()} */}
    {visible < premiumComplete.length && (
      <center> 
    <button   onClick={loadMore} 
        className="loadMorebtn">  <i class="fa fa-arrow-down fa-1x"></i> More  </button> 
         </center>
    )}






    
    </div>
    </center>


        </div>
        


        </div>
       
    )
}
 


PostsEnglish.propTypes = {
 
    deletePost: PropTypes.func.isRequired,
    addLike: PropTypes.func.isRequired,
    addFiveStars: PropTypes.func.isRequired,
    addFourStars: PropTypes.func.isRequired,
    addThreeStars: PropTypes.func.isRequired,
    addTwoStars: PropTypes.func.isRequired,
    addOneStars: PropTypes.func.isRequired,

    removeLike: PropTypes.func.isRequired,
    showActions: PropTypes.bool,
    post: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
  
  };
  
  const mapStateToProps = state => ({
    market: state.market,
    auth: state.auth
  });
  
  export default connect(
    mapStateToProps,
    { addLike, removeLike, deletePost, addFiveStars, addFourStars, addThreeStars, addTwoStars,addOneStars }
  )(PostsEnglish);
