import React, {Fragment,useEffect,useState } from "react"; 
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { addColor } from '../../actions/post'; 
import { useTranslation } from 'react-i18next'; 

const AddColors11 = ({isOpen, toggle,postID, addColor}) => {

  const [t, i18next] = useTranslation()
   
 
  useEffect(() => {
    document.body.classList.toggle("modal-open", isOpen);
  }, [isOpen]);


  const [formData, setFormData] = useState({
    color_AR_name:'',
    color_EN_name:'',
    color_code: '',
    image: '',
    }); 
 
    const { color_AR_name, color_EN_name, color_code, image } = formData;

    const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
      e.preventDefault();
     await  addColor(postID,{color_AR_name, color_EN_name, color_code, image});
      window.location=`/posts/${postID}`
    };

 
  

  const handleDelete = () => {
    toggle();

  
    }
 




  return (
    
    <>
    <div
      className={isOpen ? "modal fade show" : "modal fade"}
      id="DeleteModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="DeleteModalTitle"
      aria-hidden={isOpen}
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">

          {i18next.language === 'ar' &&(<div className="abokhaled-title-button-new-blue">اضافة لون</div>)}
          {i18next.language === 'en' &&(<div className="abokhaled-title-button-new-blue">Add color</div>)}

          <button
                type="button"
                className="abokhaled-close-button-new-blue"
                data-dismiss="modal"
                onClick={toggle} 
              > 
                <i className="fa fa-close fa-2x"></i>
              </button>

          
          </div>
          <div className="modal-body text-center delete-modal">
        
      
          
          


   <div className='mainForm'>
    
    <div className=''>
      <form
        className='' 
        onSubmit={e => onSubmit(e)}
      > 
   
      {i18next.language === 'ar' &&(
        <Fragment> 
        <span> اسم اللون </span>
        <input
         className="login-input"
          placeholder='اسم اللون'
          name='color_AR_name'
          value={color_AR_name}
          onChange={e => onChange(e)}
          required
        />


        <span> كود اللون </span>
        <input
         className="login-input"
          placeholder='كود اللون'
          name='color_code'
          value={color_code}
          onChange={e => onChange(e)}
           
        />

      <span>رابط صورة توضح لون المنتج </span>
        <input
         className="login-input"
          placeholder='رابط صورة توضح لون المنتج'
          name='image'
          value={image}
          onChange={e => onChange(e)}
           
        />


    <button className="Formbutton" type='submit'   onClick={toggle}  >  
        أضف  {' '}
          <i class="fa fa-plus" aria-hidden="true"></i> 
          </button>
         
    
          </Fragment>
      )}


 

{i18next.language === 'en' &&(
        <Fragment> 

{/* <button className="CommentbuttonENglish" type='submit'  >  
        {t('postItems_comment_form_sendComment')} {' '}
          <i class="fa fa-plus" aria-hidden="true"></i> 
          </button> */}
 
          <span>color name </span>
          <input
         className="login-input"
          placeholder='color name'
          name='color_AR_name'
          value={color_AR_name}
          onChange={e => onChange(e)}
          required
        />


<span>color code </span>
        <input
         className="login-input"
          placeholder='color code'
          name='color_code'
          value={color_code}
          onChange={e => onChange(e)}
           
        />


<span>Image link showing the color of the product </span>
        <input
         className="login-input"
          placeholder='Image link showing the color of the product'
          name='image'
          value={image}
          onChange={e => onChange(e)}
           
        />

         
<button className="Formbutton" type='submit'   onClick={toggle} >  
        
          <i class="fa fa-plus" aria-hidden="true"></i>  {' '}
          Add 
          </button>

          </Fragment>
      )}
        
      </form>
    </div>
    </div>




  
           
            <div className="modal-footer border-0 justify-content-center">
              
              {/* <button
                type="button"
                className="abokhaled-close-button-new-blue"
                data-dismiss="modal"
                onClick={toggle} 
              > 
                <i className="fa fa-close fa-2x"></i>
              </button> */}


              {/* <button
                type="button" 
                className="abokhaled-delete-button-new-red"
                onClick={handleDelete}
              >
                <i class="fa fa-trash fa-2x" aria-hidden="true"></i>
              </button> */}


            </div>


          </div>
        </div>
      </div>
    </div>
    {isOpen && <div className="modal-backdrop fade show"></div>}
  </>

  )
}


AddColors11.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  addColor: PropTypes.func.isRequired
};
  

export default connect(
  null,
  { addColor }
)(AddColors11);


 